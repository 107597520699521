import type { FunctionComponent } from 'react';
import type { IAddEditTaskForm, ITaskFields } from '../stageTasks.interface';
import { ReactComponent as PriorityStarOffIcon } from '../../../../../../assets/images/priority-star-off.svg';
import { ReactComponent as QuickWinsOffIcon } from '../../../../../../assets/images/quick-win-off.svg';
import { useFormContext } from 'react-hook-form';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

interface IPriorityAndQuickWinsSectionProps {
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  priorityWatch: boolean;
  quickWinWatch: boolean;
  className?: string;
}

const PriorityAndQuickWinsSection: FunctionComponent<IPriorityAndQuickWinsSectionProps> = ({
  onUpdateTask,
  priorityWatch,
  quickWinWatch,
  className,
}) => {
  const taskForm = useFormContext<IAddEditTaskForm>();

  return (
    <section className={`priority-and-quick-win ${className}`}>
      <AppButton
        type="button"
        id="task-form-quickWin-toggle"
        className="quick-win"
        onClick={() => {
          taskForm.setValue('quickWin', !quickWinWatch);
          onUpdateTask({ quickWin: !quickWinWatch });
        }}
      >
        <QuickWinsOffIcon className={`quick-win quick-win--${quickWinWatch ? 'on' : 'off'}`} />
      </AppButton>
      <AppButton
        type="button"
        id="task-from-priority-toggle"
        className="priority"
        onClick={() => {
          taskForm.setValue('priority', !priorityWatch);
          onUpdateTask({ priority: !priorityWatch });
        }}
      >
        <PriorityStarOffIcon className={`priority priority--${priorityWatch ? 'on' : 'off'}`} />
      </AppButton>
    </section>
  );
};

export default PriorityAndQuickWinsSection;
