import React, { Fragment, FunctionComponent, useState } from 'react';
import { IMessageDataTask } from '../stageTasks.interface';
import SingleTaskItemContainer, {
  ETaskViewType,
} from '../tasks-list-single-item/SingleTaskItemContainer';
import './MainTaskWithSubtasks.scss';
import { useAppSelector } from '../../../../../../app/store';
import { doesTaskOrSubtasksMatch, sortingTasksByCreationTime } from '../TasksList.utils';
import sassVariables from '../tasks-list-single-item/singleTaskItemContainer.module.scss';

interface IMainTaskWithSubtasksProps {
  mainTask: IMessageDataTask;
}

const MainTaskWithSubtasks: FunctionComponent<IMainTaskWithSubtasksProps> = ({ mainTask }) => {
  const { subtasksMap, tasksFilter } = useAppSelector((store) => store.StageTasksReducer);
  const [isExpanded, setIsExpanded] = useState(false);
  const includeSubtasks = !!subtasksMap[mainTask.id!] && subtasksMap[mainTask.id!].length > 0;

  if (!doesTaskOrSubtasksMatch(mainTask, tasksFilter, subtasksMap[mainTask.id!])) {
    return null; // Return early if no match
  }

  const onClickExpandCollapseSubtasks = () => {
    setIsExpanded(!isExpanded);
  };

  const subTasksContainerHeight = () => {
    if (subtasksMap[mainTask.id!] && subtasksMap[mainTask.id!].length > 0) {
      const offset = 100;
      const maxHeight =
        parseInt(sassVariables.singleTaskHight) * subtasksMap[mainTask.id!].length + offset;
      return `${maxHeight}px`;
    } else return '0px';
  };

  return (
    <div
      className="main-task-with-subtasks-wrapper"
      style={
        {
          '--subtasksListMaxHeight': subTasksContainerHeight(),
        } as React.CSSProperties
      }
    >
      <SingleTaskItemContainer
        viewType={ETaskViewType.TASK_LIST}
        shouldDisplayActionButtons={true}
        singleTask={mainTask}
        onClickSubtasksChevron={includeSubtasks ? onClickExpandCollapseSubtasks : undefined}
        areSubtasksExpanded={isExpanded}
      />
      {subtasksMap[mainTask.id!] && subtasksMap[mainTask.id!].length > 0 && (
        <div className={`subtasks-list subtasks-list--${isExpanded ? 'expanded' : 'collapsed'}`}>
          {[...subtasksMap[mainTask.id!]]
            .sort((a, b) => sortingTasksByCreationTime(a, b))
            .map((subtask) => (
              <Fragment key={subtask.id}>
                <SingleTaskItemContainer
                  viewType={ETaskViewType.TASK_LIST}
                  shouldDisplayActionButtons={true}
                  singleTask={subtask}
                />
              </Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

export default MainTaskWithSubtasks;
