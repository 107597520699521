import type { FunctionComponent } from 'react';
import './AppEmptyState.scss';

export interface IAppEmptyStateProps {
  SvgEmptyStateComponent: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  paragraph: string;
  className?: string;
}

const AppEmptyState: FunctionComponent<IAppEmptyStateProps> = ({
  SvgEmptyStateComponent,
  paragraph,
  className = '',
}) => {
  return (
    <div className={`app-empty-state ${className}`}>
      <div className="app-empty-state--paragraph-container">
        <p className="app-empty-state--paragraph">{paragraph}</p>
      </div>
      <SvgEmptyStateComponent className="app-empty-state--mascot" />
    </div>
  );
};

export default AppEmptyState;
