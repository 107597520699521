import { useFormContext } from 'react-hook-form';
import type { IProfileForm } from '../SettingsMenu';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../app/store';
import { updateUserProfileReq } from '../../../../../app/useAppData/user.store';
import { Switch } from 'antd';
import { ErrorMessage } from '../error-message/ErrorMessage';
import {
  isMobileAppWebView,
  sendPushNotificationsPermissionRequestToMobileApp,
} from '../../../../../mobile-application-utils';
import { FlagUtils } from '../../../../utils/flagUtils';
import { EUserSettingsFlags } from '../SettingsMenu.interfaces';

export const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const form = useFormContext<IProfileForm>();

  const watchShouldDisplayTextNotificationError = form.watch('shouldDisplayTextNotificationError');
  const watchShouldDisplayPushNotificationError = form.watch('shouldDisplayPushNotificationError');
  const watchUserTextNotificationsEnabled = form.watch('userTextNotificationsEnabled');
  const watchUserSettingsFlags = form.watch('userSettingsFlags');

  const onUpdateTextNotificationEnabled = () => {
    const reqPayload = {
      userTextNotificationsEnabled: form.getValues().userTextNotificationsEnabled,
    };
    dispatch(updateUserProfileReq(reqPayload))
      .unwrap()
      .then()
      .catch(() => {
        form.setValue('shouldDisplayTextNotificationError', true);
        form.setValue('userTextNotificationsEnabled', watchUserTextNotificationsEnabled);
      });
  };

  const onChangeTextNotificationSwitch = (checked: boolean) => {
    if (watchShouldDisplayTextNotificationError)
      form.setValue('shouldDisplayTextNotificationError', false);
    form.setValue('userTextNotificationsEnabled', checked);
    onUpdateTextNotificationEnabled();
  };

  const onUpdatePushNotificationEnabled = () => {
    const reqPayload = {
      userSettingsFlags: form.getValues().userSettingsFlags,
    };
    dispatch(updateUserProfileReq(reqPayload))
      .unwrap()
      .then()
      .catch(() => {
        form.setValue('shouldDisplayPushNotificationError', true);
        form.setValue('userSettingsFlags', watchUserSettingsFlags);
      });
  };

  const onChangePushNotificationSwitch = (checked: boolean) => {
    if (watchShouldDisplayPushNotificationError)
      form.setValue('shouldDisplayPushNotificationError', false);
    const updatedFlags = checked
      ? FlagUtils.setFlag(watchUserSettingsFlags, EUserSettingsFlags.PUSH_NOTIFICATIONS_ENABLED)
      : FlagUtils.unsetFlag(watchUserSettingsFlags, EUserSettingsFlags.PUSH_NOTIFICATIONS_ENABLED);
    form.setValue('userSettingsFlags', updatedFlags);
    sendPushNotificationsPermissionRequestToMobileApp(checked);
    onUpdatePushNotificationEnabled();
  };

  return (
    <>
      <section className="notification">
        <div>
          <h3 className="title mobile-label-text">
            {t('settingsPersonalInfoSmsNotificationTitle')}
          </h3>
          <h3 className="title desktop-label-text">
            {t('settingsPersonalInfoSmsNotificationTitle')}
          </h3>
          <p>{t('settingsPersonalInfoSmsNotificationSubTitle')}</p>
        </div>
        <Switch
          onChange={(checked) => onChangeTextNotificationSwitch(checked)}
          checked={form.getValues().userTextNotificationsEnabled}
          data-testid="notification-switch"
          className="app-toggle app-toggle-active-dark"
        />
        {watchShouldDisplayTextNotificationError && <ErrorMessage />}
      </section>
      {isMobileAppWebView() && (
        <section className="notification">
          <div>
            <h3 className="title mobile-label-text">
              {t('settingsPersonalInfoPushNotificationTitle')}
            </h3>
            <h3 className="title desktop-label-text">
              {t('settingsPersonalInfoPushNotificationTitle')}
            </h3>
            <p>{t('settingsPersonalInfoPushNotificationSubTitle')}</p>
          </div>
          <Switch
            onChange={(checked) => onChangePushNotificationSwitch(checked)}
            checked={FlagUtils.getFlag(
              watchUserSettingsFlags,
              EUserSettingsFlags.PUSH_NOTIFICATIONS_ENABLED,
            )}
            data-testid="notification-switch"
            className="app-toggle app-toggle-active-dark"
          />
          {watchShouldDisplayPushNotificationError && <ErrorMessage />}
        </section>
      )}
    </>
  );
};
