import type {
  IChatMessage,
  IHistoryMessage,
  IMessageResponse,
  ISessionSummariesResponse,
} from '../chat.interfaces';
import type { IUser } from '../../../app/auth/auth.interfaces';
import { getItemFromLocalStorage } from '../../../shared/utils/localStorage.utils';
import { lastSurveyOfferedDayOfWeekKey } from '../../../app/constants';
import { ApplicationInsightsApi } from '../../../application-insights';
import { EClientFlags } from '../../../shared/components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { FlagUtils } from '../../../shared/utils/flagUtils';

export enum EFeedbackSurveyType {
  NEW_USER = 1,
  SUPERHUMAN,
}

export const getMessageTime = (message: IMessageResponse | IChatMessage) => {
  return new Date(message.creationTime).getTime();
};

export const transformResponseMessageToChatMessage = (
  messages: IMessageResponse[] = [],
  lastMessageTimestamp: number | null,
  messageType: 'history' | 'chatField' = 'history',
): IChatMessage[] => {
  try {
    return messages.map((item, index) => {
      return {
        msgId: item.id,
        party: item.party ? item.party : 'Bot',
        msg: item.text || '',
        messageTime: getMessageTime(item),
        shouldDisplayTime:
          messageType === 'chatField'
            ? shouldDisplayTimeOnChatMessages(index, item, lastMessageTimestamp, 'chatField')
            : shouldDisplayTimeOnChatMessages(
                index,
                item,
                getPreviousUserOrBotMessageTimestamp(messages, index),
                'history',
              ),
        msgSequenceNumber: item.messageSequenceNumber,
        creationTime: item?.creationTime,
        sessionId: item.sessionId,
        debugInfo: item.debugInfo,
        // isHidden: messages.length > index + 1 && item.party === 'Bot' && !!item.tool && item.tool.name.includes('[UX_') && messages[index + 1].party === 'Bot',
        tool: item.tool,
        // Incorporate the grouping logic for message data if present
        data: item?.data || null,
      } as IChatMessage;
    });
  } catch (e) {
    ApplicationInsightsApi.trackTrace('transformResponseMessageToChatMessage - error');
    ApplicationInsightsApi.trackException(e);
    console.error(e);
    return [];
  }
};

const shouldDisplayTimeOnChatMessages = (
  index: number,
  messageItem: IMessageResponse,
  prevMessageTimestamp: number | null,
  messageType: 'history' | 'chatField',
) => {
  // If it's a chatField messages array (botResponse messages array, or createSession messages array), all these messages have the same creationTime. Therefore, display the message time only for the first one and set it to false for all the rest (when index !== 0)
  if (messageType === 'chatField' && index !== 0) return false;
  return shouldDisplayTime(getMessageTime(messageItem), prevMessageTimestamp);
};

export const shouldDisplayTime = (
  MessageTimestamp: number,
  prevMessageTimestamp: number | null,
) => {
  const elapsedTimeToDisplayDateTimeInMinutes = 15;
  if (prevMessageTimestamp) {
    // convert the number of milliseconds that have passed to minutes and check if it is greater than the elapsedTimeToDisplayDateTimeInMinutes var
    return (
      (MessageTimestamp - prevMessageTimestamp) / 60000 > elapsedTimeToDisplayDateTimeInMinutes
    );
  }
  return true;
};

export const getPreviousUserOrBotMessageTimestamp = (
  messagesArr: IMessageResponse[] | IChatMessage[],
  currentIndex: number,
): number | null => {
  for (let i = currentIndex - 1; i >= 0; i--) {
    if (messagesArr[i].party === 'Bot' || messagesArr[i].party === 'User')
      return getMessageTime(messagesArr[i]);
  }
  return null;
};

export const handleAppSurvey = (
  user: IUser | null,
  sessions: { [key: string]: ISessionSummariesResponse[] },
  history: IHistoryMessage[],
  currentSessionHistory: IChatMessage[],
  totalTasksForUser: number,
  triggerSurveyOverlay: (surveyType: EFeedbackSurveyType) => void,
) => {
  ApplicationInsightsApi.trackTrace('handleAppSurvey - starting');
  try {
    const currentDayOfTheWeek = new Date().getDay();
    const lastSurveyOfferedDayOfWeek = getItemFromLocalStorage<number | null>(
      lastSurveyOfferedDayOfWeekKey,
    );
    const clientFlags = user?.clientFlags || 0;
    if (FlagUtils.getFlag(clientFlags, EClientFlags.DONT_SHOW_SUPERHUMAN_SURVEY)) return; // the user already answered the superhuman survey - this is the final state

    if (lastSurveyOfferedDayOfWeek !== null && lastSurveyOfferedDayOfWeek === currentDayOfTheWeek)
      return; // the user already saw the survey today

    const hasOverFiveTurnsInCurrentSession = currentSessionHistory?.length >= 5;

    // check superhuman trigger - the user was active at least 4 different days over the past 14 days
    const fourteenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 14));
    const beenActiveAtLeastFourDaysInLastFourteenDays =
      (Object.keys(sessions) || []).filter(
        (date) => new Date(date?.replaceAll('-', ' ')) > fourteenDaysAgo,
      ).length >= 4;

    if (beenActiveAtLeastFourDaysInLastFourteenDays && hasOverFiveTurnsInCurrentSession) {
      ApplicationInsightsApi.trackTrace('handleAppSurvey - creating superhuman survey');
      return triggerSurveyOverlay(EFeedbackSurveyType.SUPERHUMAN);
    }
    if (FlagUtils.getFlag(clientFlags, EClientFlags.DONT_SHOW_NEW_USER_FEEDBACK)) return; // the user already answered the new user survey - this is the final state

    // check new user trigger - the user has at least 20 total messages of type 'User' in history + current session and has created at least 2 tasks
    const totalTurnsInHistory = (history || []).filter(
      (message) => message.party === 'User',
    ).length;
    const totalTurnsThisSession = (currentSessionHistory || []).filter(
      (message) => message.party === 'User',
    ).length;
    const hasAtLeastTwentyTurns = totalTurnsInHistory + totalTurnsThisSession >= 20;
    const hadOverTwoTasks = totalTasksForUser >= 2;

    if (
      user?.shouldDisplayFeedbackSurvey &&
      hasAtLeastTwentyTurns &&
      hadOverTwoTasks &&
      hasOverFiveTurnsInCurrentSession
    ) {
      ApplicationInsightsApi.trackTrace('handleAppSurvey - creating new user survey');
      return triggerSurveyOverlay(EFeedbackSurveyType.NEW_USER);
    }
    ApplicationInsightsApi.trackTrace('handleAppSurvey - complete no survey triggered');
  } catch (e) {
    ApplicationInsightsApi.trackTrace('handleAppSurvey error');
    ApplicationInsightsApi.trackException(e);
  }
};

export const handleMobileAppAwareness = (
  triggerMobileAwareness: () => void,
  user: IUser | null,
) => {
  try {
    const clientFlags = user?.clientFlags || 0;
    if (FlagUtils.getFlag(clientFlags, EClientFlags.DONT_SHOW_MOBILE_AWARENESS)) return;
    ApplicationInsightsApi.trackTrace('handleMobileAppAwareness - starting');
    triggerMobileAwareness();
    ApplicationInsightsApi.trackTrace('handleMobileAppAwareness - completed');
  } catch (e) {
    ApplicationInsightsApi.trackTrace('handleMobileAppAwareness - error');
    ApplicationInsightsApi.trackException(e);
  }
};

export const scrollToChatBottom = () => {
  const scrollToElement = document.getElementById('scroll-to-me');
  if (scrollToElement) setTimeout(() => scrollToElement.scrollIntoView({ behavior: 'smooth' }), 0);
};
