import type { ECalendarSyncType, IUserFeatureFlags } from '../../../../app/auth/auth.interfaces';

export interface IUserProfileReqPayload {
  phoneNumber?: string;
  userTextNotificationsEnabled?: boolean;
  shouldDisplayFeedbackSurvey?: boolean;
  shouldRemoveCalendar?: boolean;
  isDailyReminderEnabled?: boolean;
  isEveningReminderEnabled?: boolean;
  isProactiveWorkBlockCheckInReminderEnabled?: boolean;
  isProactiveEndOfWorkTimeReminderEnabled?: boolean;
  sessionId?: string;
  clientFlags?: number;
  userSettingsFlags?: number;
  featureFlags?: IUserFeatureFlags;
}

export interface IUserFeedbackReqPayload {
  feedbackText: string;
  contactEmail?: string;
}
export interface IUserSettingsFeedbackForm extends IUserFeedbackReqPayload {
  shouldSendEmail?: boolean;
}
export interface IUserFeedbackResponse {
  id: string;
  userId: string;
  feedbackText: string;
}

export interface IUpdatePreferredNameReqPayload {
  preferredName: string;
  sessionId: string;
}

// enum of bit values - should save as powers of 2
export enum EClientFlags {
  AUDIO_OFF = 1,
  DONT_SHOW_AGAIN_DISCORD_TOAST = 2,
  PRODUCT_TOUR_DISPLAYED = 4,
  DONT_SHOW_NEW_USER_FEEDBACK = 8,
  DONT_SHOW_SUPERHUMAN_SURVEY = 16,
  DONT_SHOW_MOBILE_AWARENESS = 32,
  PLAN_TOUR_DISPLAYED = 64,
  USER_ACTIVATION_REPORTED = 128,
  LIGHT_MODE_ON = 256,
  DARK_MODE_ON = 512,
  // FLAG_I = 1024,
  // FLAG_J = 2048,
}

export enum EUserSettingsFlags {
  PUSH_NOTIFICATIONS_ENABLED = 1,
  USER_ACTIVATED = 2,
}

export interface IResetUserReqPayload {
  isPartialReset: boolean;
}

export interface IiCalUpdateReqPayload {
  iCalUrl: string;
}

export interface IAppleCalendarSyncReqPayload {
  calendarId: string;
  syncType: ECalendarSyncType;
}
