import { ReactComponent as MagicLinkEmailIcon } from '../../../../assets/images/magic-link-email.svg';
import type { LoginPageProps } from '../../auth.interfaces';
import { useTranslation } from 'react-i18next';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';
import { APP_ROUTING_PATHS } from '../../../constants';
import { ELoginPageViewType } from '../Login';
import AppButton from '../../../../shared/components/app-button/AppButton';

export const LoginWithEmailButton = ({ viewType }: LoginPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearchParams();

  const requestMagicLink = () => {
    navigate(APP_ROUTING_PATHS.MAGIC_LINK_EMAIL);
  };

  return (
    <AppButton className="email-login" type="button" onClick={requestMagicLink} id="email-login">
      <div className="action-btn-img-container">
        <MagicLinkEmailIcon />
      </div>
      {viewType === ELoginPageViewType.NEW_USER_VIEW
        ? t('continueWithEmailSignUp')
        : t('continueWithEmailSignIn')}
    </AppButton>
  );
};
