import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import ProductTour from './ProductTour';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { isProductTourAlreadyDisplayed } from './ProductTour.utils';
import { useApiData } from '../../shared/hooks/useApiData';
import { FlagUtils } from '../../shared/utils/flagUtils';
import { EClientFlags } from '../../shared/components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { updateUserProfileReq } from '../../app/useAppData/user.store';
import { ApplicationInsightsApi } from '../../application-insights';
import { setShouldDisplayProductTour } from '../../shared/store/shared.store';
import {
  setIsMiniPlanTourOpened,
  setPlanView,
} from '../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { EPlanViewSelection } from '../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface';

export enum EProductTourViewType {
  PRODUCT_TOUR = 1,
  PLAN_TOUR,
}

export interface IProductTourWrapperProps {
  dataAttribute: string;
  className?: string;
  viewType: EProductTourViewType;
}

const ProductTourWrapper: FunctionComponent<IProductTourWrapperProps> = ({ ...props }) => {
  const [shouldDisplayComponent, setShouldDisplayComponent] = useState<boolean>(false);
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const { shouldDisplayProductTour } = useAppSelector((store) => store.sharedStoreReducer);
  const { user } = useAppSelector((store) => store.userReducer);
  const clientFlags = user?.clientFlags || 0;
  const dispatch = useAppDispatch();

  const openTour = () => {
    switch (props.viewType) {
      case EProductTourViewType.PRODUCT_TOUR:
        handleOpenProductTour();
        break;
      case EProductTourViewType.PLAN_TOUR:
        handleOpenPlanTour();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    openTour();
    // eslint-disable-next-line
  }, []);

  // Start Plan Tour Logic
  // set client flag to not show tour again
  const setPlanTourFlag = () => {
    try {
      const clientFlags = user?.clientFlags || 0;
      const updatedClientFlags = FlagUtils.setFlag(clientFlags, EClientFlags.PLAN_TOUR_DISPLAYED);
      dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }));
    } catch (e) {
      ApplicationInsightsApi.trackException(e);
    }
  };

  const handleOpenPlanTour = () => {
    const clientFlags = user?.clientFlags || 0;
    const planTourDisplayedFlag = FlagUtils.getFlag(clientFlags, EClientFlags.PLAN_TOUR_DISPLAYED);
    if (!shouldDisplayProductTour && !planTourDisplayedFlag) {
      setPlanTourFlag();
      dispatch(setPlanView(EPlanViewSelection.MY_WEEK));
      setShouldDisplayComponent(true);
      dispatch(setIsMiniPlanTourOpened(true));
    }
  };
  // End Plan Tour Logic

  // Start Product Tour Logic
  const handleOpenProductTour = () => {
    if (!isProductTourAlreadyDisplayed(user)) {
      dispatch(setShouldDisplayProductTour(true));
      setShouldDisplayComponent(true);
    }
  };

  const turnOnTheProductTourClientFlag = useCallback(() => {
    const updatedClientFlags = FlagUtils.setFlag(clientFlags, EClientFlags.PRODUCT_TOUR_DISPLAYED);
    dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }));
  }, [clientFlags, dispatch]);

  useApiData(sessionResponse, {
    onFulfilled: () => {
      if (!isProductTourAlreadyDisplayed(user)) {
        turnOnTheProductTourClientFlag();
      }
    },
  });
  // End Product Tour Logic

  return shouldDisplayComponent ? (
    <ProductTour {...props} setShouldDisplayComponent={setShouldDisplayComponent} />
  ) : null;
};

export default ProductTourWrapper;
