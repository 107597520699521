import { store } from '../../../../app/store';
import { getWorkBlockAssignedTasksSorted, isDesktopView } from '../../../../shared/utils/utils';
import type { IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import {
  ETaskSource,
  ETaskStatus,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import type { ICalendarEvent } from './CalendarDay';
import { defaultHourHeightDesktop, defaultHourHeightMobile } from './CalendarDay';
import { addMinutes } from 'date-fns';
import { selectedPlanViewTypeLocalStorageKey } from '../../../../app/constants';
import type { MouseEvent } from 'react';
import { ECalendarEventDurationTypeClassName } from './CalendarDay.interfaces';
import { EPlanViewSelection } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface';
import calendarSassVars from './CalendarDay.module.scss';
import getThemeColorValue from '../../../../shared/utils/themes.utils';
import { ApplicationInsightsApi } from '../../../../application-insights';

export const defaultDuration = 30; // Default duration in minutes

export const getDurationTypeClassName = (
  duration: number | null | undefined,
): ECalendarEventDurationTypeClassName => {
  const shorterThen30ClassName = ECalendarEventDurationTypeClassName.SHORTER_THAN_30_DURATION;
  const over30ClassName = ECalendarEventDurationTypeClassName.OVER_30_DURATION;
  const halfHourThreshold = 30; // in minutes
  if (!duration) return shorterThen30ClassName;
  return duration / 60 > halfHourThreshold ? over30ClassName : shorterThen30ClassName;
};

export function convertSingleItemToCalendarEvent(
  el: IMessageDataTask,
  hourHeight?: number,
  startingHour?: number,
): ICalendarEvent {
  hourHeight = hourHeight || (isDesktopView() ? defaultHourHeightDesktop : defaultHourHeightMobile);
  startingHour = startingHour || 0;

  const calcDuration = el.duration && el.duration / 60 > 15 ? el.duration / 60 : defaultDuration;
  const isWorkBlock = el.isEvent && el.isWorkBlock;
  const start = el.isRecurrenceInstance
    ? setDaylightSavingBasedOnOriginalStartTime(
        new Date(el.originalWorkTime!),
        new Date(el.workTime!),
      )
    : new Date(el.workTime!);
  const end = addMinutes(start, calcDuration);

  const startHour = start.getHours();
  const startMinutes = start.getMinutes();
  const endHour = end.getHours();
  const endMinutes = end.getMinutes();

  const endAdjustedHour = endHour === 0 && endHour < startHour ? 24 : endHour; // Treat 12AM correctly
  const startOffset = startHour * hourHeight + (startMinutes * hourHeight) / 60;
  const endOffset = endAdjustedHour * hourHeight + (endMinutes * hourHeight) / 60;
  const top = startOffset - startingHour * hourHeight;
  const height = Math.abs(endOffset - startOffset);

  const allTasks = store.getState().StageTasksReducer.allTasks;
  const currentTaskPlacement = store.getState().StagePlannerReducer.currentTaskPlacement;
  const backgroundPrimaryColor = getThemeColorValue('backgroundPrimary') || 'white';
  // calc event background color
  let backgroundColor = getThemeColorValue('backgroundBrandLowContrast'); // default WB color
  if (!el.isWorkBlock) {
    if (el?.tags && el?.tags?.length > 0) {
      const firstTagColor = el.tags[0].color || backgroundPrimaryColor || '';
      backgroundColor =
        firstTagColor === getThemeColorValue('backgroundTransparent')
          ? backgroundPrimaryColor
          : firstTagColor;
    } else backgroundColor = backgroundPrimaryColor;
  }

  const playViewType: EPlanViewSelection =
    (localStorage.getItem(selectedPlanViewTypeLocalStorageKey) as EPlanViewSelection | null) ||
    EPlanViewSelection.MY_WEEK;
  let titleFontSize = 12;
  if (playViewType === EPlanViewSelection.MY_DAY) titleFontSize += 2;

  return {
    id: el.id!,
    start,
    end,
    top,
    height,
    title: el?.name ? el.name : isWorkBlock ? 'Work Block' : '',
    titleFontSize: `${titleFontSize}px`,
    durationType: getDurationTypeClassName(el.duration),
    duration: el.duration,
    isWorkBlock,
    backgroundColor: backgroundColor,
    titleColor: getThemeColorValue('textPrimary') || '',
    isRecurring: !!el.workTimeRecurrenceType,
    parentId: el.parentId,
    source: !isWorkBlock ? el?.source || ETaskSource.Internal : undefined,
    isEvent: isWorkBlock ? false : el.isEvent || false,
    workBlockId: el?.workBlockId || null,
    status: el?.status || ETaskStatus.NOT_STARTED,
    relatedTasks: isWorkBlock
      ? getWorkBlockAssignedTasksSorted(
          [...allTasks, currentTaskPlacement || ({} as IMessageDataTask)],
          el.id!,
          start,
        )
      : [],
  };
}

export type TResizingEventEdge = 'top' | 'bottom' | null;

export const getResizingEventEdge = (
  e: MouseEvent<HTMLElement>,
  cellHeight: number,
): TResizingEventEdge => {
  const edgeOffsetEventLessOrEqualCellHeight = 4; // Pixels from edge to detect resizing when event height equals cell height
  const edgeOffsetEventLargerCellHeight = 8; // Pixels from edge to detect resizing when event height differs from cell height
  const edgOffsetForResizing =
    e.currentTarget.offsetHeight <= cellHeight
      ? edgeOffsetEventLessOrEqualCellHeight
      : edgeOffsetEventLargerCellHeight;
  const rect = e.currentTarget.getBoundingClientRect();
  const isTop = e.clientY - rect.top < edgOffsetForResizing;
  const isBottom = rect.bottom - e.clientY < edgOffsetForResizing;
  return isTop ? 'top' : isBottom ? 'bottom' : null;
};

const cellInMinutes = 30; // Each cell is 30 minutes

// Calculate new duration in seconds by the new height
export const calcNewDuration = (newHeight: number, cellHeight: number) => {
  let newDuration = Math.round(newHeight / cellHeight) * cellInMinutes;
  newDuration *= 60; // Convert to seconds
  return newDuration;
};

// Calculate new start date
export const calcNewStartTime = (
  currentStart: Date,
  initialHeight: number,
  newHeight: number,
  cellHeight: number,
) => {
  const numberOfCellsUserMoved = (initialHeight - newHeight) / cellHeight;
  const minutesToAdd = numberOfCellsUserMoved * cellInMinutes;
  const newStartTime = addMinutes(currentStart, minutesToAdd);
  return newStartTime;
};

export const getCalendarEventIconColor = (eventColor?: string): string | undefined => {
  if (
    !eventColor ||
    eventColor === getThemeColorValue('backgroundTransparent') ||
    eventColor === getThemeColorValue('backgroundPrimary')
  ) {
    return undefined;
  }
  return eventColor;
};

export const getCalendarEventInnerContainerClassName = (
  planView: EPlanViewSelection,
  eventDurationType: ECalendarEventDurationTypeClassName,
) => {
  const baseClassName = 'calendar-event-inner-container';
  let className = `${baseClassName} ${baseClassName}--${eventDurationType}`;
  switch (planView) {
    case EPlanViewSelection.MY_WEEK:
      className += ` ${baseClassName}--week`;
      break;
    case EPlanViewSelection.MY_DAY:
      className += ` ${baseClassName}--day`;
      break;
    default:
      break;
  }
  return className;
};

export const calcEventTitleVisibleLines = (event: ICalendarEvent) => {
  const cellHeight = 60;
  const lineOffset = event.isRecurring || event.height <= cellHeight ? 3 : 1;
  const titleLineHeight = parseInt(calendarSassVars.calendarEventTitleLineHeight);
  return Math.floor(event.height / (titleLineHeight + lineOffset));
};

export const setDaylightSavingBasedOnOriginalStartTime = (originalDate: Date, futureDate: Date) => {
  if (!originalDate || !futureDate) return futureDate;
  const originalHours = originalDate.getHours();
  const originalMinutes = originalDate.getMinutes();
  const futureDateWithOriginalTime = new Date(futureDate);
  futureDateWithOriginalTime.setHours(originalHours);
  futureDateWithOriginalTime.setMinutes(originalMinutes);
  return futureDateWithOriginalTime;
};

export const getEventStatus = (startTime: Date, endTime: Date) => {
  try {
    const now = new Date();
    const fifteenMinutesBefore = new Date(startTime.getTime() - 15 * 60 * 1000);

    // 15 minutes before start until end of event
    if (now >= fifteenMinutesBefore && now <= endTime) {
      return 'focused';
    }
    return 'normal';
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return '';
  }
};
