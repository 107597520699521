import { EOperatingSystem } from '../../app/auth/auth.interfaces';
import { operatingSystemLocalStorageKey } from '../../app/constants';
import { getItemFromLocalStorage } from './localStorage.utils';

export const getOperatingSystem = (): EOperatingSystem => {
  const operatingSystem = getItemFromLocalStorage<EOperatingSystem>(operatingSystemLocalStorageKey);
  if (operatingSystem) {
    return operatingSystem;
  }
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return EOperatingSystem.ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return EOperatingSystem.IOS;
  }
  return EOperatingSystem.UNKNOWN;
};
