import { createAction, createReducer } from '@reduxjs/toolkit';
import { EPlannerClickEvent, EPlanViewSelection } from './stagePlanner.interface';
import type { IPlannerCurrentTaskPlacement } from '../stage-tasks/stageTasks.interface';
import type { IDragAndDropCalendarDimensions } from '../../../../plan/calendar/calendar-day/CalendarDragAndDrop.util';
import { selectedPlanViewTypeLocalStorageKey } from '../../../../../app/constants';

export enum EPlannerMode {
  TIMEPICKER = 1,
  UNSCHEDULEDTASKSPLACER = 2,
}

export interface IStagePlannerStore {
  plannerMode: EPlannerMode | null;
  plannerOutputEventType: EPlannerClickEvent | undefined;
  plannerOutputPayload: unknown | undefined;
  currentTaskPlacement: IPlannerCurrentTaskPlacement | null;
  dragAndDropCalendarDimensions: IDragAndDropCalendarDimensions | null;
  calendarFirstCellVisiblePortion: number | null;
  planView: EPlanViewSelection;
  isMiniPlanTourOpened: boolean;
}

const initialStateStagePlannerStore: IStagePlannerStore = {
  plannerMode: null,
  plannerOutputEventType: undefined,
  plannerOutputPayload: undefined,
  currentTaskPlacement: null,
  dragAndDropCalendarDimensions: null,
  calendarFirstCellVisiblePortion: null,
  planView:
    (localStorage.getItem(selectedPlanViewTypeLocalStorageKey) as EPlanViewSelection) ||
    EPlanViewSelection.MY_PLAN,
  isMiniPlanTourOpened: false,
};

const createReducerKey = (subKey: string): string => {
  return 'stagePlanner/' + subKey;
};

export const setPlannerState = createAction<EPlannerMode | null>(
  createReducerKey('setPlannerState'),
);

export const setPlannerOutputEventType = createAction<EPlannerClickEvent | undefined>(
  createReducerKey('setPlannerOutputEvent'),
);

export const setPlannerOutputPayload = createAction<unknown | undefined>(
  createReducerKey('setPlannerOutputPayload'),
);

export const setPlannerCurrentTaskPlacement = createAction<IPlannerCurrentTaskPlacement | null>(
  createReducerKey('setPlannerCurrentTaskPlacement'),
);

export const setDragAndDropCalendarDimensions = createAction<IDragAndDropCalendarDimensions | null>(
  createReducerKey('setCalendarDragAndDropDimensions'),
);

export const setCalendarFirstCellVisiblePortion = createAction<number>(
  createReducerKey('setCalendarFirstCellVisiblePortion'),
);

export const setPlanView = createAction<EPlanViewSelection>(createReducerKey('setPlanView'));

export const setIsMiniPlanTourOpened = createAction<boolean>(
  createReducerKey('setIsMiniPlanTourOpened'),
);

export const StagePlannerReducer = createReducer(initialStateStagePlannerStore, (builder) => {
  builder.addCase(setPlannerState, (state, action) => {
    state.plannerMode = action.payload;
  });
  builder.addCase(setPlannerOutputEventType, (state, action) => {
    state.plannerOutputEventType = action.payload;
  });
  builder.addCase(setPlannerOutputPayload, (state, action) => {
    state.plannerOutputPayload = action.payload;
  });
  builder.addCase(setPlannerCurrentTaskPlacement, (state, action) => {
    state.currentTaskPlacement = action.payload;
  });
  builder.addCase(setDragAndDropCalendarDimensions, (state, action) => {
    state.dragAndDropCalendarDimensions = action.payload;
  });
  builder.addCase(setCalendarFirstCellVisiblePortion, (state, action) => {
    state.calendarFirstCellVisiblePortion = action.payload;
  });
  builder.addCase(setPlanView, (state, action) => {
    localStorage.setItem(selectedPlanViewTypeLocalStorageKey, action.payload);
    state.planView = action.payload;
  });
  builder.addCase(setIsMiniPlanTourOpened, (state, action) => {
    state.isMiniPlanTourOpened = action.payload;
  });
});
