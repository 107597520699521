import { useTranslation } from 'react-i18next';
import Timer from '../../shared/components/Timer/Timer';
import './TimerPage.scss';
import { useParams } from 'react-router-dom';
import { TimerShareButton } from '../../shared/components/Timer/TimerShare/TimerShare';
import TimerPageDialog from './timer-page-dialog/TimerPageDialog';

const TimerPage = () => {
  const { t } = useTranslation();
  const { id: timerId } = useParams<{ id: string }>();

  return (
    <div className={`timer-page theme-light`}>
      <div className="timer-page-header">
        <div className="timer-page-header-content">
          <h1 className="timer-page-header-title">{t('stageTimerTitle')}</h1>
          <TimerShareButton />
        </div>
      </div>
      <div className="timer-page-content">
        <Timer
          showCompletedAsText={true}
          shouldShowControls={false}
          shouldUsePolling={true}
          timerId={timerId || ''}
        />
      </div>
      <TimerPageDialog />
    </div>
  );
};

export default TimerPage;
