import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import mascot from '../../../../assets/images/login-error-mascot.png';
import { APP_ROUTING_PATHS } from '../../../constants';
import { useTranslation } from 'react-i18next';
import './LoginErrorPage.scss';
import { useAppDispatch } from '../../../store';
import { resetLoginResState } from '../../auth.store';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';
import StyledAppButton from '../../../../shared/components/app-button/styled-app-button/StyledAppButton';

export const LoginErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearchParams();
  const dispatch = useAppDispatch();

  const handleNavigate = () => {
    dispatch(resetLoginResState());
    navigate(APP_ROUTING_PATHS.SIGN_UP);
  };

  return (
    <div className="login-error-page auth-screen">
      <ContentFrameWrapper className="login-error-page-frame-wrapper">
        <div className="login-error-content-container">
          <h1 className="login-error-title static-string auth-title">{t('loginErrorTitle')}</h1>
          <img className="login-error-mascot" src={mascot} alt="mascot" />
          <p className="login-error-info">{t('loginErrorText')}</p>
          <StyledAppButton
            onClick={handleNavigate}
            className="auth-next-btn"
            id="login-error-try-again-button"
            data-testid="update-phone-form-submit"
            type="button"
          >
            {t('loginErrorTryAgainText')}
          </StyledAppButton>
        </div>
      </ContentFrameWrapper>
    </div>
  );
};
