import type { FunctionComponent } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import { ETaskStatus } from '../../stageTasks.interface';
import PriorityAndQuickWinsSection from '../PriorityAndQuickWinsSection';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EventIcon } from '../../../../../../../assets/images/task-details/event-details-main-icon.svg';
import './TaskNameController.scss';
import AppCheckbox from '../../../../../../../shared/components/app-checkbox/AppCheckbox';

interface ITaskNameControllerProps {
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isDisabled: boolean;
  isReadOnly: boolean;
  isEvent: boolean;
}

const TaskNameController: FunctionComponent<ITaskNameControllerProps> = ({
  onUpdateTask,
  onUpdateTaskTextarea,
  isDisabled,
  isReadOnly,
  isEvent,
}) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const nameWatch = taskForm.watch('name');
  const priorityWatch = taskForm.watch('priority');
  const quickWinWatch = taskForm.watch('quickWin');
  const { t } = useTranslation();

  const onBlur = () => {
    if (taskForm.getFieldState('name').isDirty) {
      onUpdateTaskTextarea({ name: nameWatch });
      // will reset isDirty back to false
      taskForm.resetField('name', { defaultValue: nameWatch });
    }
  };

  return (
    <div
      className={`task-name-controller task-detail-section ${
        isEvent ? 'task-name-controller--event' : ''
      }`}
    >
      {!isEvent && (
        <AppCheckbox
          className="task-details-checkbox"
          isChecked={taskForm.watch('isDone')}
          checkboxSize="18px"
          onChange={(e) => {
            taskForm.setValue('isDone', e.target.checked);
            onUpdateTask({
              status: e.target.checked ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED,
            });
          }}
        />
      )}
      {isEvent && (
        <EventIcon
          className={`event-name-icon task-detail-icon ${
            (nameWatch || []).length ? 'task-detail-icon--active' : ''
          }`}
        />
      )}
      <input
        {...taskForm.register('name', { required: true, minLength: 1, onBlur: () => onBlur() })}
        className="name-text"
        type="text"
        placeholder={
          isEvent ? t('addEditEventNamePlaceholderText') : t('addEditTaskNamePlaceholderText')
        }
        defaultValue={taskForm.getValues('name')}
        disabled={isDisabled || isReadOnly}
        autoFocus
        autoComplete="off"
      />
      {taskForm.formState.errors.name && (
        <small className="task-name-error">{t('createTaskNameFieldError')}</small>
      )}
      {!isEvent && (
        <PriorityAndQuickWinsSection
          onUpdateTask={onUpdateTask}
          priorityWatch={priorityWatch}
          quickWinWatch={quickWinWatch}
        />
      )}
    </div>
  );
};

export default TaskNameController;
