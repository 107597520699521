import { useFileDownloader } from '../../../../hooks/useFileDownloader';
import { chatSessionIdLocalStorageKey } from '../../../../../app/constants';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { downloadCheckpointAction } from '../../../../../features/chat-wrapper/chat.store';
import './DownloadCheckpointButton.scss';
import { useAppSelector } from '../../../../../app/store';
import AppButton from '../../../app-button/AppButton';

const DownloadCheckpointButton = () => {
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const [sessionIdLocalStorage] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const { downloadFile, isLoading, error } = useFileDownloader();

  const handleDownload = () => {
    const currentSessionId = sessionResponse?.data?.sessionId || sessionIdLocalStorage;
    downloadFile(downloadCheckpointAction, currentSessionId);
  };

  return (
    <div className="download-checkpoint settings-section-container">
      <AppButton
        id="download-checkpoint"
        className="download-checkpoint-btn"
        onClick={handleDownload}
        disabled={isLoading}
      >
        {isLoading ? 'Downloading...' : 'Download checkpoint'}
      </AppButton>
      {error && <small className="error">Download checkpoint error: {error}</small>}
    </div>
  );
};

export default DownloadCheckpointButton;
