import { useTranslation } from 'react-i18next';
import type { ITaskWorkBlockDetailsEditOverlay } from '../TaskEditOverlay.utils';
import { useAppSelector } from '../../../../../../../app/store';
import { isTaskAssignToThisWorkBlock } from '../../../../../../../shared/utils/utils';
import { ReactComponent as CalendarIconComponent } from '../../../../../../../assets/images/add-edit-task-due-date-icon.svg';
import { getDateFormatted, getTimeRange } from '../../TasksList.utils';

interface IProps {
  taskWorkBlockDetails?: ITaskWorkBlockDetailsEditOverlay;
  onClickDetails: () => void;
}

const TaskOverlayWorkBlockDetails = ({ taskWorkBlockDetails, onClickDetails }: IProps) => {
  const { t } = useTranslation();
  const { allWorkBlocks } = useAppSelector((store) => store.StageTasksReducer);
  const workBlock =
    allWorkBlocks.find((w) =>
      isTaskAssignToThisWorkBlock(
        w.id!,
        w?.workTime || '',
        taskWorkBlockDetails?.workBlockId,
        taskWorkBlockDetails?.workBlockInstance,
      ),
    ) || null;

  return (
    <div onClick={onClickDetails} className="cursor-pointer">
      <h4 className="assigned-to-title static-string">{t('AssignedToTitle')}</h4>
      <div className="task-work-block-details">
        {t('plannerPlusOverlayWorkBlockOptionTitle')}:{' '}
        {workBlock?.name || t('workBlockDefaultName')}
      </div>
      {workBlock?.workTime && (
        <div className="task-work-block-details">
          <CalendarIconComponent />{' '}
          {`${getDateFormatted(new Date(workBlock.workTime))} ${getTimeRange(
            new Date(workBlock.workTime),
            workBlock?.duration,
          )}`}
        </div>
      )}
    </div>
  );
};

export default TaskOverlayWorkBlockDetails;
