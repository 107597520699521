import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { useApiData } from './useApiData';
import { removeDataAttributeOnBody, setDataAttributeOnBody } from '../utils/utils';
import { dataAttributes } from '../../app/constants';
import {
  setChatQueueProcessingType,
  setQueueMessageData,
} from '../../features/chat-wrapper/chat.store';
import { EAPIStatus } from '../api/models';
import { ApplicationInsightsApi } from '../../application-insights';

export type TChatQueueMessageType = 'automatic' | 'manual';

export interface IUseChatMessageQueuePayload {
  type: TChatQueueMessageType;
  botRequestJson: string;
  localUserChatMessage?: string;
}

export const useChatMessageQueue = (): [IUseChatMessageQueuePayload | null] => {
  const { botResponse, queueMessageData, sessionResponse, chatQueueProcessingType } =
    useAppSelector((store) => store.chatReducer);
  const { shouldDisplayProductTour } = useAppSelector((store) => store.sharedStoreReducer);
  const [queue, setQueue] = useState<IUseChatMessageQueuePayload[]>([]);
  const [nextMessage, setNextMessage] = useState<IUseChatMessageQueuePayload | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (queueMessageData) {
      ApplicationInsightsApi.trackTrace('useChatMessageQueue - useEffect running.');
      if (shouldDisplayProductTour) {
        ApplicationInsightsApi.trackTrace(
          'useChatMessageQueue - product tour is displayed, returning.',
        );
        setQueueMessageData(null);
        return;
      }
      if (sessionResponse.status === EAPIStatus.PENDING) {
        ApplicationInsightsApi.trackTrace(
          'useChatMessageQueue - sessionResponse is pending, returning.',
        );
        return;
      }
      if (botResponse.status === EAPIStatus.PENDING || queue.length > 0) {
        ApplicationInsightsApi.trackTrace(
          'useChatMessageQueue - botResponse is pending or queue is not empty, adding to queue.',
          { botResponseStatus: botResponse.status, queueLength: queue.length },
        );
        setQueue((currentQueue) => {
          return [...currentQueue, queueMessageData];
        });
      } else {
        ApplicationInsightsApi.trackTrace(
          'useChatMessageQueue - botResponse is not pending and queue is empty, processing message.',
          { queueMessageData },
        );
        setDataAttributeOnBody(dataAttributes.processingMessageType, queueMessageData.type);
        dispatch(setChatQueueProcessingType(queueMessageData.type));
        setNextMessage(queueMessageData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueMessageData]);

  const takeOutFirstMessage = () => {
    ApplicationInsightsApi.trackTrace('takeOutFirstMessage - takeOutFirstMessage running.');
    if (queue.length) {
      const firstMessage = queue[0];
      ApplicationInsightsApi.trackTrace('takeOutFirstMessage - firstMessage', { firstMessage });
      setDataAttributeOnBody(dataAttributes.processingMessageType, firstMessage.type);
      dispatch(setChatQueueProcessingType(firstMessage.type));
      setNextMessage(firstMessage);
      setQueue((currentQueue) => {
        return currentQueue.slice(1);
      });
    } else {
      ApplicationInsightsApi.trackTrace('takeOutFirstMessage - queue is empty, resetting queue.');
      resetQueueState();
    }
  };

  useApiData(botResponse, {
    onFulfilled() {
      takeOutFirstMessage();
    },
    onRejected() {
      // TODO: implement a more robust solution for handling errors when there are messages in the queue
      // for now, just reset the queue state
      // in the era pre automated messages, if there was an error in the bot response api, we removed the last user message from the chat
      // and placed it back inside the input box and the user could re-send it again.
      // this is still the case, but we also have automated messages which do not have a retry logic implemented in the ui yet.
      resetQueueState();
    },
  });

  // when app loads we need to wait for the session to be retrieved before processing any messages in the queue
  // this code makes sure the queue does not process any messages until the session is retrieved
  useApiData(sessionResponse, {
    onFulfilled() {
      resetQueueState();
    },
    onRejected() {
      resetQueueState();
    },
  });

  const resetQueueState = () => {
    ApplicationInsightsApi.trackTrace('resetQueueState - running.');
    if (chatQueueProcessingType) {
      ApplicationInsightsApi.trackTrace('resetQueueState - removing processingMessageType.');
      removeDataAttributeOnBody(dataAttributes.processingMessageType);
      dispatch(setChatQueueProcessingType(null));
    }
    if (nextMessage) {
      ApplicationInsightsApi.trackTrace('resetQueueState - resetting nextMessage.');
      setNextMessage(null);
    }
    if (queue.length > 0) {
      ApplicationInsightsApi.trackTrace('resetQueueState - resetting queue.');
      setQueue([]);
    }
  };

  return [nextMessage];
};
