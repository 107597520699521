export interface ITaskFields extends IRecurrenceValues {
  reminderTime?: string | null;
  dueDate?: string | null | Date;
  workTime?: string | null | Date;
  duration?: number | undefined | null; // seconds
  priority?: boolean;
  quickWin?: boolean;
  description?: string | null;
  notes?: string | null;
  name?: string | null;
  status?: ETaskStatus;
  tags?: ITaskTag[];
  dueDateReminder?: number | null;
  workTimeReminder?: number | null;
  parentId?: string | null;
  workTimeRecurrenceDates?: string[] | null;
  isEvent?: boolean;
  isWorkBlock?: boolean;
  source?: ETaskSource;
  workBlockId?: string | null;
  workBlockOrder?: number | null;
  workBlockInstance?: Date | string | null;
}
export interface IMessageDataTask extends ITaskFields {
  id?: string;
  name: string | null;
  creationTime: string;
  status: ETaskStatus;
  completionTime?: string;
  isMultiWorkTimeTask?: boolean;
  isRecurrenceInstance?: boolean;
  isFullDayEvent?: boolean;
  originalWorkTime?: string | null | Date;
}

export interface IPlannerCurrentTaskPlacement extends IMessageDataTask {
  workBlockName?: string | null;
  workBlockStartTime?: Date | null;
}

export interface IStageTasksListResponse {
  tasks: IMessageDataTask[];
  userTaskTimestampForDelta: number;
}

// tasks filter enum
export enum ETasksFilterType {
  OVERDUE = 'Late',
  SCHEDULED = 'Scheduled',
  UNSCHEDULED = 'Unscheduled',
  DONE = 'Done',
  ALL_TASKS = 'All Tasks',
  QUICK_WINS = 'Quick Win',
  PRIORITY = 'Priority',
  EVENT = 'Event',
}

export interface ISelectedTasksFilter {
  type?: ETasksFilterType;
  tag?: string;
}

export enum ETaskStatus {
  NOT_STARTED = 'NotStarted',
  DONE = 'Done',
  DELETED = 'Deleted',
}

export interface ITaskUpdateReqPayload extends ITaskFields {
  sessionId: string;
  id: string;
}

export interface ISubTask extends ITaskFields {
  id?: string;
  taskId?: string | undefined;
  creationTime?: string;
  name: string | null;
  status: ETaskStatus;
  isMultiWorkTimeTask?: boolean;
}
export interface ICreateTaskReqPayload extends ITaskFields {
  sessionId: string;
  name: string | null;
  status: ETaskStatus;
  steps?: ISubTask[] | null;
}

export interface IAddEditTaskForm extends ITaskFields {
  name: string;
  isDone: boolean;
  workTime: Date | undefined;
  dueDate: Date | undefined;
  priority: boolean;
  quickWin: boolean;
  subTasks?: ISubTask[] | null;
  multiWorkTimeSubTasks?: ISubTask[] | null;
  recurrenceValues: IRecurrenceValues;
}

export interface ITaskTag {
  name?: string | null;
  color?: string | null;
}

export enum ETaskRecurrenceType {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export interface IRecurrenceValues {
  workTimeRecurrenceType?: ETaskRecurrenceType | null;
  workTimeRecurrenceInterval?: number | null;
  workTimeRecurrenceWeekDays?: number[] | null;
  workTimeRecurrenceRules?: string[] | null;
}

export enum ETaskSource {
  Internal = 'Internal',
  Google = 'Google',
  Outlook = 'Outlook',
  ICal = 'ICal',
  Apple = 'Apple',
}

export enum ETaskFormType {
  Task = 1,
  Event,
}
