import type { FunctionComponent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import {
  getDateAndTimeWithoutYearAndWithoutDay,
  getDateAndTimeWithoutYearAndWithoutDayWithoutTime,
  getDateAndTimeWithoutYearWithoutTime,
  getTimeByDate,
} from '../../../../../../shared/utils/dateFormat';
import dayjs from 'dayjs';
import { ReactComponent as PriorityStarIconComponent } from '../../../../../../assets/images/priority-star-on.svg';
import { store, useAppDispatch, useAppSelector } from '../../../../../../app/store';
import {
  ETaskFormType,
  ETaskSource,
  ETaskStatus,
} from '../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import {
  getTasksListReqAction,
  setSelectedMainTaskForEditing,
  setShouldOpenAddEditTaskFrom,
} from '../../../../resizable-container/stage-container/stage-tasks/stageTasks.store';
import { ApplicationInsightsApi } from '../../../../../../application-insights';
import TaskDueActionButton from './task-action-buttons/TaskDueActionButton';
import TaskScheduleActionButton from './task-action-buttons/TaskScheduleActionButton';
import type { ISingleTaskItemContainerChildrenProps } from './SingleTaskItemContainer';
import { ETaskViewType } from './SingleTaskItemContainer';
import './TasksListSingleItem.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EventIcon } from '../../../../../../assets/images/single-task/event-icon.svg';
import { ReactComponent as QuickWinIcon } from '../../../../../../assets/images/single-task/task_quick_win_icon.svg';
import { ReactComponent as ExternalEventGoogle } from '../../../../../../assets/images/single-task/external-event-google.svg';
import { ReactComponent as ExternalEventApple } from '../../../../../../assets/images/single-task/external-event-apple.svg';
import { ReactComponent as ExternalEventMicrosoft } from '../../../../../../assets/images/single-task/external-event-microsoft.svg';
import { ReactComponent as ChevronIcon } from '../../../../../../assets/images/single-task/chevron.svg';
import { isMobileDevice } from '../../../../../../shared/utils/isMobileDevice';
import {
  getExternalEventSourceDetails,
  getFeatureFlagByKey,
  isMobileView,
} from '../../../../../../shared/utils/utils';
import TaskDeleteActionButton from './task-action-buttons/TaskDeleteActionButton';
import { uuid } from '../../../../../../shared/utils/uuid';
import { getFirstLabelColor, getTimeRange, isOverdueDate } from '../TasksList.utils';
import TaskTimerActionButton from './task-action-buttons/TaskTimerActionButton';
import getThemeColorValue from '../../../../../../shared/utils/themes.utils';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import { setPlannerCurrentTaskPlacement } from '../../stage-planner/stagePlanner.store';
import { EPlanViewSelection } from '../../stage-planner/stagePlanner.interface';
import { FEATURE_FLAGS } from '../../../../../../app/constants';
import { ESoundType, handleAppPlaySound } from '../../../../../../shared/utils/sound.utils';

const TasksListSingleItem: FunctionComponent<ISingleTaskItemContainerChildrenProps> = ({
  singleTask,
  className,
  shouldHideOnComplete,
  onClick,
  onUpdateTask,
  actionOverlaysPositionStyle,
  shouldDisplayActionButtons = false,
  isPriority,
  setIsPriority,
  viewType = undefined,
  testid = '',
  onClickSubtasksChevron,
  areSubtasksExpanded,
}) => {
  const dispatch = useAppDispatch();
  const completeTimerRef = useRef<NodeJS.Timeout | null>(null);
  const removeTasksTime = 2000;
  // to hide a complete task if in chat list
  const [isHidden, setIsHidden] = useState(false);
  const { t } = useTranslation();
  const [isStopPropagation, setIsStopPropagation] = useState(true);
  const isMobile = isMobileDevice();
  const { currentTaskPlacement } = useAppSelector((store) => store.StagePlannerReducer);
  const [uuidKey, setUuidKey] = useState<string>(uuid());
  const { planView } = useAppSelector((store) => store.StagePlannerReducer);

  useEffect(() => {
    // for re-render the component when the status changed outside this component (by the chat / by the task details form / by another instance of the same task inside the plan or the tasks list)
    setUuidKey(uuid());
  }, [singleTask?.status]);

  const updateTasksList = () => {
    dispatch(getTasksListReqAction());
  };

  const handleCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
    const status = (e.target as HTMLInputElement).checked
      ? ETaskStatus.DONE
      : ETaskStatus.NOT_STARTED;
    if (status === ETaskStatus.DONE) handleAppPlaySound(ESoundType.TASK_COMPLETED);
    onUpdateTask({ status: status });
    if (completeTimerRef.current) clearTimeout(completeTimerRef.current);
    completeTimerRef.current = setTimeout(() => {
      if ((e.target as HTMLInputElement).checked) {
        if (shouldHideOnComplete) setIsHidden(!isHidden);
        if (currentTaskPlacement?.id === singleTask.id)
          dispatch(setPlannerCurrentTaskPlacement(null));
      }
    }, removeTasksTime);
  };

  // if there is an onClick prop, this is a subTask and the onOpenEditTaskFormModal function won't be called.
  const onOpenEditTaskFormModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    // for tasks that are multi work time tasks or recurring instances, we need to open the parent task for editing
    if (singleTask.isMultiWorkTimeTask) {
      const parentTask = store
        .getState()
        .StageTasksReducer.allTasks.find((t) => t.id === singleTask.parentId);
      if (parentTask) dispatch(setSelectedMainTaskForEditing(parentTask));
    } else if (singleTask.isRecurrenceInstance) {
      const parentTask = store
        .getState()
        .StageTasksReducer.allTasks.find((t) => t.id === singleTask.id && !t.isRecurrenceInstance);
      if (parentTask) dispatch(setSelectedMainTaskForEditing(parentTask));
    } else {
      dispatch(setSelectedMainTaskForEditing(singleTask));
    }
    dispatch(
      setShouldOpenAddEditTaskFrom(singleTask.isEvent ? ETaskFormType.Event : ETaskFormType.Task),
    );
  };

  const getDueDateDisplayText = () => {
    try {
      const today = dayjs(new Date());
      const isDueDateToday = dayjs(new Date(singleTask.dueDate!)).isSame(today, 'day');
      const isDueDateTimeElevenFiftyNine = dayjs(singleTask.dueDate).isSame(
        dayjs(singleTask.dueDate).endOf('day'),
        'minute',
      );
      if (isDueDateToday && isDueDateTimeElevenFiftyNine) {
        if (viewType === ETaskViewType.MY_PLAN_DUE) return '';
        return getDateAndTimeWithoutYearAndWithoutDayWithoutTime(
          new Date(singleTask.dueDate!).toISOString(),
        );
      }
      if (isDueDateToday && viewType === ETaskViewType.MY_PLAN_DUE)
        return getTimeByDate(singleTask.dueDate!);
      if (isDueDateTimeElevenFiftyNine)
        return getDateAndTimeWithoutYearWithoutTime(new Date(singleTask.dueDate!).toISOString());
      return getDateAndTimeWithoutYearAndWithoutDay(new Date(singleTask.dueDate!).toISOString());
    } catch (error) {
      ApplicationInsightsApi.trackException(error);
      return '';
    }
  };

  const handleClickEvent = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // when this component is displayed on the calendar placement unscheduled tasks list return here if click target was input element or label (which is the checkbox to mark task as complete)
    if (
      viewType === ETaskViewType.CALENDAR_PLACEMENT &&
      ((e.target as HTMLElement).tagName === 'INPUT' ||
        (e.target as HTMLElement).tagName === 'LABEL')
    )
      return;

    if (viewType === ETaskViewType.IN_CHAT_TASK_LIST) return;
    if (viewType === ETaskViewType.CALENDAR_PLACEMENT) {
      if (isMobileView() || planView === EPlanViewSelection.MY_PLAN)
        dispatch(setPlannerCurrentTaskPlacement(singleTask));
      return;
    }
    if (!isStopPropagation) return;
    e.preventDefault();
    if (onClick) {
      onClick(e);
    } else onOpenEditTaskFormModal(e);
  };

  const getCircleIconColor = () => {
    if (singleTask?.isEvent && (!singleTask?.tags || singleTask?.tags.length === 0))
      return getThemeColorValue('backgroundTransparent');
    return getFirstLabelColor(singleTask);
  };

  const handleClickSubtasksChevron = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // only on the task-list for parent tasks
    if (viewType === ETaskViewType.TASK_LIST && !singleTask?.parentId && onClickSubtasksChevron) {
      e.preventDefault();
      e.stopPropagation();
      onClickSubtasksChevron();
    }
  };

  const getEventIcon = () => {
    switch (singleTask?.source) {
      case ETaskSource.Google:
        return <ExternalEventGoogle className="external-event-icon primary-color-stroke-icon" />;
      case ETaskSource.Outlook:
        return <ExternalEventMicrosoft className="external-event-icon primary-color-stroke-icon" />;
      case ETaskSource.Apple:
        return <ExternalEventApple className="external-event-icon primary-color-stroke-icon" />;
      default:
        return <EventIcon className="event-icon primary-color-stroke-icon" />;
    }
  };

  return (
    <div
      key={uuidKey}
      className={`tasks-list-item single-task-container--view-type-${viewType} ${
        className ? className : ''
      } ${singleTask?.isEvent ? 'tasks-list-item--event' : ''} ${
        isHidden ? 'd-none' : 'displayed'
      } ${singleTask?.parentId ? 'subtask-item' : ''} ${
        isMobile ? 'tasks-list-item--calender-placement' : ''
      } ${
        currentTaskPlacement && currentTaskPlacement.id === singleTask.id
          ? 'tasks-list-item--calendar-placement'
          : ''
      } ${singleTask?.status === ETaskStatus.DONE ? 'completed-task' : ''}`}
      data-testid={`${testid}-${singleTask.name}`}
      onClick={handleClickEvent}
    >
      <section
        className="task-item-left"
        style={
          {
            // --task-checkbox-color -> CSS variable that set the checkbox color.
            '--task-checkbox-color': getCircleIconColor(),
          } as React.CSSProperties
        }
      >
        {!singleTask?.isEvent && (
          <label
            onMouseEnter={() => setIsStopPropagation(false)}
            onMouseLeave={() => setIsStopPropagation(true)}
            onClick={(e) => {
              if (isStopPropagation) e.stopPropagation();
            }}
            className={`task-complete-label ${singleTask?.isEvent ? 'visibility-hidden' : ''}`}
          >
            <input
              className="complete-checkbox"
              type="checkbox"
              onInput={handleCheckbox}
              defaultChecked={singleTask?.status === ETaskStatus.DONE}
              disabled={viewType === ETaskViewType.MY_WEEK}
            />
          </label>
        )}
        {singleTask?.isEvent && (
          <div className="event-icon-circle-background">{getEventIcon()}</div>
        )}
        <div className="task-name-container">
          <span className="task-name">{singleTask.name}</span>
          {singleTask?.isEvent &&
            !!singleTask?.source &&
            singleTask?.source !== ETaskSource.Internal && (
              <span className="external-event-source">
                {getExternalEventSourceDetails(singleTask.source)}
              </span>
            )}
        </div>
        {viewType !== ETaskViewType.MY_WEEK && !!singleTask.quickWin && (
          <div className="quick-win-icon-container">
            <QuickWinIcon className="quick-win-icon" />
          </div>
        )}
        {!!onClickSubtasksChevron &&
          (viewType !== ETaskViewType.TASK_LIST ||
            (viewType === ETaskViewType.TASK_LIST && !singleTask.parentId)) && (
            <AppButton
              id="task-chevron"
              className="task-subtasks-chevron-btn"
              onClick={handleClickSubtasksChevron}
            >
              <ChevronIcon
                className={`task-chevron primary-color-stroke-icon ${
                  areSubtasksExpanded ? 'task-chevron--expanded' : ''
                }`}
              />
            </AppButton>
          )}
      </section>
      <section className="task-item-right">
        {!isMobile && shouldDisplayActionButtons && viewType !== ETaskViewType.MY_WEEK && (
          <div className="task-actions-container">
            <TaskDueActionButton
              task={singleTask}
              className="--desktop"
              onUpdateTask={onUpdateTask}
              actionOverlaysPositionStyle={actionOverlaysPositionStyle}
            />
            <TaskScheduleActionButton
              task={singleTask}
              className="--desktop"
              onUpdateTask={onUpdateTask}
              actionOverlaysPositionStyle={actionOverlaysPositionStyle}
            />
            {getFeatureFlagByKey(FEATURE_FLAGS.FOCUS_TIMER) && (
              <TaskTimerActionButton
                task={singleTask}
                className="--desktop"
                onUpdateTask={onUpdateTask}
                actionOverlaysPositionStyle={actionOverlaysPositionStyle}
              />
            )}
            {/* add delete action for task list task */}
            {viewType === ETaskViewType.TASK_LIST && (
              <TaskDeleteActionButton
                task={singleTask}
                className="--desktop"
                onUpdateTask={onUpdateTask}
              />
            )}
          </div>
        )}
        <div className="task-icons-container">
          {viewType !== ETaskViewType.CALENDAR_PLACEMENT && (
            <>
              {!!singleTask.dueDate && getDueDateDisplayText().length > 0 && (
                <div
                  className={`due-date-tag ${
                    isOverdueDate(singleTask.dueDate, singleTask.completionTime)
                      ? 'due-date-tag--overdue'
                      : ''
                  }`}
                >
                  {isOverdueDate(singleTask.dueDate, singleTask.completionTime)
                    ? t('overdueDueDateTagTitle')
                    : getDueDateDisplayText()}
                </div>
              )}
            </>
          )}
        </div>
        {viewType === ETaskViewType.MY_PLAN_SCHEDULED && singleTask?.workTime && (
          <div className="task-work-time-range">
            {getTimeRange(new Date(singleTask?.workTime), singleTask?.duration, true, false)}
          </div>
        )}
        {viewType !== ETaskViewType.MY_WEEK && !singleTask?.isEvent && (
          <AppButton
            type="button"
            id="task-priority-toggle"
            className="priority"
            onClick={(e) => {
              e.stopPropagation();
              setIsPriority(!isPriority);
              onUpdateTask({ priority: !isPriority });
            }}
          >
            <PriorityStarIconComponent
              className={`single-task-priority-icon single-task-priority-icon--${
                isPriority ? 'on' : 'off'
              }`}
            />
          </AppButton>
        )}
      </section>
    </div>
  );
};

export default TasksListSingleItem;
