import { createAction } from '@reduxjs/toolkit';
import { API_ROUTES } from '../../../../../app/constants';
import { APIRequestState, IAPIRequestState } from '../../../../../shared/api/models';
import { ApiDataStateType, createAPIReducerCases } from '../../../../../shared/store/utils';
import {
  ICreateTimerReqPayload,
  IModifyTimerReqPayload,
  IPauseTimerReqPayload,
  IResetTimerReqPayload,
  IStartTimerReqPayload,
  ITimerData,
  ITimerIdPayload,
} from './stageTimer.interface';
import { apiServiceCSharp, createApiThunk } from '../../../../../shared/api/axios';
import { createReducer } from '@reduxjs/toolkit';
import { IMessageDataTask } from '../stage-tasks/stageTasks.interface';
import {
  removeItemFromLocalStorage,
  setItemInLocalStorage,
} from '../../../../../shared/utils/localStorage.utils';
import { ESoundType, handleAppPlaySound } from '../../../../../shared/utils/sound.utils';

export const activeTimerIdLocalStorageKey = 'activeTimerId';
export const timerTaskLocalStorageKey = 'timerTask';

export const clearTimerStorageData = () => {
  removeItemFromLocalStorage(activeTimerIdLocalStorageKey);
  removeItemFromLocalStorage(timerTaskLocalStorageKey);
};

export interface IStageTimerStore extends ApiDataStateType {
  createTimerRes: IAPIRequestState<ITimerData>;
  getTimerRes: IAPIRequestState<ITimerData>;
  startTimerRes: IAPIRequestState<ITimerData>;
  pauseTimerRes: IAPIRequestState<ITimerData>;
  modifyTimerRes: IAPIRequestState<ITimerData>;
  resetTimerRes: IAPIRequestState<ITimerData>;
  deleteTimerRes: IAPIRequestState<ITimerData>;
  activeTimer: ITimerData | null;
  timerTask: IMessageDataTask | null;
  shouldPlaySound: boolean;
}

const initialStateStageTimerStore: IStageTimerStore = {
  createTimerRes: APIRequestState.create(),
  getTimerRes: APIRequestState.create(),
  startTimerRes: APIRequestState.create(),
  pauseTimerRes: APIRequestState.create(),
  modifyTimerRes: APIRequestState.create(),
  resetTimerRes: APIRequestState.create(),
  deleteTimerRes: APIRequestState.create(),
  activeTimer: null,
  timerTask: null,
  shouldPlaySound: true,
};

export const handleDoneSound = (payload: ITimerData): boolean => {
  if (payload.isCompleted || payload.hasError)
    return handleAppPlaySound(ESoundType.TIMER_COMPLETED);
  return false;
};

const createReducerKey = (subKey: string): string => {
  return 'stageTimer/' + subKey;
};

// Utility function to build timer API URLs with timerId
const buildTimerUrl = (baseUrl: string, payload?: ITimerIdPayload): string => {
  return baseUrl.replace('{timerId}', payload?.timerId || '');
};

export const createTimerReqAction = createApiThunk(
  createReducerKey('createTimerReqAction'),
  (payload?: ICreateTimerReqPayload) => {
    return apiServiceCSharp.post<ITimerData>(`${API_ROUTES.TIMER.CREATE_TIMER}`, payload);
  },
);

export const getTimerReqAction = createApiThunk(
  createReducerKey('getTimerReqAction'),
  (timerId?: string) => {
    return apiServiceCSharp.get<ITimerData>(`${API_ROUTES.TIMER.GET_TIMER}/${timerId}`);
  },
);

export const startTimerReqAction = createApiThunk(
  createReducerKey('startTimerReqAction'),
  (payload?: IStartTimerReqPayload) => {
    const url = buildTimerUrl(API_ROUTES.TIMER.START_TIMER, payload);
    return apiServiceCSharp.post<ITimerData>(url, payload);
  },
);

export const pauseTimerReqAction = createApiThunk(
  createReducerKey('pauseTimerReqAction'),
  (payload?: IPauseTimerReqPayload) => {
    const url = buildTimerUrl(API_ROUTES.TIMER.PAUSE_TIMER, payload);
    return apiServiceCSharp.post<ITimerData>(url, payload);
  },
);

export const modifyTimerReqAction = createApiThunk(
  createReducerKey('modifyTimerReqAction'),
  (payload?: IModifyTimerReqPayload) => {
    const url = buildTimerUrl(API_ROUTES.TIMER.MODIFY_TIMER, payload);
    return apiServiceCSharp.post<ITimerData>(url, payload);
  },
);

export const resetTimerReqAction = createApiThunk(
  createReducerKey('resetTimerReqAction'),
  (payload?: IResetTimerReqPayload) => {
    const url = buildTimerUrl(API_ROUTES.TIMER.RESET_TIMER, payload);
    return apiServiceCSharp.post<ITimerData>(url, payload);
  },
);

export const deleteTimerAction = createApiThunk(
  createReducerKey('deleteTimerAction'),
  (payload?: ITimerIdPayload) => {
    const url = buildTimerUrl(API_ROUTES.TIMER.DELETE_TIMER, payload);
    return apiServiceCSharp.delete(url);
  },
);

export const setActiveTimer = createAction<ITimerData | null>(createReducerKey('setActiveTimer'));

export const setTimerTask = createAction<IMessageDataTask | null>(createReducerKey('setTimerTask'));

export const setShouldPlaySound = createAction<boolean>(createReducerKey('setShouldPlaySound'));

export const StageTimerReducer = createReducer(initialStateStageTimerStore, (builder) => {
  createAPIReducerCases(createTimerReqAction, 'createTimerRes', builder, {
    onFulfilled(state, payload) {
      setItemInLocalStorage(activeTimerIdLocalStorageKey, payload.id);
      state.activeTimer = payload;
      state.shouldPlaySound = true;
    },
  });

  createAPIReducerCases(getTimerReqAction, 'getTimerRes', builder, {
    onFulfilled(state, payload) {
      state.activeTimer = payload;
    },
    onRejected(state) {
      const updateTimer = {
        ...state.activeTimer,
        hasError: true,
      };
      state.activeTimer = updateTimer;
    },
  });

  createAPIReducerCases(startTimerReqAction, 'startTimerRes', builder, {
    onFulfilled(state, payload) {
      state.activeTimer = payload;
    },
  });

  createAPIReducerCases(pauseTimerReqAction, 'pauseTimerRes', builder, {
    onFulfilled(state, payload) {
      state.activeTimer = payload;
    },
  });

  createAPIReducerCases(modifyTimerReqAction, 'modifyTimerRes', builder, {
    onFulfilled(state, payload) {
      state.activeTimer = payload;
      state.shouldPlaySound = true;
    },
  });

  createAPIReducerCases(resetTimerReqAction, 'resetTimerRes', builder, {
    onFulfilled(state, payload) {
      state.activeTimer = payload;
      state.shouldPlaySound = true;
    },
  });

  createAPIReducerCases(deleteTimerAction, 'deleteTimerRes', builder, {
    onFulfilled(state) {
      state.activeTimer = null;
      state.shouldPlaySound = true;
    },
  });

  builder.addCase(setActiveTimer, (state, action) => {
    state.activeTimer = action.payload;
  });
  builder.addCase(setTimerTask, (state, action) => {
    setItemInLocalStorage(timerTaskLocalStorageKey, action.payload);
    state.timerTask = action.payload;
  });
  builder.addCase(setShouldPlaySound, (state, action) => {
    state.shouldPlaySound = action.payload;
  });
});
