import React from 'react';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import './PlanTodayButton.scss';
import { useTranslation } from 'react-i18next';

const PlanTodayButton = () => {
  const { t } = useTranslation();

  const onScrollToTodayCard = () => {
    // scroll to today card element (id="day-0")
    const todayCardElement = document.getElementById('day-0');
    if (todayCardElement) {
      todayCardElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  return (
    <AppButton className="plan-today-button" id="plan-today-button" onClick={onScrollToTodayCard}>
      {t('planScreenTodayButtonText')}
    </AppButton>
  );
};

export default PlanTodayButton;
