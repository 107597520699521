import { ApplicationInsightsApi } from '../../application-insights';
import { ETaskRecurrenceType } from '../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import ICAL from 'ical.js';

// Constants
const ICAL_DAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] as const;
const MIN_INTERVAL = 1;

// Types
type ICalFrequency = 'DAILY' | 'WEEKLY' | 'MONTHLY';

// Validation functions
const isValidWeekday = (day: number): boolean => {
  return Number.isInteger(day) && day >= 0 && day < ICAL_DAYS.length;
};

const isValidInterval = (interval: number): boolean => {
  return Number.isInteger(interval) && interval >= MIN_INTERVAL;
};

export const generateRecurrenceRule = (
  recurrenceType: ETaskRecurrenceType | null | undefined,
  recurrenceInterval: number | null | undefined,
  recurrenceWeekdays: number[] | null | undefined,
): string[] | null => {
  try {
    if (!recurrenceType) {
      return null;
    }

    // Validate and set interval
    const interval = recurrenceInterval || MIN_INTERVAL;
    if (!isValidInterval(interval)) {
      ApplicationInsightsApi.trackTrace('Invalid recurrence interval:', { interval });
      return null;
    }

    // Create the RRULE
    const rrule = new ICAL.Property('rrule');
    let freq: ICalFrequency;

    switch (recurrenceType) {
      case ETaskRecurrenceType.Daily:
        freq = 'DAILY';
        break;
      case ETaskRecurrenceType.Weekly:
        freq = 'WEEKLY';
        break;
      case ETaskRecurrenceType.Monthly:
        freq = 'MONTHLY';
        break;
      default:
        ApplicationInsightsApi.trackTrace('Invalid recurrence type:', { recurrenceType });
        return null;
    }

    // Set up the RRULE parameters
    const rruleParams: Record<string, string> = {
      FREQ: freq,
      INTERVAL: interval.toString(),
    };

    // Handle weekdays for weekly recurrence
    if (
      recurrenceType === ETaskRecurrenceType.Weekly &&
      recurrenceWeekdays &&
      recurrenceWeekdays.length > 0
    ) {
      // Validate weekdays
      if (!recurrenceWeekdays.every(isValidWeekday)) {
        ApplicationInsightsApi.trackTrace('Invalid weekday indices:', { recurrenceWeekdays });
        return null;
      }

      // Convert our 0-6 day index (Sunday-Saturday) to ICAL day constants
      const days = recurrenceWeekdays.map((day) => ICAL_DAYS[day]);
      rruleParams.BYDAY = days.join(',');
    }

    rrule.setValue(rruleParams);
    return [rrule.toICALString()];
  } catch (error) {
    ApplicationInsightsApi.trackTrace('Error generating recurrence rule');
    ApplicationInsightsApi.trackException(error);
    return null;
  }
};
