import { useState } from 'react';
import type { FieldValues, FieldPath } from 'react-hook-form';
import { AppSingleCalendar } from '../../../../../../shared/components/app-calendars/AppSingleCalendar';
import TaskEditOverlayHeaderAndAction from './TaskEditOverlayHeaderAndAction';
import removeAppOverlayPopover from '../../../../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { AppTimePicker } from '../../../../../../shared/components/app-timers/AppTimePicker';
import { TaskEditOverlayDurationPicker } from './TaskEditOverlayDurationPicker';
import { ReactComponent as SmallClock } from '../../../../../../assets/images/max-small-clock.svg';
import { ReactComponent as BellIcon } from '../../../../../../assets/images/task-overlay-bell-icon.svg';
import { useTranslation } from 'react-i18next';
import type { IRecurrenceValues, ITaskTag } from '../stageTasks.interface';
import TaskTagsOverlay from './task-tags-overlay/TaskTagsOverlay';
import ReminderOverlay from './reminder-overlay/ReminderOverlay';
import type { ITaskWorkBlockDetailsEditOverlay } from './TaskEditOverlay.utils';
import { getReminderCalendarText } from './TaskEditOverlay.utils';
import {
  colorPalette,
  recentTaskTagsLocalStorageKey,
  taskDueDateDefaultTime,
} from '../../../../../../app/constants';
import './TaskEditOverlay.scss';
import OverlayTypeDetails from './overlay-type-details/OverlayTypeDetails';
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from '../../../../../../shared/utils/localStorage.utils';
import { TaskOverlayWorkTime } from './TaskOverlayWorkTime';
import { isMobileView } from '../../../../../../shared/utils/utils';
import TaskOverlayWorkBlock from './task-overlay-work-block/TaskOverlayWorkBlock';

export type ITaskEditorOverlayType = 'dueDate' | 'workTime' | 'duration' | 'tags' | 'taskWorkBlock';

export interface ITaskEditOverlayProps<T extends FieldValues> {
  overlayType: ITaskEditorOverlayType;
  isSubTask?: boolean;
  date?: Date;
  duration?: number | null;
  reminder?: number | null;
  formValueName?: FieldPath<T>;
  recurrenceValues?: IRecurrenceValues;
  onChange?: (
    data: Date | number | ITaskTag | undefined,
    reminder?: number | null,
    duration?: number | null,
    recurrenceValues?: IRecurrenceValues | undefined,
    isWorkBlockCleared?: boolean,
  ) => void;
  onClear?: () => void;
  onClose?: () => void;
  setCalendarAsTimePicker?: (isTimePickerMultipleWorkTime?: boolean, taskId?: string) => void;
  isMultiWorkTimeSelector?: boolean;
  taskId?: string;
  isWorkBlock?: boolean;
  taskWorkBlockDetails?: ITaskWorkBlockDetailsEditOverlay;
}

export const TaskEditOverlay = <T extends FieldValues>({
  overlayType,
  isSubTask,
  date,
  duration,
  reminder,
  formValueName,
  recurrenceValues,
  onChange,
  onClear,
  onClose,
  setCalendarAsTimePicker,
  isMultiWorkTimeSelector,
  taskId,
  isWorkBlock,
  taskWorkBlockDetails,
}: ITaskEditOverlayProps<T>) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date);
  const [selectedDuration, setSelectedDuration] = useState<number | undefined | null>(duration);
  const [selectedReminder, setSelectedReminder] = useState<number | undefined | null>(reminder);
  const [selectedDefaultReminderOnInit, setSelectedDefaultReminderOnInit] = useState<
    number | undefined | null
  >(null);
  const [reminderOverlayType, setReminderOverlayType] = useState<'quickOptions' | 'custom' | null>(
    null,
  );
  const [taskTag, setTaskTag] = useState<ITaskTag | null>(
    overlayType === 'tags' ? { name: null, color: colorPalette[0] } : null,
  );
  const { t } = useTranslation();
  const now = new Date();
  const isBefore4pm = now.getHours() < 16;
  const isWithinHourFromMidnight = now.getHours() >= 22 && now.getMinutes() <= 59;
  const [selectedRecurrenceValues, setSelectedRecurrenceValues] = useState<IRecurrenceValues>(
    recurrenceValues ? recurrenceValues : getInitializeSelectedRecurrenceValues(),
  );

  // returns true if the time in the selected date is 23:59
  const displayDefaultTimeString = () => {
    switch (overlayType) {
      case 'dueDate':
        return !!selectedDate && selectedDate.getHours() === 23 && selectedDate.getMinutes() === 59
          ? `${t('taskOverlayDueDateTimeDefaultString')} (${taskDueDateDefaultTime})`
          : null;
      case 'workTime':
        return !!selectedDate && selectedDate.getHours() === 17 && selectedDate.getMinutes() === 0
          ? `${t('taskOverlayWorkTimeDefaultString')} (5:00PM)`
          : null;
      default:
        return null;
    }
  };
  const [showTimePicker, setShowTimePicker] = useState<boolean>(
    !!(selectedDate && !displayDefaultTimeString()),
  );

  const handleSetLater = () => {
    if (isWithinHourFromMidnight) {
      return;
    }
    let laterDate: Date;
    if (isBefore4pm) {
      laterDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 0, 0);
    } else {
      const roundedHour = Math.ceil(now.getHours() + 1);
      laterDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), roundedHour, 0, 0);
    }
    setSelectedDate(new Date(laterDate));
    setShowTimePicker(true);
    return new Date(laterDate);
  };

  const handleSetTomorrow = () => {
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 16, 0, 0);
    setSelectedDate(new Date(tomorrow));
    setShowTimePicker(true);
    return new Date(tomorrow);
  };

  const handleSetNextWeekend = () => {
    const upcomingSaturday = new Date();
    upcomingSaturday.setDate(now.getDate() + ((6 + (7 - now.getDay())) % 7));
    upcomingSaturday.setHours(9, 0, 0, 0);
    setSelectedDate(new Date(upcomingSaturday));
    setShowTimePicker(true);
    return new Date(upcomingSaturday);
  };

  const handleChange = (changedValue: Date | number | undefined) => {
    if (changedValue) {
      if (typeof changedValue === 'number') {
        setSelectedDuration(changedValue);
      } else {
        if (!selectedDate) setSelectedDate(changedValue);
        else {
          if (selectedDate.getDate() !== changedValue.getDate()) {
            setSelectedDate(
              new Date(changedValue.setHours(selectedDate.getHours(), selectedDate.getMinutes())),
            );
          } else {
            setSelectedDate(
              new Date(selectedDate.setHours(changedValue.getHours(), changedValue.getMinutes())),
            );
          }
        }
      }
    }
  };

  const handleReminderOverlaySet = () => {
    setReminderOverlayType(null);
  };

  const handleReminderOverlayClear = () => {
    setSelectedReminder(null);
    setSelectedDefaultReminderOnInit(null);
    setReminderOverlayType(null);
  };

  const handleSet = () => {
    if (overlayType === 'workTime' || overlayType === 'taskWorkBlock') {
      if (onChange)
        onChange(
          selectedDate || taskTag || undefined,
          selectedDefaultReminderOnInit ?? selectedReminder,
          selectedDuration,
          selectedRecurrenceValues,
        );
    } else {
      if (onChange)
        onChange(
          selectedDate || taskTag || undefined,
          selectedDefaultReminderOnInit ?? selectedReminder,
        );
    }
    if (taskTag) saveInRecentTags();
    if (onClose) onClose();
    else removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  };

  const saveInRecentTags = () => {
    const recentTags = getItemFromLocalStorage<ITaskTag[]>(recentTaskTagsLocalStorageKey);
    // if recentTags array is not exist yet or if is exist and not including this tag - save this tag inside the recentTags local array - avoid duplicates tags
    if (
      !recentTags ||
      (!!recentTags &&
        !recentTags.find((tag) => tag?.color === taskTag?.color && tag?.name === taskTag?.name))
    ) {
      setItemInLocalStorage(recentTaskTagsLocalStorageKey, [...(recentTags || []), taskTag]);
    }
  };

  // called when recent tag is clicked - to trigger onChange and close the overlay
  const handleTagsSet = (recentTag: ITaskTag) => {
    if (onChange) onChange(recentTag);
    if (onClose) onClose();
    else removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  };

  const handleWorkTimeQuickOptions = (handler: () => Date | undefined) => {
    if (onChange) onChange(handler(), selectedReminder, selectedDuration, selectedRecurrenceValues);
    if (onClose) onClose();
    else removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  };

  const handleDurationChange = (duration: number) => {
    if (onChange) onChange(duration);
    if (onClose) onClose();
    else removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  };

  const handleClear = () => {
    setSelectedDate(undefined);
    setSelectedDuration(undefined);
    setSelectedReminder(undefined);
    setSelectedDefaultReminderOnInit(undefined);
    setTaskTag({ name: null });
    setShowTimePicker(false);
    if (onClear) onClear();
    else if (onChange)
      onChange(undefined, undefined, undefined, undefined, overlayType === 'taskWorkBlock');
    if (onClose) onClose();
    else removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  };

  const handleClose = () => {
    if (onClose) onClose();
    else removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  };

  const getTypeOfTime = () => {
    switch (overlayType) {
      case 'dueDate':
        return t('createOrEditDueDatePopupTypeOfTime');
      case 'workTime':
        return t('createOrEditWorkTimePopupTypeOfTime');
      default:
        return '';
    }
  };

  const onClickBackIcon = () => {
    switch (reminderOverlayType) {
      case 'custom':
        setReminderOverlayType('quickOptions');
        break;
      case 'quickOptions':
        setReminderOverlayType(null);
        break;
      default:
        handleClose();
    }
  };

  const getSingleCalendar = () => {
    return (
      <AppSingleCalendar
        overlayType={overlayType}
        className="task-edit-overlay-date-picker"
        selectedDate={selectedDate}
        onSelect={handleChange}
      />
    );
  };

  function getInitializeSelectedRecurrenceValues() {
    return {
      workTimeRecurrenceType: null,
      workTimeRecurrenceInterval: null,
      workTimeRecurrenceWeekDays: null,
    };
  }

  const getWorkTimeContent = () => {
    return (
      <>
        <TaskOverlayWorkTime
          handleWorkTimeQuickOptions={handleWorkTimeQuickOptions}
          selectedDate={selectedDate}
          displayDefaultTimeString={displayDefaultTimeString}
          setSelectedReminder={setSelectedReminder}
          setSelectedDefaultReminderOnInit={setSelectedDefaultReminderOnInit}
          selectedReminder={selectedReminder}
          handleDateChange={handleChange}
          handleClear={handleClear}
          handleSet={handleSet}
          onSetLater={handleSetLater}
          onSetTomorrow={handleSetTomorrow}
          onSetNextWeekend={handleSetNextWeekend}
          handleClose={handleClose}
          setSelectedDuration={setSelectedDuration}
          selectedDuration={selectedDuration}
          isSubTask={isSubTask}
          recurrenceValues={selectedRecurrenceValues}
          getInitializeSelectedRecurrenceValues={getInitializeSelectedRecurrenceValues}
          setRecurrenceValues={setSelectedRecurrenceValues}
          setCalendarAsTimePicker={setCalendarAsTimePicker}
          isMultipleWorkTimeSelector={!!isMultiWorkTimeSelector}
          taskId={taskId}
          isWorkBlock={isWorkBlock}
        />
      </>
    );
  };

  const renderContentByOverlayType = () => {
    switch (overlayType) {
      case 'workTime':
        if (!reminderOverlayType) return getWorkTimeContent();
        break;
      case 'dueDate':
        if (!reminderOverlayType) return getSingleCalendar();
        break;
      case 'duration':
        return (
          <TaskEditOverlayDurationPicker
            handleDurationChange={handleDurationChange}
            handleSet={handleSet}
            handleClear={handleClear}
            initialDuration={selectedDuration}
            onChooseDuration={handleChange}
            onClose={handleClose}
            isSubTask={isSubTask}
          />
        );
      case 'tags':
        return (
          <TaskTagsOverlay
            handleChange={(tag, isRecentTag = false) =>
              isRecentTag ? handleTagsSet(tag) : setTaskTag(tag)
            }
            taskTag={taskTag || { name: null, color: colorPalette[0] }}
          />
        );
      case 'taskWorkBlock':
        return (
          <TaskOverlayWorkBlock
            handleClear={handleClear}
            handleSet={handleSet}
            handleClose={handleClose}
            taskId={taskId}
            taskWorkBlockDetails={taskWorkBlockDetails}
            setSelectedDuration={setSelectedDuration}
            selectedDuration={selectedDuration}
            isSubTask={isSubTask}
            setCalendarAsTimePicker={setCalendarAsTimePicker}
            isMultiWorkTimeSelector={!!isMultiWorkTimeSelector}
          />
        );
    }
  };

  const shouldDisplayReminderOverlay = () => {
    return !!reminderOverlayType && !!selectedDate;
  };

  const shouldDisplayTimePicker = () => {
    return (
      !['duration', 'tags', 'workTime', 'taskWorkBlock'].includes(overlayType) &&
      !reminderOverlayType
    );
  };

  return (
    <div className="task-edit-overlay-container">
      {!['duration', 'workTime', 'taskWorkBlock'].includes(overlayType) && (
        <TaskEditOverlayHeaderAndAction
          onClear={reminderOverlayType ? handleReminderOverlayClear : handleClear}
          onSet={reminderOverlayType ? handleReminderOverlaySet : handleSet}
          shouldHideSet={reminderOverlayType === 'quickOptions'}
          onClickBackArrow={
            isSubTask || !!reminderOverlayType
              ? onClickBackIcon
              : isMobileView()
              ? handleClose
              : null
          }
        />
      )}
      {renderContentByOverlayType()}
      {shouldDisplayReminderOverlay() && (
        <ReminderOverlay
          setReminderOverlayType={setReminderOverlayType}
          reminderOverlayType={reminderOverlayType}
          editOverlayType={overlayType}
          relativeDate={selectedDate!}
          selectedReminder={selectedReminder}
          setSelectedReminder={setSelectedReminder}
          setSelectedDefaultReminderOnInit={setSelectedDefaultReminderOnInit}
        />
      )}
      {shouldDisplayTimePicker() && (
        <>
          {!showTimePicker && (!selectedDate || !!displayDefaultTimeString()) && (
            <OverlayTypeDetails
              SvgComponent={SmallClock}
              title={getTypeOfTime()}
              data={displayDefaultTimeString()}
              disabled={!selectedDate}
              onClick={() => setShowTimePicker(true)}
            />
          )}
          {selectedDate && showTimePicker && (
            <AppTimePicker
              className="task-edit-overlay-time-picker"
              onChange={handleChange}
              date={selectedDate}
            />
          )}
          <OverlayTypeDetails
            SvgComponent={BellIcon}
            title={t('ReminderCalendarTitle')}
            data={getReminderCalendarText(
              selectedDefaultReminderOnInit ?? selectedReminder,
              overlayType,
              selectedDate,
            )}
            addOpacity={
              !selectedDefaultReminderOnInit &&
              selectedDefaultReminderOnInit !== 0 &&
              !selectedReminder &&
              selectedReminder !== 0
            }
            disabled={!selectedDate}
            onClick={() => setReminderOverlayType('quickOptions')}
          />
        </>
      )}
    </div>
  );
};
