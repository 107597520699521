// This component should not be used directly across the app; it should only be inherited by other button components.
import type { ButtonHTMLAttributes, FunctionComponent, MouseEvent, ReactNode } from 'react';
import './AppButton.scss';

export interface IAppButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: MouseEvent<HTMLButtonElement, any>) => void;
  children?: ReactNode | ReactNode[];
  id: string; // mandatory
}

const AppButton: FunctionComponent<IAppButtonProps> = ({
  className = '',
  type = 'button',
  ...attributes
}) => {
  return (
    <button {...attributes} type={type} className={`app-button ${className}`}>
      {attributes?.children}
    </button>
  );
};

export default AppButton;
