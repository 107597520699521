import type { HTMLAttributes } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { setTasksFilter } from '../stageTasks.store';
import { ETasksFilterType } from '../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from '../../../../../../assets/images/filter.svg';
import './StageTasksDropdownFilters.scss';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import XCloseIcon from '../../../../../../shared/components/icons/XCloseIcon';
import FilterItem from './FilterItem';
import { parseTagHash } from '../TasksList.utils';
import SingleTag from '../create-or-edit-task/task-tags/SingleTag';
import { ApplicationInsightsApi } from '../../../../../../application-insights';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  filters?: ETasksFilterType[];
}

export const StageTasksDropdownFilters = ({ ...attributes }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { tasksFilter, tagsToDisplayOnTasksFilter } = useAppSelector(
    (store) => store.StageTasksReducer,
  );
  const filterOptionsArr: ETasksFilterType[] = [
    ETasksFilterType.PRIORITY,
    ETasksFilterType.OVERDUE,
    ETasksFilterType.QUICK_WINS,
    ETasksFilterType.UNSCHEDULED,
    ETasksFilterType.DONE,
  ];
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // if click at the document outside the dropdown and the dropDown is open, close it
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        dropdownRef.current.clientHeight > 0
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleFilterType = (type: ETasksFilterType) => {
    try {
      let tempTasksFilter = [...tasksFilter];
      // Check if the type filter already exists
      const typeIndex = tempTasksFilter.findIndex((item) => item.type === type);
      if (typeIndex !== -1) {
        tempTasksFilter.splice(typeIndex, 1); // Remove existing type filter
      } else {
        tempTasksFilter.push({ type }); // Add new type filter
      }

      dispatch(setTasksFilter(tempTasksFilter));
    } catch (e) {
      console.error('handleRemoveItemFromFilter faild with error: ', e);
      ApplicationInsightsApi.trackException(e);
    }
  };

  const handleFilterTags = (tag: string) => {
    try {
      let tempTasksFilter = [...tasksFilter];
      // Check if the tag filter already exists
      const tagIndex = tempTasksFilter.findIndex((item) => item.tag === tag);
      if (tagIndex !== -1) {
        tempTasksFilter.splice(tagIndex, 1); // Remove existing tag filter
      } else {
        tempTasksFilter.push({ tag }); // Add new tag filter
      }
      dispatch(setTasksFilter(tempTasksFilter));
    } catch (e) {
      console.error('handleFilterTags faild with error: ', e);
      ApplicationInsightsApi.trackException(e);
    }
  };

  const handleClearFilter = () => {
    dispatch(setTasksFilter([]));
    setIsOpen(false);
  };

  return (
    <div className={`stage-tasks-dropdown-filter ${isOpen ? 'open' : ''} `} {...attributes}>
      <AppButton
        id="open-tasks-filter-dropdown-menu"
        type="button"
        className="open-tasks-filter"
        onClick={() => setIsOpen(true)}
      >
        <FilterIcon className="primary-color-stroke-icon" />
      </AppButton>
      <div className={`dropdown-menu`} ref={dropdownRef}>
        <div className="tasks-filter-scrollable-container">
          <div className="dropdown-view">
            <div>
              <h4 className="filter-title">{t('tasksFiltersTitle')}</h4>
            </div>
            <div className="filter-buttons">
              <AppButton
                id="clear-tasks-filter"
                className="clear-tasks-filter"
                onClick={handleClearFilter}
              >
                {t('tasksFiltersClearFilterButtonText')}
              </AppButton>
              <AppButton
                id="close-tasks-filter-dropdown-menu"
                type="button"
                className="close-tasks-filter"
                onClick={() => setIsOpen(false)}
              >
                <XCloseIcon />
              </AppButton>
            </div>
          </div>
          <div className="filter-options-container">
            {filterOptionsArr.map((filterTypeItem) => (
              <AppButton
                id={`${filterTypeItem?.trim()}-filter-stage-dropdown-menu`}
                key={filterTypeItem}
                className={`dropdown-item ${
                  tasksFilter?.find((item) => item.type === filterTypeItem) && 'active'
                }`}
                onClick={() => {
                  handleFilterType(filterTypeItem);
                }}
              >
                <FilterItem filterItem={{ type: filterTypeItem }} />
              </AppButton>
            ))}
          </div>
          <h4 className="filter-title tags-title">{t('tasksFiltersTagsTitle')}</h4>
          <div className="filter-options-container filter-options-container--tags">
            {tagsToDisplayOnTasksFilter.map((tag) => {
              return (
                <AppButton
                  id={`${tag?.trim()}-filter-stage-dropdown-menu`}
                  key={tag}
                  className={`dropdown-item dropdown-tag-item ${
                    tasksFilter?.find((item) => item.tag === tag) && 'active'
                  }`}
                  onClick={() => {
                    handleFilterTags(tag);
                  }}
                >
                  <SingleTag tag={parseTagHash(tag)} />
                </AppButton>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
