import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import {
  ETaskStatus,
  IMessageDataTask,
  IStageTasksListResponse,
} from '../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { getItemFromLocalStorage } from '../shared/utils/localStorage.utils';
import {
  clearTimerStorageData,
  setActiveTimer,
  timerTaskLocalStorageKey,
} from '../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.store';
import { AppDispatch, RootState } from './store';
import { ApplicationInsightsApi } from '../application-insights';
import { ETimerStatus } from '../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.interface';

export const clearLocalTimerOnTaskDeleteMiddleware: Middleware = (store) => (next) => (action) => {
  try {
    const getTasksAction = 'getTasksListReqAction';
    const taskListPollingAction = 'getTasksListPollingReqAction';
    const updateTaskAction = 'updateTaskRes';
    const currentAction = action.type;
    if (
      currentAction &&
      (currentAction?.includes(getTasksAction) || currentAction?.includes(taskListPollingAction))
    ) {
      const payload = action.payload as IStageTasksListResponse;
      if (payload?.tasks && payload?.tasks?.length > 0) {
        const currentTimerTask =
          getItemFromLocalStorage<IMessageDataTask>(timerTaskLocalStorageKey);
        if (currentTimerTask) {
          const existingTaskInResponse = payload.tasks.find(
            (task) => task.id === currentTimerTask.id,
          );
          if (existingTaskInResponse && existingTaskInResponse.status === ETaskStatus.DELETED) {
            clearTimerStorageData();
            handleActiveTimer(store);
          }
        }
      }
    }
    if (currentAction && currentAction?.includes(updateTaskAction)) {
      const payload = action.payload as IMessageDataTask;
      const currentTimerTask = getItemFromLocalStorage<IMessageDataTask>(timerTaskLocalStorageKey);
      if (
        currentTimerTask &&
        currentTimerTask.id === payload.id &&
        payload.status === ETaskStatus.DELETED
      ) {
        clearTimerStorageData();
        handleActiveTimer(store);
      }
    }
    const res = next(action);
    return res;
  } catch (e) {
    ApplicationInsightsApi.trackException(e);
    ApplicationInsightsApi.trackTrace('clearLocalTimerOnTaskDeleteMiddleware - Error');
    return next(action);
  }
};

const handleActiveTimer = (store: MiddlewareAPI<AppDispatch, RootState>) => {
  const activeTimer = store?.getState()?.StageTimerReducer?.activeTimer;
  if (activeTimer) {
    store.dispatch(
      setActiveTimer({
        ...activeTimer,
        status: ETimerStatus.PAUSED,
        hasError: true,
      }),
    );
  }
};
