import type { FunctionComponent } from 'react';
import React from 'react';
import type { ITaskSingleActionButtonsProps } from '../TasksListSingleItem.interfaces';
import { useTranslation } from 'react-i18next';
import createAppOverlayPopover from '../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import AppPopUpToast from '../../../../../../../shared/components/app-pop-up-toast/AppPopUpToast';
import removeAppOverlayPopover from '../../../../../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { ETaskStatus } from '../../stageTasks.interface';
import { popUpToastOverlayClassName } from '../../../../../../../app/constants';
import { deleteTaskModalAppOverlayPosition } from '../../TasksList.utils';
import TaskActionButton from './TaskActionButton';

const TaskDeleteActionButton: FunctionComponent<ITaskSingleActionButtonsProps> = ({
  onUpdateTask,
  className = '',
}) => {
  const { t } = useTranslation();

  const onClickDeleteAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    createAppOverlayPopover(
      <AppPopUpToast
        id={'delete-task-modal'}
        title={'taskListDeleteTaskConfirmationText'}
        CTA_Text={'taskListDeleteTaskConfirmToastDeleteButtonText'}
        dontShowAgainBtnText={'taskListDeleteTaskConfirmToastCancelButtonText'}
        onClose={() => removeAppOverlayPopover()}
        CTA_action={() => {
          onUpdateTask({ status: ETaskStatus.DELETED });
          removeAppOverlayPopover();
        }}
      />,
      popUpToastOverlayClassName + ' delete-task-modal',
      e,
      { ...deleteTaskModalAppOverlayPosition() },
      {
        closeOnClickOutside: false,
        allowInteraction: true,
      },
    );
  };

  return (
    <>
      <TaskActionButton
        actionName={t('taskDeleteActionButtonText')}
        actionType="delete"
        onClickAction={onClickDeleteAction}
        className={className}
      />
    </>
  );
};

export default TaskDeleteActionButton;
