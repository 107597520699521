import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { getWorkBlockFormDefaultValues } from '../workBlock.utils';
import type { IWorkBlockFormData } from '../workBlock.interface';
import type {
  ICreateTaskReqPayload,
  IMessageDataTask,
} from '../../stage-tasks/stageTasks.interface';
import { ETaskStatus } from '../../stage-tasks/stageTasks.interface';
import { createTaskReqAction, updateTaskReqAction } from '../../stage-tasks/stageTasks.store';
import WorkBlockNameController from './work-block-name-controller/WorkBlockNameController';
import WorkBlockScheduling from './work-block-scheduling/WorkBlockScheduling';
import WorkBlockDeleteController from './work-block-delete-controller/WorkBlockDeleteController';
import './WorkBlockDetailsForm.scss';
import { setClickNavbarConfirmModalText } from '../../../../../../shared/store/shared.store';
import { setQueueMessageData } from '../../../../chat.store';
import { createAutomatedTaskEvent } from '../../stage-tasks/TasksList.utils';
import StyledAppButton from '../../../../../../shared/components/app-button/styled-app-button/StyledAppButton';

interface IWorkBlockDetailsFormProps {
  setShouldCloseDialog: Dispatch<SetStateAction<boolean>>;
}

const WorkBlockDetailsForm: FunctionComponent<IWorkBlockDetailsFormProps> = ({
  setShouldCloseDialog,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workBlockForEdit } = useAppSelector((store) => store.workBlockReducer);
  const { clickNavbarConfirmModalText } = useAppSelector((store) => store.sharedStoreReducer);
  const workBlockForm = useForm<IWorkBlockFormData>({
    defaultValues: getWorkBlockFormDefaultValues(workBlockForEdit),
  });
  const startTimeWatch = workBlockForm.watch().workTime;
  const durationWatch = workBlockForm.watch().duration;

  const onSubmitCreateWorkBlock = (formData: IWorkBlockFormData) => {
    if (!shouldSaveBtnBeDisabled() && !workBlockForEdit) {
      delete formData.id;
      dispatch(createTaskReqAction({ ...formData } as ICreateTaskReqPayload))
        .unwrap()
        .then(() => setShouldCloseDialog(true));
    }
  };

  const updateLocalFormFields = (fields: IWorkBlockFormData) => {
    Object.entries(fields).forEach(([fieldName, value]) => {
      workBlockForm.setValue(fieldName as keyof IWorkBlockFormData, value);
    });
  };

  const onChangeFields = (fields: IWorkBlockFormData) => {
    updateLocalFormFields(fields);
    const formValues = workBlockForm.getValues();
    if (formValues?.id) {
      dispatch(
        updateTaskReqAction({
          ...fields,
          id: formValues.id,
          sessionId: formValues.sessionId || '',
        }),
      )
        .unwrap()
        .then((updatedWorkBlock) => {
          handleUpdatedWorkBlock(updatedWorkBlock, fields);
        });
    } else if (!clickNavbarConfirmModalText)
      dispatch(setClickNavbarConfirmModalText(t('newWorkBlockWillNotBeSavedNavbarConfirm')));
  };

  const shouldSaveBtnBeDisabled = () => {
    return (
      !workBlockForm.formState.isValid ||
      workBlockForm.formState.isSubmitted ||
      !startTimeWatch ||
      !durationWatch
    );
  };

  const handleUpdatedWorkBlock = (
    response: IMessageDataTask,
    updatedFields: IWorkBlockFormData,
  ) => {
    if (response.status === ETaskStatus.DELETED) {
      handleSendUXEvent(updatedFields, 'status');
      setShouldCloseDialog(true);
    }
  };

  const handleSendUXEvent = (updatedFields: IWorkBlockFormData, key: keyof IWorkBlockFormData) => {
    const formValues = workBlockForm.getValues();
    dispatch(
      setQueueMessageData({
        type: 'automatic',
        botRequestJson: JSON.stringify(
          createAutomatedTaskEvent(
            formValues.sessionId || '',
            key,
            updatedFields[key],
            workBlockForEdit!,
          ),
        ),
      }),
    );
  };

  return (
    <FormProvider {...workBlockForm}>
      <form
        className="work-block-form"
        id="work-block-form"
        data-testid="work-block-form-view"
        onSubmit={workBlockForm.handleSubmit(onSubmitCreateWorkBlock)}
      >
        <section className="details-section">
          {!workBlockForEdit?.id && (
            <StyledAppButton
              id="submit-work-block-form"
              type="submit"
              className="save-button"
              disabled={shouldSaveBtnBeDisabled()}
            >
              {t('addNewTaskSubmitButtonText')}
            </StyledAppButton>
          )}
          {workBlockForEdit?.id && <WorkBlockDeleteController onChangeFields={onChangeFields} />}
          <WorkBlockNameController onChangeFields={onChangeFields} />
          <WorkBlockScheduling onChangeFields={onChangeFields} />
        </section>
      </form>
    </FormProvider>
  );
};

export default WorkBlockDetailsForm;
