import { useCallback, useEffect } from 'react';
import { useAppSelector } from '../../../app/store';
import { ApplicationInsightsApi } from '../../../application-insights';
import { useWebsiteTabVisibility } from '../../hooks/useWebsiteTabVisibility';

const INACTIVITY_THRESHOLD = 1000 * 60 * 10; // 10 minutes

export const VersionTracker = () => {
  const { updateNeeded } = useAppSelector((state) => state.sharedStoreReducer);
  const { isVisible } = useWebsiteTabVisibility();

  const refreshApp = useCallback(() => {
    if (updateNeeded) {
      ApplicationInsightsApi.trackTrace('Refreshing app for version update', {
        updateNeeded,
        currentClientTime: Date.now(),
      });
      window.location.reload();
    }
  }, [updateNeeded]);

  useEffect(() => {
    if (!updateNeeded) return;

    let inactivityTimeout: ReturnType<typeof setTimeout>;

    const resetInactivityTimer = () => {
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }
      inactivityTimeout = setTimeout(refreshApp, INACTIVITY_THRESHOLD);
    };

    const handleUserActivity = () => {
      if (isVisible) {
        resetInactivityTimer();
      }
    };

    const activityEvents = ['mousedown', 'keydown', 'touchstart'];
    activityEvents.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    // Initial timer setup
    resetInactivityTimer();

    return () => {
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }
      activityEvents.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
    };
  }, [updateNeeded, isVisible, refreshApp]);

  return null;
};
