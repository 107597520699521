import { FunctionComponent, useEffect } from 'react';
import {
  EAppThemes,
  getActiveThemeClientFlag,
  initThemeObserver,
  isUserSystemOnDarkMode,
  setTheme,
  windowDarkModeQuery,
} from '../../utils/themes.utils';
import { useAppSelector } from '../../../app/store';

interface IAppThemeListenerProps {
  setCurrentTheme: React.Dispatch<React.SetStateAction<EAppThemes>>;
}

const AppThemeListener: FunctionComponent<IAppThemeListenerProps> = ({ setCurrentTheme }) => {
  const { user } = useAppSelector((store) => store.userReducer);

  useEffect(() => {
    // if user is logged in use its theme flag
    if (user) {
      const activeTheme = getActiveThemeClientFlag(user?.clientFlags);

      if (activeTheme !== 'systemSettings') {
        setTheme(activeTheme);
        return;
      } else setTheme(isUserSystemOnDarkMode() ? EAppThemes.DARK : EAppThemes.LIGHT);
    }

    // when the activeTheme is systemSettings or the user is not logged in
    // watch for dark mode changes in the system
    const darkMediaQuery = window.matchMedia(windowDarkModeQuery);

    const handleChange = (event: MediaQueryListEvent) => {
      setTheme(event.matches ? EAppThemes.DARK : EAppThemes.LIGHT);
    };

    darkMediaQuery?.addEventListener('change', handleChange);

    return () => {
      darkMediaQuery?.removeEventListener('change', handleChange);
    };
  }, [user]);

  useEffect(() => {
    const onThemeChange = () => {
      // will cause the app render with the new theme
      setCurrentTheme((prev) => {
        const updatedTheme = prev === EAppThemes.LIGHT ? EAppThemes.DARK : EAppThemes.LIGHT;
        return updatedTheme;
      });
    };

    // will observe the root data-theme attribute changes
    const themeObserver = initThemeObserver({ onThemeChange });

    return () => {
      if (themeObserver) themeObserver.disconnect();
    };
  }, []);

  return null;
};

export default AppThemeListener;
