import React, { Fragment } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import './SelectedFilters.scss';
import FilterItem from '../stage-tasks-dropdown-filters/FilterItem';
import { parseTagHash } from '../TasksList.utils';
import { setTasksFilter } from '../stageTasks.store';
import SingleTag from '../create-or-edit-task/task-tags/SingleTag';
import { ApplicationInsightsApi } from '../../../../../../application-insights';

const SelectedFilters = () => {
  const { tasksFilter } = useAppSelector((store) => store.StageTasksReducer);
  const dispatch = useAppDispatch();

  const handleRemoveItemFromFilter = (index: number) => {
    try {
      const updatedTasksFilter = [...tasksFilter];
      updatedTasksFilter.splice(index, 1);
      dispatch(setTasksFilter(updatedTasksFilter));
    } catch (e) {
      console.error('handleRemoveItemFromFilter faild with error: ', e);
      ApplicationInsightsApi.trackException(e);
    }
  };

  return (
    <div
      className={`selected-filters-container ${
        tasksFilter.length <= 0 ? 'no-filter-selected' : ''
      }`}
    >
      {tasksFilter.map((filterItem, index) => (
        <Fragment key={filterItem.tag || filterItem.type}>
          {filterItem.tag ? (
            <SingleTag
              tag={parseTagHash(filterItem.tag)}
              onDeleteTag={() => handleRemoveItemFromFilter(index)}
            />
          ) : (
            <FilterItem
              filterItem={filterItem}
              handleRemoveItemFromFilter={() => handleRemoveItemFromFilter(index)}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default SelectedFilters;
