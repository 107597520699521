import { FunctionComponent } from 'react';
import { ETasksFilterType, ISelectedTasksFilter } from '../stageTasks.interface';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import XCloseIcon from '../../../../../../shared/components/icons/XCloseIcon';
import { ReactComponent as PrioritiesIcon } from '../../../../../../assets/images/tasks-filter/priorities-icon.svg';
import { ReactComponent as LateIcon } from '../../../../../../assets/images/tasks-filter/late-icon.svg';
import { ReactComponent as QuickWinsIcon } from '../../../../../../assets/images/tasks-filter/quick-wins-icon.svg';
import { ReactComponent as UnscheduledIcon } from '../../../../../../assets/images/tasks-filter/unscheduled-icon.svg';
import { ReactComponent as DoneIcon } from '../../../../../../assets/images/tasks-filter/done-icon.svg';
import './FilterItem.scss';

interface IFilterItemProps {
  filterItem: ISelectedTasksFilter;
  className?: string;
  handleRemoveItemFromFilter?: () => void;
}

const FilterItem: FunctionComponent<IFilterItemProps> = ({
  filterItem,
  className = '',
  handleRemoveItemFromFilter,
}) => {
  const getFilterIcon = () => {
    switch (filterItem.type) {
      case ETasksFilterType.PRIORITY:
        return <PrioritiesIcon className="primary-color-stroke-icon" />;
      case ETasksFilterType.OVERDUE:
        return <LateIcon className="primary-color-stroke-icon" />;
      case ETasksFilterType.QUICK_WINS:
        return <QuickWinsIcon className="primary-color-stroke-icon" />;
      case ETasksFilterType.UNSCHEDULED:
        return <UnscheduledIcon className="primary-fabs-fill-icon" />;
      case ETasksFilterType.DONE:
        return <DoneIcon className="primary-color-stroke-icon" />;
      default:
        return null;
    }
  };

  return (
    <div className={`tasks-filter-item ${className}`}>
      {getFilterIcon()}
      {filterItem.type}
      {handleRemoveItemFromFilter && (
        <AppButton
          id="remove-filter"
          onClick={handleRemoveItemFromFilter}
          className={`remove-filter-button`}
        >
          <XCloseIcon size="small" />
        </AppButton>
      )}
    </div>
  );
};

export default FilterItem;
