import type { FocusEvent, FunctionComponent } from 'react';
import { handleInputKeyDown } from '../../../../../shared/utils/utils';
import {
  ETasksFilterType,
  ETaskStatus,
  IMessageDataTask,
  ITaskTag,
} from '../stage-tasks/stageTasks.interface';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { createTaskReqAction, getTasksListReqAction } from '../stage-tasks/stageTasks.store';
import './AddTaskField.scss';
import { setQueueMessageData } from '../../../chat.store';
import { uxEventAddTaskOrSubTask } from '../../../../../shared/utils/uxEventsUtils';
import { uuid } from '../../../../../shared/utils/uuid';
import { parseTagHash } from '../stage-tasks/TasksList.utils';
import { ApplicationInsightsApi } from '../../../../../application-insights';

interface IAddTaskFieldProps {
  taskStatus: ETaskStatus;
  placeholder: string;
  onChange?: (newTask: IMessageDataTask) => void;
  className?: string;
  shouldConsiderFilters?: boolean;
}

const AddTaskField: FunctionComponent<IAddTaskFieldProps> = ({
  taskStatus,
  placeholder,
  onChange,
  className = '',
  shouldConsiderFilters = false,
}) => {
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const { tasksFilter } = useAppSelector((store) => store.StageTasksReducer);
  const dispatch = useAppDispatch();

  const onAddTask = (e: FocusEvent<HTMLInputElement, Element>) => {
    const taskName = e.target?.value || '';
    const newTaskId = uuid();
    if (taskName?.length > 0 && sessionResponse?.data?.sessionId) {
      let reqPayload = {
        id: newTaskId,
        name: taskName,
        status: taskStatus,
        sessionId: sessionResponse?.data?.sessionId,
      };
      if (shouldConsiderFilters) reqPayload = { ...reqPayload, ...createTaskObjectByFilters() };
      if (onChange)
        onChange({
          ...reqPayload,
          creationTime: new Date().toISOString(),
        } as unknown as IMessageDataTask);
      dispatch(createTaskReqAction(reqPayload))
        .unwrap()
        .then((response) => {
          dispatch(getTasksListReqAction());
          if (response?.length > 0)
            dispatch(
              setQueueMessageData({
                type: 'automatic',
                botRequestJson: JSON.stringify(uxEventAddTaskOrSubTask(null, response[0], null)),
              }),
            );
        });

      e.target.value = '';
    }
  };

  const createTaskObjectByFilters = () => {
    try {
      if (!tasksFilter || tasksFilter.length === 0) return {};

      const { filterTypesItems, filterTagsItems } = tasksFilter.reduce(
        (accumulator, filterItem) => {
          if (filterItem.type)
            accumulator.filterTypesItems.push(filterItem.type as ETasksFilterType);
          if (filterItem.tag) accumulator.filterTagsItems.push(parseTagHash(filterItem.tag));
          return accumulator;
        },
        { filterTypesItems: [] as ETasksFilterType[], filterTagsItems: [] as ITaskTag[] },
      );

      return {
        status: filterTypesItems.includes(ETasksFilterType.DONE)
          ? ETaskStatus.DONE
          : ETaskStatus.NOT_STARTED,
        priority: filterTypesItems.includes(ETasksFilterType.PRIORITY),
        quickWin: filterTypesItems.includes(ETasksFilterType.QUICK_WINS),
        tags: [...filterTagsItems],
      } as IMessageDataTask;
    } catch (e) {
      console.error('createTaskObjectByFilters faild with error: ', e);
      ApplicationInsightsApi.trackException(e);
      return {};
    }
  };

  return (
    <input
      type="text"
      name="add-task"
      aria-label="add-task"
      className={`add-task-input ${className}`}
      placeholder={placeholder}
      autoComplete="off"
      onBlur={onAddTask}
      onKeyDown={handleInputKeyDown}
    />
  );
};

export default AddTaskField;
