import React from 'react';
import getThemeColorValue from '../../../../shared/utils/themes.utils';

interface TimerCircleProps {
  radius: number;
  arcLength: number;
  circumference: number;
  isInitialState: boolean;
  className?: string;
}

const GradientDefs = () => (
  <defs>
    <linearGradient id="progressGradient" gradientTransform="rotate(110)">
      <stop offset="0%" stopColor={getThemeColorValue('brandSecondary')} />
      <stop offset="45%" stopColor={getThemeColorValue('brandPrimary')} />
      <stop offset="55%" stopColor={getThemeColorValue('brandTertiary')} />
    </linearGradient>
  </defs>
);

const TimerCircle: React.FC<TimerCircleProps> = ({
  radius,
  arcLength,
  circumference,
  isInitialState,
  className = 'timer-circle',
}) => {
  return (
    <svg className={className} viewBox="0 0 300 300">
      <GradientDefs />
      {/* Background circle */}
      <circle cx="150" cy="150" r={radius} className="timer-circle--background" />
      {/* Progress circle */}
      <circle
        cx="150"
        cy="150"
        r={radius}
        className={`timer-circle__progress${
          isInitialState ? ' timer-circle--progress-initial' : ''
        }`}
        stroke={'url(#progressGradient)'}
        strokeWidth="12"
        fill="none"
        pathLength={circumference}
        strokeDasharray={`${arcLength} ${circumference}`}
        strokeDashoffset="0"
        strokeLinecap="round"
        transform="rotate(-90 150 150)"
        style={
          {
            transition: isInitialState ? 'none' : 'stroke-dasharray 0.5s ease-in-out',
            opacity: isInitialState ? 0.3 : 1,
          } as React.CSSProperties
        }
      />
    </svg>
  );
};

export default TimerCircle;
