import React, { CSSProperties } from 'react';
import type { ITaskTag } from '../../stageTasks.interface';
import getThemeColorValue from '../../../../../../../shared/utils/themes.utils';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';
import XCloseIcon from '../../../../../../../shared/components/icons/XCloseIcon';

interface ISingleTagProps {
  tag: ITaskTag;
  onDeleteTag?: () => void;
  onClickTag?: () => void;
}

const SingleTag = ({ tag, onDeleteTag, onClickTag }: ISingleTagProps) => {
  const isColorExist = !!tag?.color && tag.color !== getThemeColorValue('backgroundTransparent');

  const taskStyle: CSSProperties = {
    backgroundColor: isColorExist ? tag.color! : getThemeColorValue('untaggedColor'),
    color: getThemeColorValue('textInvert'),
  };

  return (
    <div
      className={`single-tag ${!tag.name ? 'without-name' : ''}`}
      style={taskStyle}
      onClick={() => !!onClickTag && onClickTag()}
    >
      {tag.name}
      {!!onDeleteTag && (
        <AppButton id="delete-tag" className="delete-tag" type="button" onClick={onDeleteTag}>
          <XCloseIcon size="small" className="invert-color-stroke-icon" />
        </AppButton>
      )}
    </div>
  );
};

export default SingleTag;
