import React, { FunctionComponent } from 'react';
import {
  getFeatureFlagByKey,
  getTaskFromAllTaskList,
  getTaskFromAllWorkBlockList,
} from '../../../../../../shared/utils/utils';
import { ICalendarEvent } from '../../CalendarDay';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { setTimerTask } from '../../../../../chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.store';
import { useSearchParams } from 'react-router-dom';
import { FEATURE_FLAGS } from '../../../../../../app/constants';
import { getEventStatus } from '../../CalendarDay.util';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import { EPlanViewSelection } from '../../../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface';
import { useTranslation } from 'react-i18next';
import './FocusModeButton.scss';

interface IFocusModeButtonProps {
  event: ICalendarEvent;
}
const FocusModeButton: FunctionComponent<IFocusModeButtonProps> = ({ event }) => {
  const dispatch = useAppDispatch();
  const [_, setSearchParams] = useSearchParams();
  const { planView } = useAppSelector((store) => store.StagePlannerReducer);
  const { t } = useTranslation();

  const handleStartFocusMode = (e: React.MouseEvent) => {
    e.stopPropagation();
    const taskId = event.parentId || event.id;
    if (!taskId) return;
    const taskForTimer = getTaskFromAllTaskList(taskId) ?? getTaskFromAllWorkBlockList(event.id);
    if (!taskForTimer) return;
    dispatch(setTimerTask(taskForTimer));
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams),
      stageContent: 'timer',
      from: 'planner',
    }));
  };

  return (
    <>
      {getFeatureFlagByKey(FEATURE_FLAGS.FOCUS_TIMER) &&
        getEventStatus(event.start, event.end) === 'focused' && (
          <AppButton className="focus-mode-button" onClick={handleStartFocusMode} id="focus-mode">
            {planView === EPlanViewSelection.MY_WEEK
              ? t('planTimerShortTitle')
              : t('stageTimerTitle')}
          </AppButton>
        )}
    </>
  );
};

export default FocusModeButton;
