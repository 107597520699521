import { useTranslation } from 'react-i18next';
import ThemeButtonsContainer from './theme-buttons-container/ThemeButtonsContainer';
import { useFormContext } from 'react-hook-form';
import { IProfileForm } from '../SettingsMenu';
import { ErrorMessage } from '../error-message/ErrorMessage';

const ThemeControl = () => {
  const { t } = useTranslation();
  const form = useFormContext<IProfileForm>();
  const watchShouldDisplayThemeError = form.watch('shouldDisplayThemeError');

  return (
    <section className="theme-control-container data">
      <div>
        <h3 className="title">{t('settingsThemeControlTitle')}</h3>
        <p>{t('settingsThemeControlSubTitle')}</p>
      </div>
      <ThemeButtonsContainer />
      {watchShouldDisplayThemeError && <ErrorMessage />}
    </section>
  );
};

export default ThemeControl;
