import { useTranslation } from 'react-i18next';
import { handleLogOut } from '../../../../utils/logOut';
import { Avatar } from '../../../avatar/Avatar';
import { getInitials } from '../../../../utils/getInitials';
import './TopSectionTitle.scss';
import { useAppSelector } from '../../../../../app/store';
import StyledAppButton from '../../../app-button/styled-app-button/StyledAppButton';

export const TopSectionTitle = () => {
  const { t } = useTranslation();
  const { user: userInfo } = useAppSelector((store) => store.userReducer);
  const handleAsyncLogout = async () => {
    await handleLogOut();
  };

  return (
    <section className="settings-menu-top-section">
      <Avatar
        className="settings-avatar"
        defaultAvatarClassName="default-settings-avatar"
        size={103}
        avatarImg={userInfo?.profileImage || ''}
        defaultContent={getInitials(userInfo?.name || userInfo?.email || null)}
      />
      <div className="title">
        <div className="name-email">
          <h1 className="user-detail" data-testid="settings-menu-title">
            {userInfo?.name ?? ''}
          </h1>
          <div className="email">{userInfo?.email ?? ''}</div>
        </div>
        <StyledAppButton
          className="sign-out"
          data-testid="sign-out-btn"
          id="settings-log-out"
          onClick={() => handleAsyncLogout()}
        >
          {t('signOut')}
        </StyledAppButton>
      </div>
    </section>
  );
};
