import dayjs from 'dayjs';
import {
  getDateFormatted,
  getTimeRange,
} from '../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/TasksList.utils';
import { ApplicationInsightsApi } from '../../application-insights';
import { firstDayOfWeek } from '../../app/constants';

export enum EDayjsFormatter {
  FullYear = 'YYYY',
  FullMonthWord = 'MMMM',
  PartOfMonthWord = 'MMM',
  MonthNumberWithZero = 'MM',
  MonthNumberWithoutZero = 'M',
  DayOfMonthNumberWithoutZero = 'D',
  DayOfMonthNumberWithOrdinalIndicator = 'Do',
  DayOfMonthNumberWithZero = 'DD',
  DayOfWeekNumber = 'd',
  DayOfWeekWord = 'dddd',
  PartOfDayOfWeekWord = 'ddd',
  TimeInAmPm = 'h:mmA',
  HourInAmPm = 'hA',
}

export const getTimeByDate = (
  date: string | number | Date,
  shouldIncludeMinutes = true,
): string => {
  try {
    return dayjs(new Date(date)).format(
      `${shouldIncludeMinutes ? EDayjsFormatter.TimeInAmPm : EDayjsFormatter.HourInAmPm}`,
    );
  } catch (error) {
    return '';
  }
};

export const getDateWithYear = (
  date: string | number,
  showOrdinal = false,
  showDayOfTheWeek = true,
): string => {
  try {
    return dayjs(new Date(date)).format(
      `${showDayOfTheWeek ? EDayjsFormatter.PartOfDayOfWeekWord + ', ' : ''}${
        EDayjsFormatter.PartOfMonthWord
      } ${
        showOrdinal
          ? EDayjsFormatter.DayOfMonthNumberWithOrdinalIndicator
          : EDayjsFormatter.DayOfMonthNumberWithoutZero
      }, ${EDayjsFormatter.FullYear}`,
    );
  } catch (error) {
    return '';
  }
};

export const getDateWithoutYear = (
  date: string | number | Date,
  showOrdinal = false,
  showShortWords = true,
): string => {
  try {
    return dayjs(new Date(date)).format(
      `${
        showShortWords ? EDayjsFormatter.PartOfDayOfWeekWord : EDayjsFormatter.DayOfWeekWord + ','
      } ${showShortWords ? EDayjsFormatter.PartOfMonthWord : EDayjsFormatter.FullMonthWord} ${
        showOrdinal
          ? EDayjsFormatter.DayOfMonthNumberWithOrdinalIndicator
          : EDayjsFormatter.DayOfMonthNumberWithoutZero
      }`,
    );
  } catch (error) {
    return '';
  }
};

export const getDateAndTimeWithoutYear = (
  date: string | number | Date,
  showOrdinal = false,
  showShortWords = true,
): string => {
  try {
    return `${getDateWithoutYear(date, showOrdinal, showShortWords)}, ${getTimeByDate(date)}`;
  } catch (error) {
    return '';
  }
};

export const getDateAndTimeWithoutYearAndWithoutDay = (date: string | number | Date): string => {
  try {
    return (
      dayjs(new Date(date)).format(
        `${EDayjsFormatter.PartOfMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`,
      ) +
      ' ' +
      getTimeByDate(date)
    );
  } catch (error) {
    return '';
  }
};

export const addDaysToDate = (date: Date | string, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getDateAndTimeWithoutYearAndWithoutDayWithoutTime = (
  date: string | number | Date,
  showShortWords = true,
): string => {
  try {
    return dayjs(new Date(date)).format(
      `${showShortWords ? EDayjsFormatter.PartOfMonthWord : EDayjsFormatter.FullMonthWord} ${
        EDayjsFormatter.DayOfMonthNumberWithoutZero
      }`,
    );
  } catch (error) {
    return '';
  }
};

export const getDateAndTimeWithoutYearWithoutTime = (date: string | number | Date): string => {
  try {
    return dayjs(new Date(date)).format(
      `${EDayjsFormatter.PartOfMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`,
    );
  } catch (error) {
    return '';
  }
};

// get the name of the day
export const getDayName = (dayIndex: number, fullWord = false) => {
  try {
    return dayjs()
      .weekday(dayIndex)
      .format(fullWord ? EDayjsFormatter.DayOfWeekWord : EDayjsFormatter.PartOfDayOfWeekWord);
  } catch (error) {
    return '';
  }
};

// get the number of the day in the month
export const getDayOfTheMonth = (date: string | number | Date) => {
  try {
    return dayjs(date).format(EDayjsFormatter.DayOfMonthNumberWithoutZero);
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return '';
  }
};

// get the day index(0-6) of a specific date
export const getDayIndex = (date?: Date): number => {
  try {
    if (date) return dayjs(new Date(date)).day();
    return 0;
  } catch (error) {
    return 0;
  }
};

export const getDateBasedOnDayIndexAndDayOffset = (
  dayIndex: number,
  dayOffset: number,
  weekOffset = 0,
): Date => {
  try {
    return dayjs().weekday(dayIndex).add(dayOffset, 'day').add(weekOffset, 'week').toDate();
  } catch (error) {
    return new Date();
  }
};

// day index 0-6, week offset 0 = current week etc...
export const getDateBasedOnDayIndexAndWeekOffset = (dayIndex: number, weekOffset: number): Date => {
  try {
    return dayjs()
      .weekday(dayIndex + firstDayOfWeek)
      .add(new Date().getDay() === 0 ? weekOffset - 1 : weekOffset, 'week')
      .toDate();
  } catch (error) {
    return new Date();
  }
};

export const getDateBasedOnDayOffset = (dayIndex: number): Date => {
  try {
    return dayjs().add(dayIndex, 'day').toDate();
  } catch (error) {
    return new Date();
  }
};

// short date with duration format
// returns Tue Sep 12 10:00AM or Tue Sep 12 10:00AM - 11:00AM
export const getShortDateWithDuration = (date: string | Date, duration?: number | null): string => {
  const start = new Date(date);
  return `${getDateFormatted(start)} ${getTimeRange(start, duration)}`.replaceAll(': ', ' ');
};

// for example: "January 8"
export const getMonthNameAndDay = (date: string | Date): string => {
  try {
    return dayjs(new Date(date)).format(
      `${EDayjsFormatter.FullMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`,
    );
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return '';
  }
};

export const getDayOfWeekNameAndDayOfMonthByDate = (
  date: string | Date,
  fullWeekDayWord = false,
): string => {
  try {
    return dayjs(new Date(date)).format(
      `${fullWeekDayWord ? EDayjsFormatter.DayOfWeekWord : EDayjsFormatter.PartOfDayOfWeekWord} ${
        EDayjsFormatter.DayOfMonthNumberWithoutZero
      }`,
    );
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return '';
  }
};
