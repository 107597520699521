import type { CSSProperties, Dispatch, RefObject, SetStateAction } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import './CalendarDay.scss';
import { TimeIndicator } from '../time-indicator/TimeIndicator';
import { store, useAppDispatch, useAppSelector } from '../../../../app/store';
import {
  getTasksListReqAction,
  setCalendarSelectedWorkTime,
  updateTaskReqAction,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import type {
  ETaskSource,
  ETaskStatus,
  IMessageDataTask,
  ITaskUpdateReqPayload,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { addMinutes } from 'date-fns';
import {
  EDragAndDropType,
  getCenterCoordinates,
  getDomElementByQuerySelector,
  isDesktopView,
} from '../../../../shared/utils/utils';
import { EPlanDayCardDisplayType } from '../../plan-day-card/PlanDayCard';
import {
  addDaysToDate,
  getDateBasedOnDayIndexAndWeekOffset,
} from '../../../../shared/utils/dateFormat';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import PlusButtonOverlay from '../../create-plus-button/plus-button-overlay/PlusButtonOverlay';
import {
  calendarHourLineElementClassName,
  calendarHourLineTransparentElClassName,
} from '../../../../app/constants';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { EAPIStatus } from '../../../../shared/api/models';
import { uuid } from '../../../../shared/utils/uuid';
import { onPlaceUnscheduledTask } from '../../plan.utils';
import CalendarEvent from './CalendarEvent';
import CalendarHourLineElement from './CalendarHourLineElement';
import { useDrop } from 'react-dnd';
import { convertSingleItemToCalendarEvent } from './CalendarDay.util';
import type { IDragItem } from './CalendarDragAndDrop.util';
import {
  autoScrollWhenDraggingNearTheTopOrBottom,
  getDropTimeOnCalendar,
} from './CalendarDragAndDrop.util';
import type {
  ECalendarEventDurationTypeClassName,
  IUpdateDragEventApiRequestFuncArgs,
} from './CalendarDay.interfaces';
import { ECloseSwipeDirection } from '../../../../shared/hooks/swipe-hooks/swipe.utils';
import plusButtonSassVariables from '../../create-plus-button/CreatePlusButton.module.scss';
import getThemeColorValue from '../../../../shared/utils/themes.utils';
export interface ICalendarEvent {
  id: string;
  parentId?: string | null;
  start: Date;
  end: Date;
  title: string;
  backgroundColor?: string;
  titleColor: string;
  source?: ETaskSource;
  durationType: ECalendarEventDurationTypeClassName;
  top: number;
  height: number;
  isRecurring: boolean;
  groupId?: number;
  columnOffset?: number;
  columnsToTake?: number;
  eventsEndAfterCurrentEventStarts?: number;
  totalColumns?: number;
  isEvent?: boolean;
  isWorkBlock?: boolean;
  status: ETaskStatus;
  relatedTasks: IMessageDataTask[];
  workBlockId?: string | null;
  duration?: number | null;
  titleFontSize?: string;
}

interface IProps {
  tasksAndWorkBlocks: IMessageDataTask[];
  showCurrentTimeIndicator: boolean;
  dayIndex: number;
  cardIndex: number;
  shouldShowHourText: boolean;
  playViewType: EPlanDayCardDisplayType;
  daysToDisplay: number;
  hourHeight?: number;
  nonRelativeDayIndex: number;
  setDroppingProcessCounter: Dispatch<SetStateAction<number>>;
  droppingProcessCounter: number;
  scrollableContainerRef: RefObject<HTMLDivElement>;
}

export const defaultHourHeightDesktop = 60;
export const defaultHourHeightMobile = 60;

export interface IDropResult {
  dayIndex: number;
  updatedDroppedEvent?: ICalendarEvent;
}

const CalendarDay = ({
  tasksAndWorkBlocks,
  showCurrentTimeIndicator,
  dayIndex,
  cardIndex,
  shouldShowHourText,
  playViewType,
  daysToDisplay,
  nonRelativeDayIndex,
  droppingProcessCounter,
  setDroppingProcessCounter,
  scrollableContainerRef,
  hourHeight = isDesktopView() ? defaultHourHeightDesktop : defaultHourHeightMobile,
}: IProps) => {
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const { tasksListResponse, updateTaskRes } = useAppSelector((store) => store.StageTasksReducer);
  const { plannerMode, currentTaskPlacement } = useAppSelector(
    (store) => store.StagePlannerReducer,
  );
  const [startingHour] = useState(0);
  const [endingHour] = useState(24);
  const dispatch = useAppDispatch();
  const [eventsGroups, setEventsGroups] = useState<ICalendarEvent[][]>([]);
  const [calendarEventsArray, setCalendarEventsArray] = useState<ICalendarEvent[]>([]);
  const calendarDayContainerRef = useRef<HTMLDivElement | null>();
  const [localRenderTrigger, setLocalRenderTrigger] = useState<string>(uuid());
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const cellHeight = hourHeight / 2; // (hourHeight / 2) -> each cell is half hour
  const calendarDayContainerClassName = `calendar-day-container${
    isCurrentDay(dayIndex) ? ' calendar-day-container--today' : ''
  }`;

  useEffect(() => {
    // TODO: Investigate why the update task response causes the component to re-render with the task data from before the drop.
    // run convertTasksToEvents function only if there are no dropping processes in progress
    if (droppingProcessCounter === 0) convertTasksToEvents(tasksAndWorkBlocks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksAndWorkBlocks, currentTaskPlacement]);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setLocalRenderTrigger(uuid());
    }, 300);
  }, [plannerMode]);

  const updateDragEventInLocalState = (dargItem: IDragItem, start: Date) => {
    const end =
      dargItem.event.duration && dargItem.event.duration / 60 > 15
        ? addMinutes(start, dargItem.event.duration / 60)
        : addMinutes(start, 30);
    const top =
      start.getHours() * hourHeight +
      (start.getMinutes() * hourHeight) / 60 -
      startingHour * hourHeight;
    const updatedEvent: ICalendarEvent = { ...dargItem.event, start, end, top };
    divideEventByGroups([
      ...calendarEventsArray.filter((e) => e.id !== dargItem.event.id),
      updatedEvent,
    ]);
    return updatedEvent;
  };

  const updateDragEventApiRequest = (
    updateDraggedEventArgs: IUpdateDragEventApiRequestFuncArgs,
  ) => {
    if (!sessionResponse?.data?.sessionId) return;
    const {
      dragItem,
      dropType,
      updatedWorkTime,
      relatedWorkBlockId,
      relatedWorkBlockOrder,
      relatedWorkBlockInstance,
    } = updateDraggedEventArgs;

    const request: ITaskUpdateReqPayload = {
      sessionId: sessionResponse?.data?.sessionId,
      id: dragItem.event.id,
    };
    if (updatedWorkTime) {
      request.workTime = updatedWorkTime.toISOString();
      if (dragItem.isComeFromWorkBlock) {
        request.workBlockId = null;
        request.workBlockInstance = null;
        request.workBlockOrder = null;
      }
    } else if (relatedWorkBlockId) {
      request.workBlockId = relatedWorkBlockId;
      request.workBlockOrder = relatedWorkBlockOrder;
      request.workBlockInstance = relatedWorkBlockInstance
        ? relatedWorkBlockInstance?.toISOString()
        : null;
      request.workTimeReminder = null;
      request.workTimeRecurrenceType = null;
    }

    if (dropType === EDragAndDropType.PLANNER_TO_CALENDAR_EVENT) {
      request.duration = dragItem.event?.duration;
    }

    dispatch(updateTaskReqAction(request))
      .unwrap()
      .finally(() => {
        // in case of error will trigger rerender the component to revert the local changes
        dispatch(getTasksListReqAction())
          .unwrap()
          .finally(() => setDroppingProcessCounter((prev) => (prev > 0 ? prev - 1 : 0)));
      });
  };

  const calendarEventDrop = (
    dragItem: IDragItem,
    hours: number,
    minutes: number,
    dropType: EDragAndDropType,
  ): IDropResult => {
    setDroppingProcessCounter((prev) => prev + 1);
    // Create a new date with the updated hour and minutes
    const updatedStartDate = getUpdatedDate(hours, minutes);
    const updatedDroppedEvent = updateDragEventInLocalState(dragItem, updatedStartDate);
    updateDragEventApiRequest({ dragItem, dropType, updatedWorkTime: updatedStartDate });
    return { dayIndex, updatedDroppedEvent };
  };

  function convertTasksAndWorkBlocksToEventsList(list: IMessageDataTask[]): ICalendarEvent[] {
    return list.map((el: IMessageDataTask) => {
      return convertSingleItemToCalendarEvent(el, hourHeight, startingHour);
    });
  }

  function divideEventByGroups(events: ICalendarEvent[]): void {
    events.sort((a, b) => {
      const result = a.start.getTime() - b.start.getTime();
      if (!result) {
        return b.end.getTime() - a.end.getTime();
      }
      return result;
    });

    setCalendarEventsArray(events);
    const overlappingGroups: ICalendarEvent[][] = [];
    let currentGroup: ICalendarEvent[] = [];
    let currentGroupId = 1;

    for (const event of events) {
      if (currentGroup.length === 0) {
        event.groupId = currentGroupId;
        currentGroup.push(event);
      } else {
        if (currentGroup.find((e) => event.start < e.end)) {
          event.groupId = currentGroupId;
          currentGroup.push(event);
        } else {
          overlappingGroups.push(currentGroup);
          currentGroupId++;
          event.groupId = currentGroupId;
          currentGroup = [event];
        }
      }
    }

    if (currentGroup.length > 0) {
      overlappingGroups.push(currentGroup);
    }

    for (const group of overlappingGroups) {
      let maxOffset = 0;
      let currentColumn = 0;

      // create the columns and slot each event in the right column if there is room
      for (let i = 0; i < group.length; i++) {
        if (i === 0) {
          group[i].columnOffset = 0;
        } else {
          if (group[i].start >= group[i - 1].end) {
            group[i].columnOffset = group[i - 1].columnOffset;
          } else {
            group[i].columnOffset = currentColumn + 1;
            currentColumn++;
          }
        }
      }
      // find the total number of columns for the group
      for (const event of group) {
        if (event.columnOffset! > maxOffset) {
          maxOffset = event.columnOffset!;
        }
      }

      // set the total number of columns for each group on each event
      for (const event of group) {
        event.totalColumns = maxOffset + 1;
      }
    }

    setEventsGroups(overlappingGroups);
  }

  function convertTasksToEvents(tasksAndWorkBlocks: IMessageDataTask[]): void {
    const tempPlacement = getUnscheduledTaskPlacement();
    const events = [
      ...convertTasksAndWorkBlocksToEventsList(
        tempPlacement ? [...tasksAndWorkBlocks, tempPlacement] : tasksAndWorkBlocks,
      ),
    ];
    divideEventByGroups(events);
  }

  const renderHourLines = () => {
    const lines = [];
    let calendarHourElTopPosition = 0;
    let calendarHalfHourElTopPosition = 0;
    for (let i = startingHour; i <= endingHour; i++) {
      calendarHourElTopPosition = i * hourHeight - startingHour * hourHeight;
      calendarHalfHourElTopPosition = i * hourHeight + cellHeight - startingHour * hourHeight;
      lines.push(
        <CalendarHourLineElement
          key={i}
          keyComponent={i}
          children={
            shouldShowHourText &&
            i < endingHour && (
              <span id={`hour-${i}-${cardIndex}`} className="calendar-hour-text">
                {i % 12 === 0 ? 12 : i % 12}
                {i < 12 ? 'A' : 'P'}
              </span>
            )
          }
          style={{ top: `${calendarHourElTopPosition}px` }}
          lineIndex={i}
          handleClickOnHourLine={handleClickOnHourLine}
          onOpenCreateOverlay={onOpenCreateOverlay}
          dayIndx={dayIndex}
        />,
        <CalendarHourLineElement
          key={i + 0.1}
          keyComponent={i + 0.1}
          className={`${calendarHourLineTransparentElClassName}`}
          style={{
            top: `${calendarHourElTopPosition}px`,
            height: cellHeight,
            background: getThemeColorValue('backgroundTransparent'),
          }}
          lineIndex={i}
          handleClickOnHourLine={handleClickOnHourLine}
          onOpenCreateOverlay={onOpenCreateOverlay}
          dayIndx={dayIndex}
        />,
        <CalendarHourLineElement
          key={i + 0.2}
          keyComponent={i + 0.2}
          className={`${calendarHourLineTransparentElClassName} ${calendarHourLineTransparentElClassName}--half`}
          style={{
            top: `${calendarHalfHourElTopPosition}px`,
            height: cellHeight,
            background: getThemeColorValue('backgroundTransparent'),
          }}
          lineIndex={i}
          handleClickOnHourLine={handleClickOnHourLine}
          isHalfHour={true}
          onOpenCreateOverlay={onOpenCreateOverlay}
          dayIndx={dayIndex}
        />,
        <CalendarHourLineElement
          key={i + 0.5}
          keyComponent={i + 0.5}
          className={`${calendarHourLineElementClassName}--half`}
          style={{ top: `${calendarHalfHourElTopPosition}px` }}
          lineIndex={i}
          handleClickOnHourLine={handleClickOnHourLine}
          isHalfHour={true}
          onOpenCreateOverlay={onOpenCreateOverlay}
          dayIndx={dayIndex}
        />,
      );
    }
    return lines;
  };

  const getUpdatedDate = (hours: number, minutes: number) => {
    const currentDate = getDate();
    currentDate.setHours(hours, minutes, 0, 0);
    return currentDate;
  };

  const handleClickOnHourLine = (hours: number, minutes: number) => {
    if (!isCalenderDayClickable()) return;
    const currentDate = getUpdatedDate(hours, minutes);
    switch (plannerMode) {
      case EPlannerMode.TIMEPICKER:
      case EPlannerMode.UNSCHEDULEDTASKSPLACER:
        onPlaceUnscheduledTask(currentDate);
        break;
      default:
        break;
    }
  };

  const onOpenCreateOverlay = (hours: number, minutes: number) => {
    if (!isCalenderDayClickable() || !!currentTaskPlacement) return;
    const currentDate = getDate();
    currentDate.setHours(hours, minutes, 0, 0);
    dispatch(setCalendarSelectedWorkTime(currentDate.toISOString()));
    createAppOverlayPopover(
      <PlusButtonOverlay />,
      'plan-plus-overlay-menu-container',
      null,
      {
        ...getCenterCoordinates(
          getDomElementByQuerySelector('#stage-container-main'),
          parseInt(plusButtonSassVariables.planPlusMenuWidth),
          parseInt(plusButtonSassVariables.planPlusMenuHeight),
        ),
      },
      {
        // overlay config
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.HALF_SCREEN,
        shouldCloseBySwipeOnMobile: true,
        closeSwipeDirection: ECloseSwipeDirection.DOWN,
        isCustomStyle: true,
      },
    );
  };

  function getDate() {
    switch (playViewType) {
      case EPlanDayCardDisplayType.MY_PLAN:
      case EPlanDayCardDisplayType.MY_DAY:
        return addDaysToDate(new Date(), cardIndex * daysToDisplay + nonRelativeDayIndex);
      case EPlanDayCardDisplayType.MY_WEEK:
        if (daysToDisplay === 7)
          return getDateBasedOnDayIndexAndWeekOffset(nonRelativeDayIndex, cardIndex);
        return addDaysToDate(new Date(), cardIndex * daysToDisplay + nonRelativeDayIndex);
    }
  }

  function isCurrentDay(dayIndex: number) {
    const currentDate = new Date();
    const cardDate =
      playViewType === EPlanDayCardDisplayType.MY_WEEK && daysToDisplay === 7
        ? getDateBasedOnDayIndexAndWeekOffset(dayIndex, cardIndex)
        : addDaysToDate(currentDate, cardIndex * daysToDisplay + dayIndex);
    return cardDate.toDateString() === currentDate.toDateString();
  }

  const renderEvents = () => {
    return eventsGroups.map((group) => {
      return group.map((event) => (
        <CalendarEvent
          key={event.id}
          event={event}
          isCalenderDayClickable={isCalenderDayClickable()}
          shouldShowHourText={shouldShowHourText}
          calendarDayContainerWidth={calendarDayContainerRef?.current?.clientWidth!}
          playViewType={playViewType}
          eventsFlatArray={calendarEventsArray}
          divideEventByGroups={divideEventByGroups}
          dayIndex={dayIndex}
          updateDragEventApiRequest={updateDragEventApiRequest}
          setDroppingProcessCounter={setDroppingProcessCounter}
          cellHeight={cellHeight}
        />
      ));
    });
  };

  function getUnscheduledTaskPlacement() {
    const currentTaskPlacement = store.getState().StagePlannerReducer.currentTaskPlacement;
    if (!currentTaskPlacement || !currentTaskPlacement.workTime) return null;
    const currentDate = getDate();
    const taskDate = new Date(currentTaskPlacement.workTime);
    if (currentDate.toDateString() === taskDate.toDateString()) return currentTaskPlacement;
    return null;
  }

  const isCalenderDayClickable = () => {
    if (
      tasksListResponse.status === EAPIStatus.PENDING ||
      updateTaskRes.status === EAPIStatus.PENDING
    )
      return false;
    return true;
  };

  const [, dropRef] = useDrop({
    accept: [EDragAndDropType.CALENDAR_EVENT, EDragAndDropType.PLANNER_TO_CALENDAR_EVENT],
    drop: (item: IDragItem, monitor) => {
      if (monitor.didDrop() || item?.snapYDroppedPosition === undefined) return;
      const { hour, minutes } = getDropTimeOnCalendar(item, dayIndex, cellHeight);
      return calendarEventDrop(item, hour, minutes, monitor.getItemType() as EDragAndDropType);
    },
    hover: (_, monitor) => {
      // Auto-scroll the scrollable container when dragging near the top/bottom
      autoScrollWhenDraggingNearTheTopOrBottom(monitor, scrollableContainerRef);
    },
  });

  const getCalendarContainerStyle = (): CSSProperties => {
    if (playViewType === EPlanDayCardDisplayType.MY_WEEK) return { paddingTop: cellHeight };
    return {};
  };

  return (
    <div className={calendarDayContainerClassName} style={getCalendarContainerStyle()}>
      <div
        className="calendar-day-all-day-events-container"
        style={{ minHeight: cellHeight }}
      ></div>
      <div
        ref={(node) => {
          dropRef(node);
          calendarDayContainerRef.current = node;
        }}
        key={localRenderTrigger}
        className="calendar-events-container"
        style={{ height: (endingHour - startingHour) * hourHeight }}
      >
        {renderHourLines()}
        {calendarDayContainerRef.current && renderEvents()}
        {showCurrentTimeIndicator && (
          <TimeIndicator hourHeight={hourHeight} startingHour={startingHour} />
        )}
      </div>
    </div>
  );
};

export default memo(CalendarDay);
