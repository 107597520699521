import { useTranslation } from 'react-i18next';
import './ErrorMessage.scss';

interface IProps {
  messageTranslationKey?: string;
  className?: string;
  isAbsolute?: boolean;
}

export const ErrorMessage = ({
  messageTranslationKey,
  className = '',
  isAbsolute = true,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <small
      className={`update-settings-error ${
        !isAbsolute ? 'update-settings-error--static-position' : ''
      } 
      ${className} app-error`}
      data-testid="settings-error"
    >
      {messageTranslationKey ? t(messageTranslationKey) : t('pleaseTryAgainError')}
    </small>
  );
};
