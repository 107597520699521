import React, { useState } from 'react';
import './InternalUserSettings.scss';
import DownloadSessionButton from '../download-session-button/DownloadSessionButton';
import { useAppDispatch } from '../../../../../app/store';
import { updateUserProfileReq } from '../../../../../app/useAppData/user.store';
import { useFormContext } from 'react-hook-form';
import type { IProfileForm } from '../SettingsMenu';
import { ApplicationInsightsApi } from '../../../../../application-insights';
import DownloadCheckpointButton from '../download-checkpoint-button/DownloadCheckpointButton';

interface InternalUserSettingsProps {}

export const InternalUserSettings: React.FC<InternalUserSettingsProps> = () => {
  const form = useFormContext<IProfileForm>();
  const [error, setError] = useState<string | null>(null);
  const featureFlagsWatcher = form.watch('featureFlags');
  const [flagsRollBackValue, setFlagsRollBackValue] = useState<string>(featureFlagsWatcher);
  const dispatch = useAppDispatch();

  const handleSave = () => {
    setError(null);
    try {
      const updatedFlags = JSON.parse(featureFlagsWatcher);
      if (typeof updatedFlags !== 'object' || Array.isArray(updatedFlags)) return;
    } catch (err) {
      ApplicationInsightsApi.trackException(err);
      form.setValue('featureFlags', flagsRollBackValue); // Rollback
      setError('Invalid Format.');
      return;
    }

    dispatch(updateUserProfileReq({ featureFlags: JSON.parse(featureFlagsWatcher) }))
      .unwrap()
      .then((updatedProfile) => {
        setFlagsRollBackValue(JSON.stringify(updatedProfile?.featureFlags || '{}', null, 2));
      });
  };

  return (
    <div className="internal-user-settings">
      <h1>Internal User Settings</h1>
      <br />
      <DownloadSessionButton />
      <DownloadCheckpointButton />
      <h3>Feature Flags</h3>
      <textarea {...form.register('featureFlags')} rows={10} className="feature-flags-textarea" />
      {error && <p className="error-message">{error}</p>}
      <button onClick={handleSave} className="save-feature-flags-button">
        Update Feature Flags
      </button>
    </div>
  );
};
