// Import theme-generator allows TypeScript to dynamically read the same theme values (as in scss files) at runtime.
import { ApplicationInsightsApi } from '../../application-insights';
import { sendThemeChangeRequestToMobileApp } from '../../mobile-application-utils';
import themeColors from '../../styles/themes/theme-generator.module.scss';
import { EClientFlags } from '../components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { FlagUtils } from './flagUtils';

export enum EAppThemes {
  LIGHT = 'light',
  DARK = 'dark',
}

export const windowDarkModeQuery = '(prefers-color-scheme: dark)';

export const isUserSystemOnDarkMode = () => {
  return window.matchMedia && window.matchMedia(windowDarkModeQuery).matches;
};

export const defaultTheme = isUserSystemOnDarkMode() ? EAppThemes.DARK : EAppThemes.LIGHT;

export const rootThemeDataAttribute = 'data-theme';

const getThemeList = () => {
  try {
    return themeColors.themes?.split(',').map((val) => val.trim());
  } catch (e) {
    ApplicationInsightsApi.trackException(
      `Creating a theme list inside themes.utils failed with error: ${e}`,
    );
    console.error('Creating a theme list inside themes.utils failed with error: ', e);
    return [];
  }
};

const themeList = getThemeList();
/*
  For each theme variable, we pick the ones that start with "theme_".
  These will be our theme variables as theme_themeName_variableName.
  This will output a flat object of variable names with their values.
*/
const getThemeVars = (): Record<string, string> => {
  try {
    return Object.keys(themeColors).reduce((acc, key) => {
      if (key !== 'themes' && key.startsWith('theme_')) {
        acc[key] = themeColors[key];
      }
      return acc;
    }, {} as Record<string, string>);
  } catch (e) {
    ApplicationInsightsApi.trackException(
      `Creating the theme variables object inside themes.utils failed with error: ${e}`,
    );
    console.error('Creating the theme variables object inside themes.utils failed with error: ', e);
    return {};
  }
};

const themeVars = getThemeVars();
/*
  This transforms the flat object from above into a nested object like so:
  {
    themeName: {
      variableName: value,
      ...
    },
    ...
  }
*/
const splitVariablesByTheme = (): Record<string, Record<string, string>> => {
  try {
    return Object.keys(themeVars).reduce((result, key) => {
      const parts = key.split('_');
      const theme = parts[1];
      const variable = parts[2];
      if (!result[theme]) {
        result[theme] = {};
      }
      result[theme][variable] = themeVars?.[key];
      return result;
    }, {} as Record<string, Record<string, string>>);
  } catch (e) {
    ApplicationInsightsApi.trackException(
      `split variables by theme inside themes.utils failed with error: ${e}`,
    );
    console.error('split variables by theme inside themes.utils failed with error: ', e);
    return {};
  }
};

const varsByTheme = splitVariablesByTheme();

/*
  gets the current theme the user has applied, from the html el classname
*/
export function getCurrentTheme(): string {
  const themeAttribute = document.querySelector('html')?.getAttribute(rootThemeDataAttribute);
  return themeAttribute && themeList.includes(themeAttribute) ? themeAttribute : defaultTheme;
}

/*
  similar to the scss themed mixin,
  returns the color for the current theme
  inline style usage on jsx elements:       
  style={{
    color: getThemeColorValue('colorKey'),
    border: `${getThemeColorValue('borderColorPrimaryKeyColor')} 1px solid`,
  }}
*/
export default function getThemeColorValue(variableName: string): string | undefined {
  try {
    const currentTheme = getCurrentTheme();
    const variables = varsByTheme?.[currentTheme];
    if (variables && !variables[variableName]) {
      console.error(`Theme sass variable: ${variableName} not found`);
      ApplicationInsightsApi.trackException(`Theme sass variable: ${variableName} not found`);

      return undefined;
    }
    return variables ? variables[variableName] : undefined;
  } catch (e) {
    console.error(`Theme sass variable: ${variableName} not found`);
    ApplicationInsightsApi.trackException(`Theme sass variable: ${variableName} not found`);
    return undefined;
  }
}

/* *************** functions below here are not generally needed except at the root app level ***************** */

let currentTheme = getCurrentTheme();

const onMutationChange = (
  onThemeChange: (oldTheme: string, newTheme: string) => void,
  mutationsList: MutationRecord[],
): void => {
  let wasUpdated = false;
  const newTheme = getCurrentTheme();

  mutationsList.forEach((mutation) => {
    if (mutation.attributeName === rootThemeDataAttribute) {
      if (newTheme !== currentTheme) {
        wasUpdated = true;
      }
    }
  });

  if (wasUpdated) {
    onThemeChange(currentTheme, newTheme);
    currentTheme = newTheme;
  }
};

/*
  inits a mutation observer to watch the html element for data-theme attribute changes.
  onThemeChange should be a method with optional usage of params oldTheme, newTheme.

  This is needed for react, since react won't rerender with the new theme colors
  if the theme changes. We can watch the change manually, then have a callback that
  allows us to manually rerender as a result.
*/
export const initThemeObserver = ({
  onThemeChange,
}: {
  onThemeChange: (oldTheme: string, newTheme: string) => void;
}): MutationObserver => {
  document.documentElement.setAttribute(rootThemeDataAttribute, defaultTheme);

  const themeObserver = new MutationObserver((mutationsList) =>
    onMutationChange(onThemeChange, mutationsList),
  );
  themeObserver.observe(document.documentElement, {
    attributes: true,
    attributeFilter: [rootThemeDataAttribute],
  });

  return themeObserver;
};

/* 
  will set the root data-theme attribute to the updated theme
*/
export const setTheme = (theme: EAppThemes) => {
  try {
    sendThemeChangeRequestToMobileApp(theme);
    document.documentElement.setAttribute(rootThemeDataAttribute, theme);
  } catch (e) {
    ApplicationInsightsApi.trackException(
      `set theme type inside themes.utils failed with error: ${e}`,
    );
    console.error('set theme type inside themes.utils failed with error: ', e);
  }
};

export type TSystemSettingsTheme = 'systemSettings';
export type themeButtonType = EAppThemes | TSystemSettingsTheme;

export const getActiveThemeClientFlag = (userClientFlags?: number): themeButtonType => {
  if (userClientFlags || userClientFlags === 0) {
    const isLightON = FlagUtils.getFlag(userClientFlags, EClientFlags.LIGHT_MODE_ON);
    const isDarkON = FlagUtils.getFlag(userClientFlags, EClientFlags.DARK_MODE_ON);
    if (isLightON) return EAppThemes.LIGHT;
    else if (isDarkON) return EAppThemes.DARK;
  }
  return 'systemSettings';
};

export const getThemeByDocumentDataThemeAttr = (): EAppThemes | null => {
  try {
    const themeAttribute = document.documentElement?.getAttribute(
      rootThemeDataAttribute,
    ) as EAppThemes | null;
    return themeAttribute;
  } catch (e) {
    ApplicationInsightsApi.trackException(
      `getThemeByDocumentDataThemeAttr - get theme type from the document data attribute failed with error: ${e}`,
    );
    return null;
  }
};
