import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ECalendarSyncType,
  EExternalCalendarProvider,
  EOperatingSystem,
} from '../../../../../../app/auth/auth.interfaces';
import { API_ROUTES } from '../../../../../../app/constants';
import GoogleLogo from '../../../../../../assets/images//Google-logo.png';
import MicrosoftOutlookLogo from '../../../../../../assets/images//Microsoft-Outlook-logo.png';
import { ReactComponent as AppleLogoComponent } from '../../../../../../assets/images//Apple-logo-svg.svg';
import ImportAndExportIcon from '../../../../../../assets/images//import-and-export-icon.png';
import ExportIcon from '../../../../../../assets/images//export-icon.png';
import ImportIcon from '../../../../../../assets/images//import-icon.png';
import SyncButton from './SyncButton';
import { ICalProviderSync } from './ical-provider-sync/ICalProviderSync';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import StyledAppButton from '../../../../app-button/styled-app-button/StyledAppButton';
import { appleCalendarSyncRequestAction } from '../../../../../../app/useAppData/user.store';
import { getFeatureFlagByKey } from '../../../../../utils/utils';
import {
  isMobileAppWebView,
  sendAppleCalendarSyncRequestToMobileApp,
} from '../../../../../../mobile-application-utils';
import BackArrowIcon from '../../../../icons/back-arrow-icon/BackArrowIcon';
import { getOperatingSystem } from '../../../../../utils/getMobileOperatingSystem';
import AppButton from '../../../../app-button/AppButton';

export const clientBaseUrl = window.location.origin;

const calendarLoginRoutes = {
  [EExternalCalendarProvider.GOOGLE]: API_ROUTES.AUTH.GOOGLE_CALENDAR_LOGIN,
  [EExternalCalendarProvider.OUTLOOK]: API_ROUTES.AUTH.OUTLOOK_CALENDAR_LOGIN,
  [EExternalCalendarProvider.ICAL]: API_ROUTES.AUTH.ICAL_CALENDAR_LOGIN,
  [EExternalCalendarProvider.APPLE]: API_ROUTES.AUTH.APPLE_CALENDAR_LOGIN,
};

export const CalendarSyncPopover = () => {
  const { t } = useTranslation();
  const [selectedCalendarType, setSelectedCalendarType] = useState<string | undefined | null>(null);
  const { user } = useAppSelector((store) => store.userReducer);
  const dispatch = useAppDispatch();

  const authRedirect = (syncType: ECalendarSyncType) => {
    const calendarLoginRoute = selectedCalendarType
      ? calendarLoginRoutes[selectedCalendarType as EExternalCalendarProvider]
      : null;
    // Assign userId to requestId
    // Pass userId to the redirect function to identify the authenticated user
    const serverBaseUrl = process.env.REACT_APP_BASE_URL_CSHARP;
    const redirectUrl = `${serverBaseUrl}/${API_ROUTES.AUTH.REDIRECT}/${selectedCalendarType}`;
    const queryParams = `redirectUri=${serverBaseUrl}/${calendarLoginRoute}&isCalendarLogin=true&syncType=${syncType}&requestId=${user?.id}&state=${clientBaseUrl}/app/chat?settingsMenu=open`;
    window.location.href = `${redirectUrl}?${queryParams}`;
  };

  const syncAppleCalendar = (syncType: ECalendarSyncType) => {
    dispatch(appleCalendarSyncRequestAction({ calendarId: `apple_${user?.id}`, syncType }));
    sendAppleCalendarSyncRequestToMobileApp({ syncType });
  };

  return (
    <div className="calendar-sync-popover-container">
      {selectedCalendarType ? (
        <div>
          <section className="title-and-back-section">
            <AppButton
              id="close-calendar-sync-overlay"
              type="button"
              className="calendar-sync-overlay-header-back"
              onClick={() => setSelectedCalendarType(null)}
            >
              <BackArrowIcon />
            </AppButton>
            <h4 className="calendar-sync-overlay-header-text">
              {selectedCalendarType === EExternalCalendarProvider.ICAL
                ? t('iCalSyncEnterUrl')
                : t('settingsPersonalInfoCalendarSyncType')}
            </h4>
          </section>
          {selectedCalendarType === EExternalCalendarProvider.ICAL ? (
            <ICalProviderSync />
          ) : (
            <div className="calendar-sync-type-buttons">
              <SyncButton
                syncType={ECalendarSyncType.FULL}
                icon={ImportAndExportIcon}
                title="settingsPersonalInfoCalendarImportAndExportTitle"
                subtitle="settingsPersonalInfoCalendarImportAndExportSubTitle"
                imageAltText="importAndExportImageAltText"
                onClick={
                  selectedCalendarType == EExternalCalendarProvider.APPLE
                    ? syncAppleCalendar
                    : authRedirect
                }
              />
              <SyncButton
                syncType={ECalendarSyncType.EXPORT}
                icon={ExportIcon}
                title="settingsPersonalInfoCalendarExportTitle"
                subtitle="settingsPersonalInfoCalendarExportSubTitle"
                imageAltText="exportImageAltText"
                onClick={
                  selectedCalendarType == EExternalCalendarProvider.APPLE
                    ? syncAppleCalendar
                    : authRedirect
                }
              />
              <SyncButton
                syncType={ECalendarSyncType.IMPORT}
                icon={ImportIcon}
                title="settingsPersonalInfoCalendarImportTitle"
                subtitle="settingsPersonalInfoCalendarImportSubTitle"
                imageAltText="importImageAltText"
                onClick={
                  selectedCalendarType == EExternalCalendarProvider.APPLE
                    ? syncAppleCalendar
                    : authRedirect
                }
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <h4 className="calendar-sync-popover-title">
            {t('settingsPersonalInfoCalendarChooseService')}
          </h4>
          <div className="calendar-sync-popover-buttons">
            <AppButton
              id="sync-with-google-calendar"
              className="calendar-sync-popover-google-button"
              onClick={() => setSelectedCalendarType(EExternalCalendarProvider.GOOGLE)}
            >
              <img src={GoogleLogo} alt={t('loginGoogleLogoImageAltText')} />
              {t('syncWithGoogle')}
            </AppButton>
            <AppButton
              id="sync-with-microsoft-outlook-calendar"
              className="calendar-sync-popover-microsoft-outlook-button"
              onClick={() => setSelectedCalendarType(EExternalCalendarProvider.OUTLOOK)}
            >
              <img src={MicrosoftOutlookLogo} alt={t('loginMicrosoftOutlookLogoImageAltText')} />
              {t('syncWithMicrosoftOutlook')}
            </AppButton>
            {isMobileAppWebView() &&
              getOperatingSystem() === EOperatingSystem.IOS &&
              getFeatureFlagByKey('AppleCalendarSyncEnabled') && (
                <AppButton
                  id="sync-with-apple-calendar"
                  className="calendar-sync-popover-apple-button"
                  onClick={() => setSelectedCalendarType(EExternalCalendarProvider.APPLE)}
                >
                  <AppleLogoComponent className="contrast-color-fill-icon" />
                  {t('syncWithApple')}
                </AppButton>
              )}
            <StyledAppButton
              id="sync-with-ical-calendar"
              className="calendar-sync-popover-ical-button"
              onClick={() => setSelectedCalendarType(EExternalCalendarProvider.ICAL)}
            >
              {t('iCalSyncFrom')}
            </StyledAppButton>
          </div>
        </>
      )}
    </div>
  );
};
