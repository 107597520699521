import removeAppOverlayPopover from '../../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import AppPopUpToast from '../../../../shared/components/app-pop-up-toast/AppPopUpToast';

interface CloseTimerToastProps {
  onClose: () => void;
}

export const TimerCloseToast = (prop: CloseTimerToastProps) => {
  const toastBody = 'closeTimerToastMessage';
  const ctaText = 'closeTimerToastCTA';

  const handleCTA = () => {
    prop.onClose();
  };

  return (
    <AppPopUpToast
      id="mobile-awareness-toast"
      body={toastBody}
      CTA_Text={ctaText}
      dontShowAgainBtnText="closeTimerToastCancel"
      CTA_action={handleCTA}
      onClose={() => removeAppOverlayPopover()}
    />
  );
};
