import { ReactComponent as NoFilterResultsEmptyStateImage } from '../../../../../../assets/images/mascot/birds-mascot.svg';
import { ReactComponent as NoTasksEmptyStateImage } from '../../../../../../assets/images/mascot/tasks-mascot.svg';
import { ReactComponent as NoFilterResultsEmptyStateDarkImage } from '../../../../../../assets/images/mascot/birds-mascot-dark.svg';
import { ReactComponent as NoTasksEmptyStateDarkImage } from '../../../../../../assets/images/mascot/tasks-mascot-dark.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../app/store';
import type { IAppEmptyStateProps } from '../../../../../../shared/components/app-empty-state/AppEmptyState';
import AppEmptyState from '../../../../../../shared/components/app-empty-state/AppEmptyState';
import {
  EAppThemes,
  getThemeByDocumentDataThemeAttr,
} from '../../../../../../shared/utils/themes.utils';

export const StageTasksEmptyState = () => {
  const { tasksFilter } = useAppSelector((store) => store.StageTasksReducer);
  const { t } = useTranslation();
  const isDarkMode = getThemeByDocumentDataThemeAttr() === EAppThemes.DARK;

  const getEmptyStateProps = (): IAppEmptyStateProps => {
    if (tasksFilter.length >= 1) {
      return {
        SvgEmptyStateComponent: isDarkMode
          ? NoFilterResultsEmptyStateDarkImage
          : NoFilterResultsEmptyStateImage,
        paragraph: t('noFilterResultTasksEmptyStateText'),
      };
    }
    return {
      SvgEmptyStateComponent: isDarkMode ? NoTasksEmptyStateDarkImage : NoTasksEmptyStateImage,
      paragraph: t('noTasksEmptyStateText'),
    };
  };

  return <AppEmptyState {...getEmptyStateProps()} className="stage-tasks-empty-state" />;
};
