import { ReactComponent as PlusSvgComponent } from '../../../assets/images/plan-create-plus-icon.svg';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import PlusButtonOverlay from './plus-button-overlay/PlusButtonOverlay';
import './CreatePlusButton.scss';
import { useAppSelector } from '../../../app/store';
import { ECloseSwipeDirection } from '../../../shared/hooks/swipe-hooks/swipe.utils';
import { getCenterCoordinates, getDomElementByQuerySelector } from '../../../shared/utils/utils';
import plusButtonSassVariables from './CreatePlusButton.module.scss';
import AppButton from '../../../shared/components/app-button/AppButton';

const CreatePlusButton = () => {
  const { currentTaskPlacement } = useAppSelector((store) => store.StagePlannerReducer);

  const onOpenOverlay = () => {
    createAppOverlayPopover(
      <PlusButtonOverlay />,
      'plan-plus-overlay-menu-container',
      null,
      {
        ...getCenterCoordinates(
          getDomElementByQuerySelector('#stage-container-main'),
          parseInt(plusButtonSassVariables.planPlusMenuWidth),
          parseInt(plusButtonSassVariables.planPlusMenuHeight),
        ),
      },
      {
        // overlay config
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.HALF_SCREEN,
        shouldCloseBySwipeOnMobile: true,
        closeSwipeDirection: ECloseSwipeDirection.DOWN,
        isCustomStyle: true,
      },
    );
  };

  return (
    <>
      {!currentTaskPlacement && (
        <AppButton id="plan-view-plus-button" className="create-plus-btn" onClick={onOpenOverlay}>
          <PlusSvgComponent className="plus-fab" />
        </AppButton>
      )}
    </>
  );
};

export default CreatePlusButton;
