import DiscordLink from '../../discord-link/DiscordLink';
import { TopSectionTitle } from './top-section-title/TopSectionTitle';
import { PersonalInfo } from './personal-info/PersonalInfo';
import { SettingsFeedback } from './settings-feedback/SettingsFeedback';
import { ImportantLinks } from './important-links/ImportantLinks';
import { useSearchParams } from 'react-router-dom';
import { settingsMenuParam } from '../../../../app/constants';
import { InviteFriendsCard } from './invite-friends-card/InviteFriendsCard';
import './SettingsMenu.scss';
import type { IProactiveReminders } from '../../../../app/auth/auth.interfaces';
import { ProactiveReminders } from './proactive-reminders/ProactiveReminders';
import { FormProvider, useForm } from 'react-hook-form';
import { userPhoneFormatter } from './SettingsMenu.utils';
import { InternalUserSettings } from './internal-user-settings/InternalUserSettings';
import { useAppSelector } from '../../../../app/store';
import { useEffect } from 'react';
import AppButton from '../../app-button/AppButton';
import BackArrowIcon from '../../icons/back-arrow-icon/BackArrowIcon';

export interface IProfileForm {
  phoneNumber: string;
  isUsPhoneNumber: boolean;
  preferredName: string;
  userTextNotificationsEnabled: boolean;
  editName: boolean;
  editPhone: boolean;
  shouldDisplayPhoneError: boolean;
  shouldDisplayTextNotificationError: boolean;
  shouldDisplayPushNotificationError: boolean;
  shouldDisplayAudioError: boolean;
  shouldDisplayNameError: boolean;
  shouldDisplayThemeError: boolean;
  proactiveReminders: IProactiveReminders;
  clientFlags: number;
  userSettingsFlags: number;
  featureFlags: string;
}

export const SettingsMenu = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAppSelector((store) => store.userReducer);

  const form = useForm<IProfileForm>({
    defaultValues: {
      phoneNumber: userPhoneFormatter(user?.phoneNumber || ''),
      userTextNotificationsEnabled: !!user?.userTextNotificationsEnabled,
      preferredName: user?.preferredName ?? '',
      editName: false,
      editPhone: false,
      shouldDisplayPhoneError: false,
      shouldDisplayTextNotificationError: false,
      shouldDisplayPushNotificationError: false,
      shouldDisplayAudioError: false,
      shouldDisplayNameError: false,
      shouldDisplayThemeError: false,
      proactiveReminders: user?.proactiveReminders,
      clientFlags: user?.clientFlags || 0,
      userSettingsFlags: user?.userSettingsFlags || 0,
      featureFlags: JSON.stringify(user?.featureFlags || '{}', null, 2),
    },
  });

  useEffect(() => {
    form.reset({
      ...form.getValues(),
      phoneNumber: userPhoneFormatter(user?.phoneNumber || ''),
      userTextNotificationsEnabled: !!user?.userTextNotificationsEnabled,
      preferredName: user?.preferredName ?? '',
      proactiveReminders: user?.proactiveReminders,
      clientFlags: user?.clientFlags || 0,
      userSettingsFlags: user?.userSettingsFlags || 0,
      featureFlags: JSON.stringify(user?.featureFlags || '{}', null, 2),
    });
  }, [user]);

  const onCloseSettings = () => {
    setSearchParams((prev) => {
      prev.delete(settingsMenuParam);
      return prev;
    });
  };

  return (
    <>
      {searchParams.get(settingsMenuParam) && (
        <FormProvider {...form}>
          <div className={`settings-menu fadeIn`} data-testid="settings-menu">
            <AppButton
              id="close-settings"
              className="close-settings"
              type="button"
              onClick={onCloseSettings}
            >
              <BackArrowIcon />
            </AppButton>
            <div className="scrollable-container">
              <TopSectionTitle />
              <PersonalInfo />
              <ProactiveReminders />
              <InviteFriendsCard />
              <SettingsFeedback />
              <DiscordLink className="settings-section-container" />
              {user?.isInternalUser && (
                <>
                  <InternalUserSettings />
                </>
              )}
              <ImportantLinks />
            </div>
          </div>
        </FormProvider>
      )}
    </>
  );
};
