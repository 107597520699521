import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import type { IUserSettingsFeedbackForm } from '../SettingsMenu.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { sendUserFeedbackRequest } from '../settingsMenu.store';
import { useApiData } from '../../../../hooks/useApiData';
import { EAPIStatus } from '../../../../api/models';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { scrollIntoView } from '../../../../utils/scrollIntoView';
import './SettingsFeedback.scss';
import { Switch } from 'antd';
import StyledAppButton from '../../../app-button/styled-app-button/StyledAppButton';
import { isValidEmail } from '../../../../utils/utils';

export const SettingsFeedback = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userFeedback } = useAppSelector((store) => store.settingsMenuReducer);
  const { user: userInfo } = useAppSelector((store) => store.userReducer);
  const { register, handleSubmit, reset, formState, watch, setValue } =
    useForm<IUserSettingsFeedbackForm>({
      defaultValues: {
        feedbackText: '',
        contactEmail: userInfo?.email || '',
        shouldSendEmail: !!userInfo?.email,
      },
    });

  const shouldSendEmailWatch = watch('shouldSendEmail');
  const contactEmailWatch = watch('contactEmail');

  const onSubmitUserFeedback = (formData: IUserSettingsFeedbackForm) => {
    if (!formData.shouldSendEmail || !formData.contactEmail) delete formData.contactEmail;
    delete formData.shouldSendEmail;
    dispatch(sendUserFeedbackRequest(formData));
  };

  useApiData(userFeedback, {
    onFulfilled() {
      reset({
        feedbackText: '',
        contactEmail: userInfo?.email || '',
        shouldSendEmail: !!userInfo?.email,
      });
    },
    onRejected() {
      reset({ shouldSendEmail: shouldSendEmailWatch }, { keepValues: true });
    },
  });

  const shouldDisplayError = () => {
    return (
      [EAPIStatus.REJECTED].includes(userFeedback.status) &&
      isErrorCodeNotForbiddenOrUnauthorized(userFeedback?.error?.code)
    );
  };

  const handleEmailToggle = (checked: boolean) => {
    setValue('shouldSendEmail', checked);
  };

  const isSubmitDisabled = () => {
    return (
      (shouldSendEmailWatch && !isValidEmail(contactEmailWatch)) ||
      !formState.isValid ||
      formState.isSubmitted ||
      userFeedback.status === EAPIStatus.PENDING
    );
  };

  return (
    <section
      className="settings-feedback settings-section-container"
      data-testid="settings-feedback"
    >
      <h2 className="sub-title static-string">{t('settingsFeedbackTitle')}</h2>
      <div className="separation"></div>
      <form data-testid="setting-feedback-form" onSubmit={handleSubmit(onSubmitUserFeedback)}>
        <label htmlFor="feedback">{t('settingsFeedbackLabelText')}</label>
        <div className="error-relative">
          <textarea
            data-testid="setting-feedback-textarea"
            id="settings-feedback"
            className="app-textarea"
            placeholder={t('settingTextareaPlaceholderText')}
            {...register('feedbackText', { required: true, minLength: 2 })}
            onClick={() =>
              scrollIntoView('#settings-feedback', { behavior: 'smooth', block: 'center' })
            }
          />
          {shouldDisplayError() && <ErrorMessage />}
        </div>
        <div className="email-and-submit-container">
          <section className="email-switch-section">
            <Switch
              className="app-toggle app-toggle-active-dark"
              checked={shouldSendEmailWatch}
              onChange={handleEmailToggle}
            />
            <input
              type="text"
              className="email-input"
              aria-label="email"
              autoComplete="false"
              placeholder={t('emailInputPlaceholder')}
              {...register('contactEmail', { required: false, minLength: 2 })}
            />
            <StyledAppButton
              id="submit-feedback-in-settings"
              className="submit-feedback"
              type="submit"
              data-testid="settings-feedback-submit"
              disabled={isSubmitDisabled()}
            >
              {t('submit')}
            </StyledAppButton>
          </section>
          {shouldSendEmailWatch && !isValidEmail(contactEmailWatch) && (
            <ErrorMessage
              messageTranslationKey="settingsFeedbackSwitchAllowEmailError"
              isAbsolute={false}
              className="switch-email-text switch-email-text--error"
            />
          )}
          <p className="switch-email-text">{t('settingsFeedbackSwitchEmailText')}</p>
        </div>
      </form>
    </section>
  );
};
