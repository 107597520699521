import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../app/store';
import { createOrContinueSession } from './chat.store';
import { ResizableContainer } from './resizable-container/ResizableContainer';
import { ApplicationInsightsApi } from '../../application-insights';
import ProductTourWrapper, { EProductTourViewType } from '../product-tour/ProductTourWrapper';
import './ChatWrapper.scss';

export const ChatWrapper: FunctionComponent = () => {
  // this carousel open only after registration
  const componentShouldLoad = useRef(true);
  const dispatch = useAppDispatch();

  const onStartSession = useCallback(() => {
    // call createOrContinueSession Api
    dispatch(createOrContinueSession())
      .unwrap()
      .catch((e) => {
        ApplicationInsightsApi.trackException(e);
      });
  }, [dispatch]);

  useEffect(() => {
    if (componentShouldLoad.current) {
      componentShouldLoad.current = false;
      onStartSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStartSession]);

  return (
    <div className="chat-wrapper">
      <ResizableContainer />
      <ProductTourWrapper
        dataAttribute="data-product-tour"
        viewType={EProductTourViewType.PRODUCT_TOUR}
      />
    </div>
  );
};
