import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../app-button/AppButton';
import { ReactComponent as ShareIcon } from '../../../../assets/images/share-icon.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/images/copy-icon.svg';
import { ReactComponent as CopiedIcon } from '../../../../assets/images/check-icon.svg';
import './TimerShare.scss';
import { ApplicationInsightsApi } from '../../../../application-insights';
import { baseUrl } from '../../../../app/auth/auth.utils';
import { useAppSelector } from '../../../../app/store';

interface IProps {
  className?: string;
}

enum EComponentState {
  OFFER_SHARING = 1,
  OFFER_COPY,
  HAS_COPIED,
}

export const TimerShareButton = ({ className = '', ...attributes }: IProps) => {
  const [componentState, setComponentState] = useState(EComponentState.OFFER_SHARING);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const { activeTimer: timer } = useAppSelector((store) => store.StageTimerReducer);
  const timerId = timer?.id;
  const timerRef = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    return clearTimer();
  }, []);

  const clearTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const runTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setComponentState(EComponentState.OFFER_SHARING);
      setHasError(false);
    }, 3000);
  };

  const handleCopyLink = async () => {
    try {
      const shareUrl = `${baseUrl}/timer/${timerId}`;
      await navigator.clipboard.writeText(shareUrl);
      setComponentState(EComponentState.HAS_COPIED);
      runTimer();
    } catch (error) {
      ApplicationInsightsApi.trackTrace('TimerShareButton - Failed to copy link', { error });
      ApplicationInsightsApi.trackException(error);
      setHasError(true);
      runTimer();
    }
  };

  const handleClickShareTimer = async () => {
    try {
      if (!navigator.share) {
        return setComponentState(EComponentState.OFFER_COPY);
      }
      const shareData = {
        title: t('taskTimerActionButtonText'),
        text: `${t('taskTimerActionButtonText')} - ${timer?.name || ''}`,
        url: `${baseUrl}/timer/${timerId}`,
      };
      await navigator.share(shareData);
    } catch (error) {
      setComponentState(EComponentState.OFFER_COPY);
    }
  };

  const getShareButtonText = () => {
    switch (componentState) {
      case EComponentState.OFFER_SHARING:
        return t('studyWithFriends');
      case EComponentState.OFFER_COPY:
        return t('sharedTimerLinkCopy');
      case EComponentState.HAS_COPIED:
        return t('sharedTimerLinkCopied');
      default:
        return '';
    }
  };

  const getShareButtonIcon = () => {
    switch (componentState) {
      case EComponentState.OFFER_SHARING:
        return <ShareIcon className="timer-share-icon" />;
      case EComponentState.OFFER_COPY:
        return <CopyIcon className="timer-share-icon" />;
      case EComponentState.HAS_COPIED:
        return <CopiedIcon className="timer-share-icon" />;
      default:
        return <ShareIcon className="timer-share-icon" />;
    }
  };

  return (
    <div
      className={`timer-share-button-container ${
        timer?.hasError ? 'timer-share-button-container--hidden' : ''
      }`}
      {...attributes}
    >
      <AppButton
        id="timer-share"
        className={`timer-share-button ${
          componentState === EComponentState.HAS_COPIED ? 'timer-share-button--copied' : ''
        }`}
        onClick={
          componentState === EComponentState.OFFER_COPY ? handleCopyLink : handleClickShareTimer
        }
        disabled={componentState === EComponentState.HAS_COPIED}
      >
        <span>{getShareButtonText()}</span>
        {getShareButtonIcon()}
      </AppButton>
      {hasError && <div className="timer-share-error">{t('shareLinkNotCopiedToast')}</div>}
    </div>
  );
};
