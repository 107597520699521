import React from 'react';
import { ReactComponent as themeSystemSettigsIconComponent } from '../../../../../../assets/images/settings-menu/theme-control/theme-system-settings.svg';
import { ReactComponent as themeLightIconComponent } from '../../../../../../assets/images/settings-menu/theme-control/theme-light.svg';
import { ReactComponent as themeDarkIconComponent } from '../../../../../../assets/images/settings-menu/theme-control/theme-dark.svg';
import ThemeButton from './ThemeButton';
import { EAppThemes } from '../../../../../utils/themes.utils';
import './ThemeButtonsContainer.scss';
import { EClientFlags } from '../../SettingsMenu.interfaces';

const ThemeButtonsContainer = () => {
  return (
    <div className="theme-control-buttons-container" data-testid="theme-control-buttons-container">
      <ThemeButton
        type={EAppThemes.LIGHT}
        SvgComponent={themeLightIconComponent}
        themeFlag={EClientFlags.LIGHT_MODE_ON}
      />
      <ThemeButton
        type={EAppThemes.DARK}
        SvgComponent={themeDarkIconComponent}
        themeFlag={EClientFlags.DARK_MODE_ON}
      />
      <ThemeButton type="systemSettings" SvgComponent={themeSystemSettigsIconComponent} />
    </div>
  );
};

export default ThemeButtonsContainer;
