import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import React, { useEffect } from 'react';
import QuickOptionsReminder from './quick-options-reminders/QuickOptionsReminder';
import CustomReminder from './custom-reminder/CustomReminder';
import type { ITaskEditorOverlayType } from '../TaskEditOverlay';
import './ReminderOverlay.scss';

interface IReminderOverlayProps {
  setReminderOverlayType: Dispatch<SetStateAction<'quickOptions' | 'custom' | null>>;
  reminderOverlayType: 'quickOptions' | 'custom' | null;
  editOverlayType: ITaskEditorOverlayType;
  relativeDate: Date;
  selectedReminder: number | undefined | null;
  setSelectedReminder: Dispatch<SetStateAction<number | null | undefined>>;
  setSelectedDefaultReminderOnInit: Dispatch<SetStateAction<number | null | undefined>>;
}

const ReminderOverlay: FunctionComponent<IReminderOverlayProps> = ({
  setSelectedReminder,
  selectedReminder,
  setReminderOverlayType,
  reminderOverlayType,
  editOverlayType,
  relativeDate,
  setSelectedDefaultReminderOnInit,
}) => {
  useEffect(() => {
    if (selectedReminder && reminderOverlayType !== 'custom') {
      setReminderOverlayType('custom');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReminderComponentByType = () => {
    switch (reminderOverlayType) {
      case 'quickOptions':
        return (
          <QuickOptionsReminder
            relativeDate={relativeDate}
            setReminderOverlayType={setReminderOverlayType}
            editOverlayType={editOverlayType}
            setSelectedReminder={setSelectedReminder}
          />
        );
      case 'custom':
        return (
          <CustomReminder
            relativeDate={relativeDate}
            selectedReminder={selectedReminder}
            setSelectedReminder={setSelectedReminder}
            editOverlayType={editOverlayType}
            setSelectedDefaultReminderOnInit={setSelectedDefaultReminderOnInit}
          />
        );
      default:
        return <></>;
    }
  };
  return <div className="reminder-overlay-main-container">{getReminderComponentByType()}</div>;
};

export default ReminderOverlay;
