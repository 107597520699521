import type { FunctionComponent } from 'react';
import React from 'react';
import TaskActionButton from './TaskActionButton';
import type { ITaskSingleActionButtonsProps } from '../TasksListSingleItem.interfaces';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../../app/store';
import { setTimerTask } from '../../../stage-timer/stageTimer.store';
import { getTaskFromAllTaskList } from '../../../../../../../shared/utils/utils';

const TaskTimerActionButton: FunctionComponent<ITaskSingleActionButtonsProps> = ({
  task,
  className = '',
}) => {
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const handleTimerClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const taskId = task.parentId || task.id;
    if (!taskId) return;
    const taskForTimer = getTaskFromAllTaskList(taskId);
    if (!taskForTimer) return;
    dispatch(setTimerTask(taskForTimer));
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams),
      stageContent: 'timer',
      from: 'tasks',
    }));
  };

  return (
    <TaskActionButton
      actionName={t('taskTimerActionButtonText')}
      actionType="timer"
      onClickAction={handleTimerClick}
      className={className}
    />
  );
};

export default TaskTimerActionButton;
