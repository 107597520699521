import { FunctionComponent } from 'react';
import { ReactComponent as CloseSvgComponentBigIcon } from '../../../../assets/images/big-arrow-back-icon.svg';
import { ReactComponent as CloseSvgComponentSmallIcon } from '../../../../assets/images/small-arrow-back-icon.svg';
import './BackArrowIcon.scss';
import { isDesktopView } from '../../../utils/utils';

interface IBackArrowIconProps {
  className?: string;
  direction?: 'left' | 'right';
}

const BackArrowIcon: FunctionComponent<IBackArrowIconProps> = ({
  className = '',
  direction = 'left',
}) => {
  return (
    <>
      {isDesktopView() ? (
        <CloseSvgComponentBigIcon
          className={`primary-color-stroke-icon arrow-icon--${direction} ${className}`}
        />
      ) : (
        <CloseSvgComponentSmallIcon
          className={`primary-color-stroke-icon arrow-icon--${direction} ${className} `}
        />
      )}
    </>
  );
};

export default BackArrowIcon;
