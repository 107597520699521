import './StageTimer.scss';
import Timer from '../../../../../shared/components/Timer/Timer';

export const StageTimer = () => {
  return (
    <div className="stage-timer">
      <Timer showCompletedAsText={false} shouldShowControls={true} />
    </div>
  );
};

export default StageTimer;
