import type { FunctionComponent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NextIconComponent } from '../../assets/images/product-tour/next.svg';
import type { IProductTour, ModalPosition } from './ProductTour.utils';
import { getModalPosition, logProductTourDisplayedEvent } from './ProductTour.utils';
import { stageParam } from '../../app/constants';
import productTourSassVariables from './ProductTour.module.scss';
import { isMobileView } from '../../shared/utils/utils';
import './ProductTour.scss';
import { useAppDispatch } from '../../app/store';
import { useSearchParams } from 'react-router-dom';
import { setShouldDisplayProductTour } from '../../shared/store/shared.store';
import { ProductTourArray } from './tour-arrays/ProductTourArray';
import { PlanTourArray } from './tour-arrays/PlanTourArray';
import type { IProductTourWrapperProps } from './ProductTourWrapper';
import { EProductTourViewType } from './ProductTourWrapper';
import { ApplicationInsightsApi } from '../../application-insights';
import AppButton from '../../shared/components/app-button/AppButton';
import { setIsMiniPlanTourOpened } from '../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';

interface IProductTourProps extends IProductTourWrapperProps {
  setShouldDisplayComponent: (shouldDisplay: boolean) => void;
}

const ProductTour: FunctionComponent<IProductTourProps> = ({
  dataAttribute,
  className,
  viewType,
  setShouldDisplayComponent,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [modalPositionStyleObj, setModalPositionStyleObj] = useState<ModalPosition | null>(null);
  const productTourArr: IProductTour[] =
    viewType === EProductTourViewType.PRODUCT_TOUR ? ProductTourArray() : PlanTourArray();
  const { t } = useTranslation();
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const handleNext = () => {
    if (activeIndex === productTourArr.length - 1) {
      // remove product-tour
      handleClose();
      return;
    }
    productTourArr[activeIndex]?.next();
    setActiveIndex(activeIndex + 1);
  };

  const handlePrev = () => {
    productTourArr[activeIndex]?.prev();
    setActiveIndex(activeIndex - 1);
  };

  useEffect(() => {
    if (productTourArr[activeIndex]?.target) {
      let target = productTourArr[activeIndex]?.target;
      if (isMobileView() && !!productTourArr[activeIndex]?.mobileTarget)
        target = productTourArr[activeIndex]?.mobileTarget;
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        const position = getModalPosition({
          modalWidth: Number(productTourSassVariables.productTourCardWidth),
          modalHeight: Number(productTourSassVariables.productTourCardHeight),
          elementSelector: `[${dataAttribute}=${target}]`,
        });
        setModalPositionStyleObj(position);
      }, 15);
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    logProductTourDisplayedEvent(viewType);
  }, []);

  const getTourCardClassName = () => {
    let cardClassName = 'product-tour-card';
    if (activeIndex === productTourArr.length - 1)
      cardClassName += ' product-tour-card--last-screen';
    if (!productTourArr[activeIndex]?.target) cardClassName += ' product-tour-card--no-target';
    if (!!productTourArr[activeIndex]?.target && !modalPositionStyleObj) cardClassName += ' d-none';
    if (className) cardClassName += ` ${className}`;
    if (productTourArr[activeIndex]?.cardClassName)
      cardClassName += ` ${productTourArr[activeIndex]?.cardClassName}`;
    return cardClassName;
  };

  const handleCloseProductTour = () => {
    ApplicationInsightsApi.trackEvent('*SignUpProductTourClosed');
    setSearchParams((prev) => {
      prev.delete(stageParam);
      return prev;
    });
    dispatch(setShouldDisplayProductTour(false));
  };

  const handleClose = () => {
    switch (viewType) {
      case EProductTourViewType.PRODUCT_TOUR:
        handleCloseProductTour();
        break;
      case EProductTourViewType.PLAN_TOUR:
        dispatch(setIsMiniPlanTourOpened(false));
        break;
      default:
        break;
    }
    setShouldDisplayComponent(false);
  };

  return (
    <div className={`product-tour-container fadeIn ${className ? className : ''}`}>
      <div
        style={{
          top: modalPositionStyleObj?.top,
          left: modalPositionStyleObj?.left,
        }}
        className={getTourCardClassName()}
      >
        <section>
          <div className="title-container">
            <h3 className="product-tour-card-title">{productTourArr[activeIndex]?.title}</h3>
            <div className="tour-icon app-mascot">{productTourArr[activeIndex]?.icon}</div>
          </div>
          <p className="product-tour-card-paragraph">{productTourArr[activeIndex]?.text}</p>
          {productTourArr[activeIndex]?.list && (
            <ul className="product-tour-card-list">
              {(productTourArr[activeIndex]?.list || []).map((item, index) => (
                <li className="product-tour-card-list-bullet" key={index}>
                  <b className="product-tour-card-bullet-title">{item.title}</b>
                  <p className="product-tour-card-bullet-text">{item.text}</p>
                </li>
              ))}
            </ul>
          )}
        </section>
        <section className="actions-container">
          <AppButton
            id="skip-product-tour"
            className={`product-tour-button--skip ${
              activeIndex == productTourArr.length - 1 ? 'product-tour-button--hidden' : ''
            }`}
            onClick={handleClose}
          >
            {t('productTourSkipText')}
          </AppButton>
          <div className="actions-steps-container">
            {activeIndex !== 0 && activeIndex !== productTourArr.length - 1 && (
              <AppButton
                id="product-tour-prev"
                className="product-tour-button--prev"
                onClick={handlePrev}
              >
                <NextIconComponent />
              </AppButton>
            )}
            {activeIndex === 0 || activeIndex === productTourArr.length - 1 ? (
              <AppButton
                id="product-tour-start"
                className="product-tour-button--start-and-done"
                onClick={handleNext}
              >
                {activeIndex === 0 ? t('productTourStartActionText') : t('productTourDoneText')}
              </AppButton>
            ) : (
              <AppButton
                id="product-tour-next"
                className="product-tour-button--next"
                onClick={handleNext}
              >
                <NextIconComponent />
              </AppButton>
            )}
          </div>
        </section>
        <div
          className={`chevron ${modalPositionStyleObj?.chevronPosition?.direction}`}
          style={{
            top: modalPositionStyleObj?.chevronPosition?.top,
            left: modalPositionStyleObj?.chevronPosition?.left,
          }}
        ></div>
      </div>
    </div>
  );
};

export default ProductTour;
