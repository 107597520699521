import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { EAPIStatus } from '../../../../../shared/api/models';
import { transformResponseMessageToChatMessage } from '../../../chat/Chat.utils';
import MessageWrapper from '../../../chat/message/MessageWrapper';
import ErrorMessage from '../../../chat/message/error-message/ErrorMessage';
import closeImg from '../../../../../assets/images/close-toast.svg';
import { resetBotResponse } from '../../../chat.store';
import './ToastMessages.scss';
import messageLoadingAnimation from '../../../chat/MessageLoadingAnimation.json';
import Lottie from 'lottie-react';
import { isMobileView, setDataAttributeOnBody } from '../../../../../shared/utils/utils';
import {
  aiToolNames,
  bodyInputFocusedClassName,
  dataAttributes,
  isChatInputFocusedLocalStorageKey,
  stageParam,
} from '../../../../../app/constants';
import { getItemFromLocalStorage } from '../../../../../shared/utils/localStorage.utils';
import type { IChatMessage } from '../../../chat.interfaces';
import { useApiData } from '../../../../../shared/hooks/useApiData';
import SwipeToCloseWrapper from '../../../../../shared/components/swipe-to-close-wrapper/SwipeToCloseWrapper';
import { ECloseSwipeDirection } from '../../../../../shared/hooks/swipe-hooks/swipe.utils';
import { useSearchParams } from 'react-router-dom';
import { EAppStageContentType } from '../stageContainer.interfaces';

const ToastMessages = () => {
  const { updateTaskRes, tasksListResponse } = useAppSelector((store) => store.StageTasksReducer);
  const { isMiniPlanTourOpened } = useAppSelector((store) => store.StagePlannerReducer);
  const { botResponse } = useAppSelector((store) => store.chatReducer);
  const [shouldDisplayMessage, setShouldDisplayMessage] = useState<'info' | 'error' | null>(null);
  const startFadeOutAnimationTimerRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const [shouldStartDestroyAnimation, setShouldStartDestroyAnimation] = useState(false);
  // After this time passes (30 seconds), the destroy animation (fade-out) will start.
  const timeInMilliSecondsToStartTheDestroyAnimation = 30000;
  const [toastMessage, setToastMessage] = useState<IChatMessage | null>(null);
  const [searchParams, _] = useSearchParams();
  const stageScreenParam: EAppStageContentType | null = useMemo(
    () => (searchParams.get(stageParam) as EAppStageContentType) || null,
    [searchParams],
  );
  const prevStageSearchParamRef = useRef<EAppStageContentType | null>(stageScreenParam);

  // if the user switch by the ai or the bot is still thinking the toast should not destroying, else it should destroy
  const shouldDestroyToastAfterSwitchingTabs = useCallback(() => {
    if (botResponse.status === EAPIStatus.PENDING) return false;
    else if (botResponse.data && botResponse.data?.messages?.length > 0) {
      const botMessageToolName =
        botResponse.data.messages[botResponse.data.messages.length - 1].tool?.name;
      return botMessageToolName !== aiToolNames.XU_ENTER_FORM;
    }
    return true;
  }, [botResponse, botResponse.data]);

  useEffect(() => {
    // Close the toast if the user switches to another tab by himself (not by the aiControl) and the bot is not "thinking"
    if (stageScreenParam !== prevStageSearchParamRef.current) {
      prevStageSearchParamRef.current = stageScreenParam as EAppStageContentType;
      if (shouldDisplayMessage && shouldDestroyToastAfterSwitchingTabs()) {
        onClose();
      }
    }
  }, [searchParams]);

  const clearTimer = () => {
    if (startFadeOutAnimationTimerRef.current) clearTimeout(startFadeOutAnimationTimerRef.current);
  };

  const startTimerForCloseAnimation = useCallback(() => {
    clearTimer();
    startFadeOutAnimationTimerRef.current = setTimeout(() => {
      setShouldStartDestroyAnimation(true);
    }, timeInMilliSecondsToStartTheDestroyAnimation);
  }, []);

  useEffect(() => {
    // display new toast only in desktop or in mobile when the keyboard is close or open on the chat bar field -to prevent from toast to appear while the keyboard is open on fields that is not the chat field in mobile
    const shouldDisplayNewToast =
      ((getItemFromLocalStorage<boolean>(isChatInputFocusedLocalStorageKey) ||
        !document.body.className.includes(bodyInputFocusedClassName)) &&
        isMobileView()) ||
      !isMobileView();
    if (shouldDisplayNewToast) {
      if (
        !botResponse.data?.messages?.length &&
        [updateTaskRes.status, tasksListResponse.status, botResponse.status].includes(
          EAPIStatus.REJECTED,
        )
      ) {
        setShouldDisplayMessage('error');
        setDataAttributeOnBody(dataAttributes.chatToastMessageDataType, 'error');
        startTimerForCloseAnimation();
      }
    }
    return () => clearTimer();
  }, [
    botResponse.data,
    updateTaskRes.status,
    tasksListResponse.status,
    botResponse.status,
    startTimerForCloseAnimation,
  ]);

  useEffect(() => {
    // if the plan tour was open and closed, and the toast is displayed start the timer for the close animation
    if (!isMiniPlanTourOpened && shouldDisplayMessage) startTimerForCloseAnimation();
  }, [isMiniPlanTourOpened]);

  useApiData(botResponse, {
    onFulfilled: (response) => {
      const shouldDisplayNewToast =
        ((getItemFromLocalStorage<boolean>(isChatInputFocusedLocalStorageKey) ||
          !document.body.className.includes(bodyInputFocusedClassName)) &&
          isMobileView()) ||
        !isMobileView();
      const hasValidMessage =
        !!response.messages?.length &&
        response.messages[response.messages.length - 1].party === 'Bot' &&
        response.messages[response.messages.length - 1].text.length > 0;
      if (shouldDisplayNewToast && hasValidMessage) {
        setToastMessage(
          transformResponseMessageToChatMessage(
            [botResponse.data!.messages[botResponse.data!.messages.length - 1]],
            null,
          )[0],
        );
        setShouldDisplayMessage('info');
        setDataAttributeOnBody(dataAttributes.chatToastMessageDataType, 'info');
        // don't start the timer for removing the toast message when the plan tour is open to show it after the plan tour closed
        if (!isMiniPlanTourOpened) startTimerForCloseAnimation();
      }
    },
  });

  const onClose = () => {
    setShouldDisplayMessage(null);
    dispatch(resetBotResponse());
    setShouldStartDestroyAnimation(false);
    clearTimer();
  };

  return (
    <>
      {!!shouldDisplayMessage && !isMiniPlanTourOpened && (
        <SwipeToCloseWrapper
          id="toast-messages-container"
          onCloseWhenSwipeOutEnd={onClose}
          swipeDirection={ECloseSwipeDirection.RIGHT_AND_LEFT}
          threshold={5}
          className={`toast-messages-container ${shouldStartDestroyAnimation ? 'fadeOut' : ''}`}
          onAnimationEnd={(animationData) => {
            if (animationData.animationName === 'fadeOutAnimation' && shouldStartDestroyAnimation)
              onClose();
          }}
        >
          {botResponse.status === EAPIStatus.PENDING ? (
            <Lottie
              animationData={messageLoadingAnimation}
              loop={true}
              className="lottie-message-animation lottie-message-animation-toast"
            />
          ) : (
            <div className="toast-messages-container-fade-container fadeIn">
              {(shouldDisplayMessage === 'error' ||
                (shouldDisplayMessage === 'info' &&
                  botResponse?.data &&
                  botResponse.data.messages?.length > 0)) && (
                <>
                  <button id="close-bot-toast" className="close-toast" onClick={onClose}>
                    <img src={closeImg} alt="close" />
                  </button>
                  {toastMessage && botResponse?.data && botResponse.data.messages?.length > 0 && (
                    <MessageWrapper
                      isToastMessage={true}
                      msgItem={toastMessage}
                      hideMsgTime={true}
                    />
                  )}
                  {shouldDisplayMessage === 'error' && (
                    <ErrorMessage
                      className={`${
                        botResponse.status === EAPIStatus.REJECTED ? 'bot-response-error' : ''
                      }`}
                      testId="stage-toast-reject-error"
                    />
                  )}
                </>
              )}
            </div>
          )}
        </SwipeToCloseWrapper>
      )}
    </>
  );
};

export default ToastMessages;
