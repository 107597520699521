import type { FunctionComponent } from 'react';
import { UserAvatarHeader } from './user-avatar/UserAvatarHeader';
import { ToggleHistoryHeaderIcon } from './toggle-history-icon/ToggleHistoryHeaderIcon';
import { StageTasksDropdownFilters } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import './Header.scss';
import { PlanUnscheduledFab } from '../../../../features/plan/plan-unscheduled-fab/PlanUnscheduledFab';
import { useSearchParams } from 'react-router-dom';
import {
  planHeaderActionsContainerClassName,
  planTourDataAttrChangeViewsMobile,
  planTourDataAttrPlannerMobile,
  stageParam,
} from '../../../../app/constants';
import { useTranslation } from 'react-i18next';
import { EAppStageContentType } from '../../../../features/chat-wrapper/resizable-container/stage-container/stageContainer.interfaces';
import { PlanViewSelectionDropdown } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-planner/plan-view-selection/PlanViewSelectionDropdown';
import { TimerShareButton } from '../../Timer/TimerShare/TimerShare';
import { isMobileView } from '../../../utils/utils';
import PlanTodayButton from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-planner/plan-today-button/PlanTodayButton';

export const Header: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const displayPlannerFabOrAvatar = () => {
    if (searchParams.get(stageParam) === EAppStageContentType.PLANNER) {
      return (
        <>
          <PlanViewSelectionDropdown data-plan-tour={planTourDataAttrChangeViewsMobile} />
          <div className={planHeaderActionsContainerClassName}>
            <PlanTodayButton />
            <PlanUnscheduledFab planTourDataAttr={planTourDataAttrPlannerMobile} />
          </div>
        </>
      );
    }
    return null;
  };

  const displayTasksHeaderContent = () => {
    if (searchParams.get(stageParam) === EAppStageContentType.TASKS) {
      return (
        <>
          <h1 className="header-title header-title--tasks">{t('stageMyTasksTitle')}</h1>
          <StageTasksDropdownFilters />
        </>
      );
    }
    return null;
  };

  const displayTimerHeaderContent = () => {
    if (searchParams.get(stageParam) === EAppStageContentType.TIMER) {
      return (
        <div className="timer-header-content">
          <h1 className="header-title">{t('stageTimerTitle')}</h1>
          {isMobileView() && <TimerShareButton className="timer-share" />}
        </div>
      );
    }
  };

  return (
    <header className="header-container">
      <div className="header-content">
        {displayTasksHeaderContent()}
        {displayTimerHeaderContent()}
        {displayPlannerFabOrAvatar()}
        <ToggleHistoryHeaderIcon />
        <UserAvatarHeader />
      </div>
    </header>
  );
};
