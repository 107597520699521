import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { createCssVhVariableByViewportHeight } from '../../utils/createCssVhVariableByViewportHeight';
import {
  bodyInputFocusedClassName,
  chatInputId,
  isChatInputFocusedLocalStorageKey,
  plannerModeOnLocalStorageKey,
  stageParam,
} from '../../../app/constants';
import { setItemInLocalStorage } from '../../utils/localStorage.utils';
import { ApplicationInsightsApi, ELogLabels } from '../../../application-insights';
import { useAppDispatch } from '../../../app/store';
import { isMobileDevice } from '../../utils/isMobileDevice';

import { scrollToChatBottom } from '../../../features/chat-wrapper/chat/Chat.utils';
import { EAppStageContentType } from '../../../features/chat-wrapper/resizable-container/stage-container/stageContainer.interfaces';
import {
  activePlannerUnscheduledTasksPlacer,
  resetPlannerState,
} from '../../../features/chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils';
import { clearTimerStorageData } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.store';

const AppEventListenerHandler = () => {
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const addToBodyFocusClassNameTimer = useRef<NodeJS.Timeout | null>(null);
  const scrollTopTouchendTimer = useRef<NodeJS.Timeout | null>(null);
  const lastKnownInputEventRef = useRef<string | null>(null);
  const bodyInputUnFocusedClassName = 'keyboard-close';
  const dispatch = useAppDispatch();
  const focusedInputElRef = useRef<HTMLElement | null>(null);
  const prevViewportHeightRef = useRef<number | null>(window.innerHeight);

  // 2 different function each event for different timers -> cant pass the timer as an argument because that we should to pass the reference of the function and cant pass an argument
  const handleFocusEventOnInputOrTextBox = useCallback((e: Event) => {
    handleBlurAndFocus('focus', e.target as HTMLElement);
  }, []);

  const handleBlurEventOnInputOrTextBox = useCallback((e: Event) => {
    handleBlurAndFocus('blur', e.target as HTMLElement);
  }, []);

  const triggerBlurEventOnFocusedInput = () => {
    const newViewportHeight = window.innerHeight;
    if (
      prevViewportHeightRef.current &&
      prevViewportHeightRef.current < newViewportHeight &&
      !!focusedInputElRef.current
    ) {
      focusedInputElRef.current.blur();
    }
    prevViewportHeightRef.current = newViewportHeight;
  };

  const handleBlurAndFocus = (eventType: 'focus' | 'blur', element: HTMLElement) => {
    if (addToBodyFocusClassNameTimer.current) clearTimeout(addToBodyFocusClassNameTimer.current);
    addToBodyFocusClassNameTimer.current = setTimeout(() => {
      if (lastKnownInputEventRef.current !== eventType) {
        if (eventType === 'focus') {
          document.body.classList.remove(bodyInputUnFocusedClassName);
          if (!document.body.className.includes(bodyInputFocusedClassName))
            document.body.classList.add(bodyInputFocusedClassName);
          if (element?.id === chatInputId)
            setItemInLocalStorage<boolean>(isChatInputFocusedLocalStorageKey, true);
          // scroll into the focused element
          let scrollDirection: ScrollLogicalPosition = 'center';
          if (
            !!document.querySelector('.toast-messages-container') &&
            !document.querySelector('.dislike-feedback-container')
          )
            scrollDirection = 'start';
          !!element && element?.scrollIntoView({ behavior: 'smooth', block: scrollDirection });
          focusedInputElRef.current = element;
        } else {
          if (!document.hidden || isMobileDevice()) {
            element.blur();
            document.body.classList.remove(bodyInputFocusedClassName);
            document.body.classList.add(bodyInputUnFocusedClassName);
            setItemInLocalStorage<boolean>(isChatInputFocusedLocalStorageKey, false);
            focusedInputElRef.current = null;
            scrollToChatBottom();
          }
        }
      }
      lastKnownInputEventRef.current = eventType;
    }, 300);
  };

  const windowScrollTopTouchend = useCallback(() => {
    if (scrollTopTouchendTimer.current) clearTimeout(scrollTopTouchendTimer.current);
    scrollTopTouchendTimer.current = setTimeout(() => {
      if (
        document.body.classList.contains(bodyInputFocusedClassName) ||
        !!document.querySelector('.app-overlay-popover-content-container')
      ) {
        window.scrollTo({
          top: 0,
        });
      }
    }, 400);
  }, []);

  const onChangeEventHandler = useCallback((e: any) => {
    try {
      const target = e.target;
      if (target?.nodeName === 'SELECT') {
        // the the option name
        const selectedOption =
          target?.options[target.value]?.text || 'unable to extract option name';
        ApplicationInsightsApi.trackEvent(ELogLabels.SELECT, { selectedOption });
      }
    } catch (error) {
      ApplicationInsightsApi.trackException(error);
    }
  }, []);

  const removeOnchangeEventHandler = () => {
    document.removeEventListener('change', onChangeEventHandler);
  };

  // when the virtual keyboard is opened scroll to top the window
  const addEventFocusOnTextBox = useCallback(
    (textBoxArr: (HTMLInputElement | HTMLTextAreaElement)[]) => {
      textBoxArr.forEach((item) => {
        item.addEventListener('focus', handleFocusEventOnInputOrTextBox);
        item.addEventListener('blur', handleBlurEventOnInputOrTextBox);
      });
    },
    [handleFocusEventOnInputOrTextBox, handleBlurEventOnInputOrTextBox],
  );

  const removeEventFocusFromTextBox = useCallback(
    (textBoxArr: (HTMLInputElement | HTMLTextAreaElement)[]) => {
      textBoxArr.forEach((item) => {
        item.removeEventListener('focus', handleFocusEventOnInputOrTextBox);
        item.removeEventListener('blur', handleBlurEventOnInputOrTextBox);
      });
    },
    [handleFocusEventOnInputOrTextBox, handleBlurEventOnInputOrTextBox],
  );

  useEffect(() => {
    const myInterval = setInterval(() => {
      const nodes = document.querySelectorAll(
        `input[type=text], input[type=number], textarea`,
      ) as unknown as (HTMLInputElement | HTMLTextAreaElement)[];
      if (nodes.length > 0 && !(window as any).isAxiosPending) {
        addAppEventListener(nodes);
        clearInterval(myInterval);
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAppEventListener = useCallback(
    (textboxElements: (HTMLInputElement | HTMLTextAreaElement)[]) => {
      addEventFocusOnTextBox(textboxElements);

      document.addEventListener('touchend', windowScrollTopTouchend);
      document.addEventListener('change', onChangeEventHandler);
    },
    [addEventFocusOnTextBox, windowScrollTopTouchend, onChangeEventHandler],
  );

  useEffect(() => {
    const keyboardTriggeredArr = document.querySelectorAll(
      `input[type=text], input[type=number], input[type=tel], input[type=email], textarea, [contenteditable=true]`,
    ) as unknown as (HTMLInputElement | HTMLTextAreaElement)[];
    addAppEventListener(keyboardTriggeredArr);
    return () => {
      if (addToBodyFocusClassNameTimer.current) clearTimeout(addToBodyFocusClassNameTimer.current);
      if (scrollTopTouchendTimer.current) clearTimeout(scrollTopTouchendTimer.current);
      removeEventFocusFromTextBox(keyboardTriggeredArr);
      document.removeEventListener('touchend', windowScrollTopTouchend);
      // reset lastKnownInputEventRef and body className for animation
      lastKnownInputEventRef.current = null;
      document.body.classList.remove(bodyInputFocusedClassName);
      document.body.classList.remove(bodyInputUnFocusedClassName);
      setItemInLocalStorage<boolean>(isChatInputFocusedLocalStorageKey, false);
    };
  }, [
    addEventFocusOnTextBox,
    location,
    params,
    removeEventFocusFromTextBox,
    searchParams,
    windowScrollTopTouchend,
    addAppEventListener,
  ]);

  useEffect(() => {
    createCssVhVariableByViewportHeight();
    addWindowResizeEvent();
    return () => {
      removeWindowResizeEvent();
      removeOnchangeEventHandler();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // responsible for managing the planner stages while moving between tabs or reloading the page
  useEffect(() => {
    const stageContent = searchParams.get(stageParam) as EAppStageContentType;
    switch (stageContent) {
      case EAppStageContentType.PLANNER:
        clearTimerStorageData();
        if (plannerModeOnLocalStorageKey in localStorage) {
          activePlannerUnscheduledTasksPlacer();
        }
        break;
      case EAppStageContentType.TASKS:
        clearTimerStorageData();
        break;
      default:
        resetPlannerState();
        break;
    }
  }, [searchParams, dispatch]);

  const addWindowResizeEvent = () => {
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', createCssVhVariableByViewportHeight);
    } else {
      window.addEventListener('resize', createCssVhVariableByViewportHeight);
    }
  };

  const removeWindowResizeEvent = () => {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', createCssVhVariableByViewportHeight);
    } else {
      window.removeEventListener('resize', createCssVhVariableByViewportHeight);
    }
  };

  // trigger the blur event on the focused input when the window is resized while the keyboard is closing by the keyboard closing button on mobile devices
  useEffect(() => {
    if (isMobileDevice()) {
      const handleResize = () => {
        triggerBlurEventOnFocusedInput();
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [dispatch]);

  return <></>;
};
export default AppEventListenerHandler;
