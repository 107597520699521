import { FunctionComponent, memo } from 'react';
import './AppCheckbox.scss';

interface IAppCheckboxProps {
  className?: string;
  shouldDisabled?: boolean;
  isChecked?: boolean;
  checkboxSize?: string;
  checkMarkSize?: string;
  chackboxColor?: string;
  checkboxBorderWidth?: string;
  marginRightSpace?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AppCheckbox: FunctionComponent<IAppCheckboxProps> = ({
  className = '',
  shouldDisabled = false,
  isChecked = false,
  checkboxSize = '10px',
  checkMarkSize = '10px',
  checkboxBorderWidth = '3px',
  marginRightSpace = '0px',
  chackboxColor,
  onChange,
}) => {
  return (
    <label
      className={`app-checkbox ${className}`}
      style={
        {
          // CSS variables that set the checkbox style.
          '--checkboxColor': chackboxColor,
          '--checkboxSize': checkboxSize,
          '--checkMarkSize': checkMarkSize,
          '--checkboxBorderWidth': checkboxBorderWidth,
          '--marginRightSpace': marginRightSpace,
        } as React.CSSProperties
      }
    >
      <input
        className="complete-checkbox"
        type="checkbox"
        checked={isChecked}
        disabled={shouldDisabled}
        onChange={onChange}
      />
    </label>
  );
};

export default memo(AppCheckbox);
