import AppButton from '../../app-button/AppButton';
import XCloseIcon from '../../icons/XCloseIcon';
import { ReactComponent as ResetSvgComponent } from '../../../../assets/images/reset-timer-icon.svg';
import { ReactComponent as StartSvgComponent } from '../../../..//assets/images/start-timer-icon.svg';
import { ReactComponent as PauseSvgComponent } from '../../../..//assets/images/pause-timer-icon.svg';
import { ETimerStatus } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.interface';
import createAppOverlayPopover from '../../app-overlay-popover/createAppOverlayPopover';
import { TimerCloseToast } from '../../../../features/chat-wrapper/chat/popups/TimerCloseToast';
import './TimerControls.scss';
import {
  activeTimerIdLocalStorageKey,
  deleteTimerAction,
  modifyTimerReqAction,
  pauseTimerReqAction,
  resetTimerReqAction,
  startTimerReqAction,
  timerTaskLocalStorageKey,
} from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.store';
import { ApplicationInsightsApi } from '../../../../application-insights';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { EAPIStatus } from '../../../api/models';
import { removeItemFromLocalStorage } from '../../../utils/localStorage.utils';

interface TimerControlsProps {
  localRemainingTime: number;
}

const TimerControls: React.FC<TimerControlsProps> = ({ localRemainingTime }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeTimer, pauseTimerRes, startTimerRes, modifyTimerRes, resetTimerRes } =
    useAppSelector((store) => store.StageTimerReducer);
  const dispatch = useAppDispatch();
  const isCompleted = localRemainingTime <= 0;

  const handleCloseTimerToast = () => {
    if (activeTimer?.id) dispatch(deleteTimerAction({ timerId: activeTimer.id }));
    removeItemFromLocalStorage(activeTimerIdLocalStorageKey);
    removeItemFromLocalStorage(timerTaskLocalStorageKey);
    setSearchParams({ stageContent: searchParams.get('from') || '' });
  };

  const handleAddTime = async (minutes: number) => {
    if (!activeTimer?.id || modifyTimerRes.status === EAPIStatus.PENDING) return;

    dispatch(
      modifyTimerReqAction({
        timerId: activeTimer.id,
        secondsToAdd: minutes * 60,
      }),
    )
      .unwrap()
      .catch((error: Error) => {
        ApplicationInsightsApi.trackException(error);
      });
  };

  const handleReset = async () => {
    if (!activeTimer?.id || resetTimerRes.status === EAPIStatus.PENDING) return;
    dispatch(resetTimerReqAction({ timerId: activeTimer.id }))
      .unwrap()
      .catch((error: Error) => {
        ApplicationInsightsApi.trackException(error);
      });
  };

  const handlePause = async () => {
    if (!activeTimer?.id || pauseTimerRes.status === EAPIStatus.PENDING) return;
    dispatch(pauseTimerReqAction({ timerId: activeTimer.id }))
      .unwrap()
      .catch((error: Error) => {
        ApplicationInsightsApi.trackException(error);
      });
  };

  const handleStart = async () => {
    if (!activeTimer?.id || startTimerRes.status === EAPIStatus.PENDING) return;
    dispatch(startTimerReqAction({ timerId: activeTimer.id }))
      .unwrap()
      .catch((error: Error) => {
        ApplicationInsightsApi.trackException(error);
      });
  };

  const handleClose = () => {
    // Pause the timer first
    const isTimerRunning = activeTimer?.status === ETimerStatus.RUNNING;
    if (isTimerRunning) {
      handlePause();
    }
    const shouldOpenConfirmation = isTimerRunning && localRemainingTime > 0;
    if (!shouldOpenConfirmation) {
      handleCloseTimerToast();
      return;
    }
    createAppOverlayPopover(
      <TimerCloseToast onClose={handleCloseTimerToast} />,
      null,
      null,
      undefined,
      { isAppToast: true },
    );
  };

  // Render timer controls
  return (
    <div className="stage-timer-controls">
      <AppButton className="stage-timer-button" onClick={handleClose} id="close-timer-button">
        <XCloseIcon className="stage-timer-button-icon" />
      </AppButton>
      <AppButton className="stage-timer-button" onClick={handleReset} id="reset-timer-button">
        <ResetSvgComponent className="reset-timer-icon stage-timer-button-icon" />
      </AppButton>
      <AppButton
        className="stage-timer-button"
        onClick={() => handleAddTime(5)}
        id="add-time-timer-button"
      >
        +5
      </AppButton>
      <AppButton
        className="stage-timer-button"
        onClick={() => handleAddTime(10)}
        id="add-time-timer-button"
      >
        +10
      </AppButton>
      <AppButton
        className="stage-timer-button start-pause"
        onClick={activeTimer?.status === ETimerStatus.RUNNING ? handlePause : handleStart}
        id="start-pause-timer-button"
      >
        {activeTimer?.status === ETimerStatus.RUNNING ? (
          <PauseSvgComponent className="pause-timer-icon" />
        ) : (
          <StartSvgComponent className="start-timer-icon" />
        )}
      </AppButton>
    </div>
  );
};

export default TimerControls;
