import React from 'react';
import type { EClientFlags } from '../content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { FlagUtils } from '../../utils/flagUtils';
import { updateUserProfileReq } from '../../../app/useAppData/user.store';
import { useTranslation } from 'react-i18next';
import './AppPopUpToast.scss';
import { openExternalLinkOnMobileApp } from '../../../mobile-application-utils';
import { useSwipeToClose } from '../../hooks/swipe-hooks/useSwipeToClose';
import { ECloseSwipeDirection } from '../../hooks/swipe-hooks/swipe.utils';
import AppButton from '../app-button/AppButton';
import XCloseIcon from '../icons/XCloseIcon';

export interface IAppPopUpToastProps {
  id: string;
  body?: string;
  title?: string;
  CTA_Text?: string; // text of the link action
  CTA_Href?: string; // href of the link action
  CTA_action?: () => void; // action of the link action
  dontShowAgainFlag?: EClientFlags;
  dontShowAgainBtnText?: string;
  onClose?: () => void;
}

const AppPopUpToast: React.FC<IAppPopUpToastProps> = ({
  body,
  title,
  CTA_Href,
  CTA_Text,
  CTA_action,
  dontShowAgainFlag,
  id,
  onClose,
  dontShowAgainBtnText,
}) => {
  const { user } = useAppSelector((store) => store.userReducer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { handleTouchStart, handleTouchMove } = useSwipeToClose({
    swipeDirection: ECloseSwipeDirection.RIGHT_AND_LEFT,
    onSwipeMove: () => !!onClose && onClose(),
    shouldCalculateTranslate: false,
    threshold: 30,
  });

  const handleDontShowAgain = () => {
    if (!!dontShowAgainFlag && (!!user?.clientFlags || user?.clientFlags === 0)) {
      const updatedClientFlags = FlagUtils.setFlag(user.clientFlags, dontShowAgainFlag);
      dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }));
    }
    onCloseToast();
  };

  const onCloseToast = () => {
    !!onClose && onClose();
  };

  return (
    <div
      className="app-pop-up-toast"
      id={id}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="app-pop-up-toast--header">
        <h3 className="app-pop-up-toast--title">{t(title || body || '')}</h3>
        <AppButton id={`close-${id}`} className="app-pop-up-toast--close" onClick={onCloseToast}>
          <XCloseIcon className="app-pop-up-toast-close-icon" />
        </AppButton>
      </div>
      {title && body && <p className="app-pop-up-toast--body">{t(body)}</p>}
      <div className="app-pop-up-toast--actions">
        {(!!dontShowAgainFlag || dontShowAgainBtnText) && (
          <AppButton
            onClick={(dontShowAgainFlag && handleDontShowAgain) || onCloseToast}
            id={`dont-show-again-${id}`}
            className="app-pop-up-toast--actions--dont-show-again"
          >
            {t(dontShowAgainBtnText || 'appPopUpToastDontShowAgainText')}
          </AppButton>
        )}
        {(!!CTA_Href || CTA_Text) && (
          <a
            id={`CTA-${id}`}
            onClick={(e) => {
              if (!CTA_Href) {
                e.preventDefault();
                if (CTA_action) CTA_action();
                return;
              }
              openExternalLinkOnMobileApp(e, CTA_Href);
              if (CTA_action) CTA_action();
              onCloseToast();
            }}
            className="app-pop-up-toast--actions--cta"
            href={CTA_Href}
            aria-label={id + '-CTA'}
            target="_blank"
            rel="noreferrer"
          >
            {t(CTA_Text || '')}
          </a>
        )}
      </div>
    </div>
  );
};

export default AppPopUpToast;
