import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../app/store';
import type { IMessageDataTask } from './stageTasks.interface';
import { ETaskStatus } from './stageTasks.interface';
import { StageTasksEmptyState } from './stage-tasks-emty-state/StageTasksEmptyState';
import { shouldDisplayOnTaskList, sortingTasksByCreationTime } from './TasksList.utils';
import { Fragment, useEffect, useState } from 'react';
import AddTaskField from '../add-task-field/AddTaskField';
import './StageTasks.scss';
import MainTaskWithSubtasks from './main-task-with-subtasks/MainTaskWithSubtasks';
import SelectedFilters from './selected-filters/SelectedFilters';

export const StageTasks = () => {
  const { tasksWithoutRecurrences } = useAppSelector((store) => store.StageTasksReducer);
  const { shouldDisplayProductTour } = useAppSelector((store) => store.sharedStoreReducer);
  const [localTaskList, setLocalTaskList] = useState<IMessageDataTask[]>(tasksWithoutRecurrences);
  const { t } = useTranslation();

  const getTasksOnTaskList = () => {
    return localTaskList
      .filter((item) => shouldDisplayOnTaskList(item))
      .sort((a, b) => sortingTasksByCreationTime(a, b));
  };

  const updateLocalList = (newTask: IMessageDataTask) => {
    setLocalTaskList((prev) => {
      return [newTask, ...prev];
    });
  };

  useEffect(() => {
    setLocalTaskList(tasksWithoutRecurrences);
  }, [tasksWithoutRecurrences]);

  return (
    <div className="stage-child-component stage-tasks-container">
      <SelectedFilters />
      <div className="scrollable-container">
        {[...getTasksOnTaskList()].map((task) => (
          <Fragment key={task.id}>
            <MainTaskWithSubtasks mainTask={task} />
          </Fragment>
        ))}
        {!shouldDisplayProductTour && <StageTasksEmptyState />}
        <AddTaskField
          taskStatus={ETaskStatus.NOT_STARTED}
          placeholder={t('addTaskInputPlaceholderText')}
          onChange={(newTask) => updateLocalList(newTask)}
          className="add-main-task-input"
          shouldConsiderFilters={true}
        />
      </div>
    </div>
  );
};
