import React from 'react';
import { ReactComponent as chatActiveMobileIconComponent } from '../../../../assets/images/navbar/mobile-icons/navbar-active-chat-mobile.svg';
import { ReactComponent as tasksActiveMobileIconComponent } from '../../../../assets/images/navbar/mobile-icons/navbar-active-tasks-mobile.svg';
import { ReactComponent as planActiveMobileIconComponent } from '../../../../assets/images/navbar/mobile-icons/navbar-active-plan-mobile.svg';
import { ReactComponent as chatActiveDesktopIconComponent } from '../../../../assets/images/navbar/desktop-icons/navbar-active-chat-desktop.svg';
import { ReactComponent as tasksActiveDesktopIconComponent } from '../../../../assets/images/navbar/desktop-icons/navbar-active-tasks-desktop.svg';
import { ReactComponent as planActiveDesktopIconComponent } from '../../../../assets/images/navbar/desktop-icons/navbar-active-plan-desktop.svg';
import { EAppStageContentType } from '../stage-container/stageContainer.interfaces';
import { NavButton } from './NavButton';
import { useSearchParams } from 'react-router-dom';
import { Avatar } from '../../../../shared/components/avatar/Avatar';
import {
  productTourDataAttrChat,
  productTourDataAttrPlan,
  productTourDataAttrTasks,
  productTourDataAttrUserAvatarDesk,
  settingsMenuParam,
} from '../../../../app/constants';
import { getInitials } from '../../../../shared/utils/getInitials';
import { useAppSelector } from '../../../../app/store';
import { useTranslation } from 'react-i18next';
import './Navbar.scss';
import { isDesktopView } from '../../../../shared/utils/utils';
import AppButton from '../../../../shared/components/app-button/AppButton';
import { EAppThemes, getThemeByDocumentDataThemeAttr } from '../../../../shared/utils/themes.utils';

export const Navbar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user: userInfo } = useAppSelector((store) => store.userReducer);
  const { allTasks } = useAppSelector((store) => store.StageTasksReducer);
  const { t } = useTranslation();
  const isDesktopViewScreen = isDesktopView();
  const isDarkMode = getThemeByDocumentDataThemeAttr() === EAppThemes.DARK;

  const onOpenSettingsMenu = () => {
    if (!searchParams.get(settingsMenuParam)) {
      setSearchParams((prev) => {
        prev.append(settingsMenuParam, 'open');
        return prev;
      });
    }
  };

  return (
    <div className="navbar-container">
      <div className="action-buttons">
        <div className="navbar-max-title --desktop">{t('max')}</div>
        <NavButton
          className="chat-btn"
          type={EAppStageContentType.CHAT}
          title={t('navbarChatTitle')}
          SvgComponent={
            isDesktopViewScreen || isDarkMode
              ? chatActiveDesktopIconComponent
              : chatActiveMobileIconComponent
          }
          productTourDataAttr={productTourDataAttrChat}
        />
        <NavButton
          className="tasks-btn"
          type={EAppStageContentType.TASKS}
          title={t('navbarTasksTitle')}
          SvgComponent={
            isDesktopViewScreen || isDarkMode
              ? tasksActiveDesktopIconComponent
              : tasksActiveMobileIconComponent
          }
          productTourDataAttr={productTourDataAttrTasks}
        />
        <NavButton
          className="plan-btn"
          type={EAppStageContentType.PLANNER}
          title={t('navbarPlanTitle')}
          SvgComponent={
            isDesktopViewScreen || isDarkMode
              ? planActiveDesktopIconComponent
              : planActiveMobileIconComponent
          }
          productTourDataAttr={productTourDataAttrPlan}
        />
      </div>
      <AppButton
        id="navbar-user-avatar"
        className="user-avatar"
        data-product-tour={productTourDataAttrUserAvatarDesk}
        onClick={onOpenSettingsMenu}
      >
        <Avatar
          className="navbar-avatar"
          defaultAvatarClassName="default-header-avatar"
          size={44}
          avatarImg={userInfo?.profileImage || ''}
          defaultContent={getInitials(userInfo?.name || userInfo?.email || '')}
        />
      </AppButton>
    </div>
  );
};
