import { createAction, createReducer } from '@reduxjs/toolkit';
import { apiServiceCSharp, createApiThunk } from '../../shared/api/axios';
import type { IAPIRequestState } from '../../shared/api/models';
import { APIRequestState } from '../../shared/api/models';
import type { ApiDataStateType } from '../../shared/store/utils';
import { createAPIReducerCases } from '../../shared/store/utils';
import { API_ROUTES, registrationParametersStorageKey } from '../constants';
import type { ILinkAccountsResponse, IUserAppData } from './user.interfaces';
import type {
  IRegisterPayload,
  IRegisterPhoneNumberRequestPayload,
  IUser,
  IVerifyOtpPayload,
  IVerifyOtpResponse,
} from '../auth/auth.interfaces';
import type {
  IAppleCalendarSyncReqPayload,
  IiCalUpdateReqPayload,
  IUpdatePreferredNameReqPayload,
  IUserProfileReqPayload,
} from '../../shared/components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';

export interface IUserStore extends ApiDataStateType {
  user: IUser | null;
  registrationRes: IAPIRequestState<IUser>;
  verifyOtpRes: IAPIRequestState<IVerifyOtpResponse>;
  getUserProfile: IAPIRequestState<IUser>;
  updateUserProfile: IAPIRequestState<IUser>;
  updatePhoneNumberRes: IAPIRequestState<IUser>;
  updatePreferredName: IAPIRequestState<IUser>;
  userAppData: IAPIRequestState<IUserAppData>;
  iCalUpdateRequest: IAPIRequestState<IUser>;
  linkAccounts: IAPIRequestState<ILinkAccountsResponse>;
  appleCalendarSyncRequest: IAPIRequestState<IUser>;
}

const initialStateUserStore: IUserStore = {
  user: null,
  registrationRes: APIRequestState.create(),
  verifyOtpRes: APIRequestState.create(),
  getUserProfile: APIRequestState.create(),
  updateUserProfile: APIRequestState.create(),
  updatePhoneNumberRes: APIRequestState.create(),
  updatePreferredName: APIRequestState.create(),
  userAppData: APIRequestState.create(),
  iCalUpdateRequest: APIRequestState.create(),
  linkAccounts: APIRequestState.create(),
  appleCalendarSyncRequest: APIRequestState.create(),
};

export interface LinkAccountsPayload {
  newUserPhoneNumber: string;
}

const createReducerKey = (subKey: string): string => {
  return 'user/' + subKey;
};

export const registrationReqAction = createApiThunk(
  createReducerKey('registrationReqAction'),
  (payload?: IRegisterPayload) => apiServiceCSharp.post<IUser>(API_ROUTES.AUTH.REGISTER, payload),
);

export const verifyOtpReqAction = createApiThunk(
  createReducerKey('verifyOtpReqAction'),
  (payload?: IVerifyOtpPayload) =>
    apiServiceCSharp.post<IVerifyOtpResponse>(API_ROUTES.AUTH.VERIFY_OTP, payload),
);

export const getUserProfileReq = createApiThunk(createReducerKey('getUserProfileReq'), () =>
  apiServiceCSharp.get<IUser>(API_ROUTES.SETTINGS.USER_PROFILE),
);

export const updateUserProfileReq = createApiThunk(
  createReducerKey('updateUserProfileReq'),
  (reqPayload?: IUserProfileReqPayload) =>
    apiServiceCSharp.patch<IUser>(API_ROUTES.USER.UPDATE_USER_INFO, reqPayload),
);

export const updatePreferredNameReq = createApiThunk(
  createReducerKey('updatePreferredNameReq'),
  (reqPayload?: IUpdatePreferredNameReqPayload) =>
    apiServiceCSharp.patch<IUser>(API_ROUTES.USER.UPDATE_USER_INFO, reqPayload),
);

export const updatePhoneNumberReqAction = createApiThunk(
  createReducerKey('updatePhoneNumberReqAction'),
  (payload?: IRegisterPhoneNumberRequestPayload) =>
    apiServiceCSharp.patch<IUser>(API_ROUTES.USER.UPDATE_USER_INFO, payload),
);

export const getUserAppData = createApiThunk(createReducerKey('getUserAppData'), () =>
  apiServiceCSharp.get<IUserAppData>(API_ROUTES.USER.USER_APP_DATA),
);

export const iCalUpdateRequestAction = createApiThunk(
  createReducerKey('iCalUpdateRequest'),
  (reqPayload?: IiCalUpdateReqPayload) =>
    apiServiceCSharp.post<IUser>(API_ROUTES.AUTH.ICAL_CALENDAR_LOGIN, reqPayload),
);

export const appleCalendarSyncRequestAction = createApiThunk(
  createReducerKey('appleCalendarSyncRequest'),
  (reqPayload?: IAppleCalendarSyncReqPayload) =>
    apiServiceCSharp.post<IUser>(API_ROUTES.AUTH.APPLE_CALENDAR_LOGIN, reqPayload),
);

export const linkAccountsReqAction = createApiThunk(
  createReducerKey('linkAccountsReqAction'),
  (payload?: LinkAccountsPayload) =>
    apiServiceCSharp.post<ILinkAccountsResponse>(API_ROUTES.USER.LINK_ACCOUNTS, payload),
);

export const setUser = createAction<IUser>(createReducerKey('setUser'));

export const userReducer = createReducer(initialStateUserStore, (builder) => {
  createAPIReducerCases(registrationReqAction, 'registrationRes', builder, {
    onFulfilled(state, payload) {
      if (payload.id) {
        state.user = payload;
        localStorage.removeItem(registrationParametersStorageKey);
      }
    },
  });
  createAPIReducerCases(getUserProfileReq, 'getUserProfile', builder, {
    onFulfilled(state, payload) {
      if (payload.id) {
        state.user = payload;
      }
    },
  });
  createAPIReducerCases(updateUserProfileReq, 'updateUserProfile', builder, {
    onFulfilled: (state, payload) => {
      if (payload.id) {
        state.user = payload;
      }
    },
  });
  createAPIReducerCases(updatePreferredNameReq, 'updatePreferredName', builder, {
    onFulfilled(state, payload) {
      if (payload.id) {
        state.user = payload;
      }
    },
  });
  createAPIReducerCases(verifyOtpReqAction, 'verifyOtpRes', builder, {
    onFulfilled(state, payload) {
      if (payload.user) {
        state.user = payload.user;
      }
    },
  });
  createAPIReducerCases(updatePhoneNumberReqAction, 'updatePhoneNumberRes', builder, {
    onFulfilled(state, payload) {
      if (payload.id) {
        state.user = payload;
      }
    },
  });
  createAPIReducerCases(getUserAppData, 'userAppData', builder, {
    onFulfilled(state, payload) {
      if (payload.user) {
        state.user = payload.user;
      }
    },
  });
  createAPIReducerCases(iCalUpdateRequestAction, 'iCalUpdateRequest', builder, {
    onFulfilled(state, payload) {
      if (payload.id) {
        state.user = payload;
      }
    },
  });
  createAPIReducerCases(appleCalendarSyncRequestAction, 'appleCalendarSyncRequest', builder, {
    onFulfilled(state, payload) {
      if (payload.id) {
        state.user = payload;
      }
    },
  });
  createAPIReducerCases(linkAccountsReqAction, 'linkAccounts', builder, {
    onFulfilled(state, payload) {
      if (payload.user) {
        state.user = payload.user;
      }
    },
  });
  builder.addCase(setUser, (state, action) => {
    state.user = action.payload;
  });
});
