import { useState, useEffect, useRef } from 'react';
import './StageTimerShare.scss';
import { TimerShareButton } from '../../../../../../shared/components/Timer/TimerShare/TimerShare';

export const StageTimerShare = ({ ...attributes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // TODO: add share timer functionality

  useEffect(() => {
    const handleClickOutside = () => {
      if (dropdownRef.current && dropdownRef.current.clientHeight > 0) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className={`stage-timer-share ${isOpen ? 'open' : ''} `} {...attributes}>
      <TimerShareButton />
    </div>
  );
};
