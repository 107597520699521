import { ECalendarSyncType } from './app/auth/auth.interfaces';
import { ApplicationInsightsApi } from './application-insights';
import { EAppThemes } from './shared/utils/themes.utils';

export enum EMobileAppMessage {
  TOKEN = 1,
  OPEN_URL = 2,
  PUSH_NOTIFICATIONS_PERMISSION,
  APPLE_CALENDAR_SYNC,
  THEME_CHANGE,
}

export interface IMobileAppMessage {
  type: EMobileAppMessage;
  data: unknown;
}

export interface IMobileAppRefreshTokenMessageData {
  token: string;
  refreshToken: string;
}

export interface IMobileAppCalendarSyncMessageData {
  syncType?: ECalendarSyncType | 0; // 0 to remove the calendar sync
}

// mobile app webview injects messageHandler to the window object
// on ios devices, the handler is on window.webkit.messageHandlers.messageHandler
const sendMessageToMobileApp = (message: string) => {
  try {
    const currentWindow = window as any;
    if (!currentWindow || (!currentWindow.messageHandler && !currentWindow.webkit)) {
      return;
    }
    ApplicationInsightsApi.trackTrace(
      'sendMessageToMobileApp - sending a message to the mobile app',
      { message },
    );
    if (currentWindow.messageHandler) {
      currentWindow.messageHandler.postMessage(message);
    } else if (
      currentWindow.webkit &&
      currentWindow.webkit.messageHandlers &&
      currentWindow.webkit.messageHandlers.messageHandler
    ) {
      currentWindow.webkit.messageHandler.messageHandler.postMessage(message);
    }
  } catch (e) {
    ApplicationInsightsApi.trackException(e);
  }
};

export const sendRefreshTokenToMobileApp = (data: IMobileAppRefreshTokenMessageData) => {
  const message = createMobileAppRequestPayload(data, EMobileAppMessage.TOKEN);
  if (!message) return;
  sendMessageToMobileApp(message);
};

export const sendRequestOpenUrlToMobileApp = (url: string) => {
  const message = createMobileAppRequestPayload(url, EMobileAppMessage.OPEN_URL);
  if (!message) return;
  sendMessageToMobileApp(message);
};

export const sendPushNotificationsPermissionRequestToMobileApp = (enablePermission: boolean) => {
  const message = createMobileAppRequestPayload(
    enablePermission,
    EMobileAppMessage.PUSH_NOTIFICATIONS_PERMISSION,
  );
  if (!message) return;
  sendMessageToMobileApp(message);
};

export const sendAppleCalendarSyncRequestToMobileApp = (
  data: IMobileAppCalendarSyncMessageData,
) => {
  const message = createMobileAppRequestPayload(data, EMobileAppMessage.APPLE_CALENDAR_SYNC);
  if (!message) return;
  sendMessageToMobileApp(message);
};

export const sendThemeChangeRequestToMobileApp = (theme: EAppThemes) => {
  const message = createMobileAppRequestPayload(theme, EMobileAppMessage.THEME_CHANGE);
  if (!message) return;
  sendMessageToMobileApp(message);
};

export const createMobileAppRequestPayload = (
  data: unknown,
  type: EMobileAppMessage,
): string | null => {
  try {
    if (data == null || data === undefined) {
      return null;
    }

    const message: IMobileAppMessage = {
      type,
      data,
    };
    return JSON.stringify(message);
  } catch (e) {
    ApplicationInsightsApi.trackException(e);
    return null;
  }
};

export const isMobileAppWebView = (): boolean => {
  const currentWindow = window as any;
  if (!!currentWindow?.messageHandler || !!currentWindow?.webkit?.messageHandlers?.messageHandler) {
    return true;
  }
  return false;
};

export const openExternalLinkOnMobileApp = (
  e: React.MouseEvent<HTMLElement>,
  url: string | null | undefined,
) => {
  try {
    if (!isMobileAppWebView() || !url) return;
    e.preventDefault();
    sendRequestOpenUrlToMobileApp(url);
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
};
