import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { store, useAppDispatch, useAppSelector } from '../../../app/store';
import { isMobileDevice } from '../../../shared/utils/isMobileDevice';
import { isDesktopView, isMobileView } from '../../../shared/utils/utils';
import {
  EPlannerMode,
  setPlannerCurrentTaskPlacement,
} from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import './PlanTasksPlacementList.scss';
import { resetPlannerState } from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils';
import type { ITaskUpdateReqPayload } from '../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyStateImg } from '../../../assets/images/mascot/mascot-chart.svg';
import removeAppOverlayPopover from '../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { getDateWithoutYear, getShortDateWithDuration } from '../../../shared/utils/dateFormat';
import { getItemFromLocalStorage } from '../../../shared/utils/localStorage.utils';
import {
  chatSessionIdLocalStorageKey,
  timePickerContextOverlayDefaultWidth,
} from '../../../app/constants';
import {
  getTasksListReqAction,
  updateTaskReqAction,
} from '../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { EAPIStatus } from '../../../shared/api/models';
import { PlanTaskPlacementSetButton } from './PlanTaskPlacementSetButton';
import { filterForUnscheduledTasks } from '../plan.utils';
import AppEmptyState from '../../../shared/components/app-empty-state/AppEmptyState';
import PlannerTask from './PlannerTask';
import createAppOverlayPopover from '../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TimePickerContext } from '../../chat-wrapper/resizable-container/stage-container/stage-tasks/task-edit-overlays/time-picker-context/TimePickerContext';
import SassVariables from '../../../styles/style.module.scss';
import SwipeToCloseWrapper from '../../../shared/components/swipe-to-close-wrapper/SwipeToCloseWrapper';
import { ECloseSwipeDirection } from '../../../shared/hooks/swipe-hooks/swipe.utils';
import { EPlanViewSelection } from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface';
import AppButton from '../../../shared/components/app-button/AppButton';
import BackArrowIcon from '../../../shared/components/icons/back-arrow-icon/BackArrowIcon';

enum EPlanTasksPlacementListMode {
  FULL = 'full',
  CLOSED = 'closed',
  PLACING = 'placing',
}

export const PlanTasksPlacementList = () => {
  const mainLayoutClass = 'plan-tasks-placement-list-container';
  const devicePrefix = isMobileDevice() || isMobileView() ? 'mobile' : 'desktop';
  const deviceClass = `${mainLayoutClass}--${devicePrefix}`;
  const { t } = useTranslation();
  const { plannerMode, currentTaskPlacement, planView } = useAppSelector(
    (store) => store.StagePlannerReducer,
  );
  const { allTasks, updateTaskRes, tasksListResponse } = useAppSelector(
    (store) => store.StageTasksReducer,
  );
  const [mode, setMode] = useState<EPlanTasksPlacementListMode | null>(
    EPlanTasksPlacementListMode.CLOSED,
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    setMode(
      plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER
        ? EPlanTasksPlacementListMode.FULL
        : EPlanTasksPlacementListMode.CLOSED,
    );
  }, [plannerMode]);

  const getSelectedTimeStrToDisplay = () => {
    if (!!currentTaskPlacement?.workBlockId && !!currentTaskPlacement?.workBlockStartTime) {
      return `${
        currentTaskPlacement.workBlockName || t('workBlockDefaultName')
      } ${getDateWithoutYear(currentTaskPlacement?.workBlockStartTime)}`;
    } else if (currentTaskPlacement?.workTime) {
      return `${getShortDateWithDuration(
        currentTaskPlacement?.workTime,
        currentTaskPlacement?.duration,
      )}`;
    }
    return '';
  };

  const onCleanCurrentTaskPlacement = () => {
    removeAppOverlayPopover();
    dispatch(setPlannerCurrentTaskPlacement(null));
  };

  useEffect(() => {
    if (plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER) {
      if (currentTaskPlacement) {
        setMode(EPlanTasksPlacementListMode.PLACING);
        // remove currentTaskPlacement if exist onDesktopView when planView is not MY_PLAN
        if (isDesktopView() && planView !== EPlanViewSelection.MY_PLAN)
          onCleanCurrentTaskPlacement();
        else if (isDesktopView() && !isMobileDevice()) {
          createAppOverlayPopover(
            <TimePickerContext
              taskName={currentTaskPlacement?.name || ''}
              selectedTimeStr={getSelectedTimeStrToDisplay()}
              onClose={() => onCleanCurrentTaskPlacement()}
              onSet={<PlanTaskPlacementSetButton onSet={onSetUnscheduledWorkTime} />}
            />,
            null,
            null,
            {
              bottom: `calc(${SassVariables.overlayDesktopBottomHeight} - 20px)`,
              left: 'unset',
              right: 50,
              width: `${timePickerContextOverlayDefaultWidth}px`,
            },
            {
              isCustomStyle: true,
              allowInteraction: true,
              closeOnClickOutside: false,
            },
          );
        }
      } else {
        if (isDesktopView() && !isMobileDevice()) {
          removeAppOverlayPopover();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaskPlacement, planView]);

  const onClose = useCallback(() => {
    resetPlannerState(true);
    removeAppOverlayPopover();
  }, []);

  const handleBackToList = () => {
    dispatch(setPlannerCurrentTaskPlacement(null));
    setMode(EPlanTasksPlacementListMode.FULL);
  };

  const onSetUnscheduledWorkTime = () => {
    if (
      tasksListResponse.status === EAPIStatus.PENDING ||
      updateTaskRes.status === EAPIStatus.PENDING
    )
      return;
    const placedTask = store.getState().StagePlannerReducer.currentTaskPlacement;
    const payload: ITaskUpdateReqPayload = {
      sessionId: getItemFromLocalStorage(chatSessionIdLocalStorageKey) || '',
      id: placedTask?.id || '',
      workTime: placedTask?.workBlockId ? null : new Date(placedTask?.workTime!).toISOString(),
      workBlockId: placedTask?.workBlockId || null,
      workBlockInstance: placedTask?.workBlockInstance || null,
      duration: placedTask?.duration || null,
    };
    dispatch(updateTaskReqAction(payload)).then(() => {
      dispatch(getTasksListReqAction()).then(() => {
        handleBackToList();
      });
    });
  };

  const currentTaskContext = () => {
    return (
      <div className="plan-tasks-placement-current-task-container">
        <h1 className="plan-tasks-placement-current-task-name">{currentTaskPlacement?.name}</h1>
        <p className="plan-tasks-placement-current-task-name">{getSelectedTimeStrToDisplay()}</p>
      </div>
    );
  };

  const getHeaderText = () => {
    if ((allTasks || []).filter(filterForUnscheduledTasks).length === 0) return null;
    return t(isMobileDevice() || isMobileView() ? 'tapScheduleMobile' : 'tapScheduleDesktop');
  };

  return (
    <SwipeToCloseWrapper
      id="planner-mode-unscheduled-task-list"
      className={`${mainLayoutClass} ${deviceClass} ${mainLayoutClass}--${mode}`}
      swipeDirection={ECloseSwipeDirection.DOWN}
      onStartCloseSwipeAnimation={(direction) => direction === 'down' && onClose()}
      canCloseOnSwipe={mode === EPlanTasksPlacementListMode.FULL}
    >
      <div className={'plan-tasks-placement-list-inner-container'}>
        <div className="plan-tasks-placement-list-actions-container">
          <AppButton
            id="planner-mode-unscheduled-task-list-close-button"
            type="button"
            className={`plan-tasks-placement-list-close-button plan-tasks-placement-list-close-button--full no-style-button`}
            onClick={onClose}
          >
            <BackArrowIcon />
          </AppButton>
          <AppButton
            id="planner-mode-unscheduled-task-list-back-button"
            type="button"
            className={`plan-tasks-placement-list-close-button plan-tasks-placement-list-close-button--placing no-style-button`}
            onClick={handleBackToList}
          >
            <BackArrowIcon />
          </AppButton>
          <h3 className="plan-tasks-placement-header plan-tasks-placement-header--full">
            {getHeaderText()}
          </h3>
          <h3 className="plan-tasks-placement-header plan-tasks-placement-header--placing">
            {currentTaskPlacement?.workTime ? t('myPlanDayScheduled') : t('pickTime')}
          </h3>
        </div>
        <div className="plan-tasks-list-scroll">
          <div className="plan-tasks-list">
            {(allTasks || [])
              .filter(filterForUnscheduledTasks)
              .sort(
                (ta, tb) =>
                  new Date(ta.creationTime).getTime() - new Date(tb.creationTime).getTime(),
              )
              .map((task, index) => (
                <Fragment key={task.id}>
                  <PlannerTask task={task} index={index} />
                </Fragment>
              ))}
          </div>
        </div>
        {currentTaskContext()}
        {!getHeaderText() && mode !== EPlanTasksPlacementListMode.PLACING && (
          <AppEmptyState
            SvgEmptyStateComponent={EmptyStateImg}
            paragraph={t('plannerEmptyStateText')}
            className="planner-empty-state"
          />
        )}
      </div>
      {<PlanTaskPlacementSetButton onSet={onSetUnscheduledWorkTime} />}
    </SwipeToCloseWrapper>
  );
};
