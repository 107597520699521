import { IUser } from '../../app/auth/auth.interfaces';
import type { IMessageDataTask } from './resizable-container/stage-container/stage-tasks/stageTasks.interface';

export interface IChatMessage {
  party: TChatMessageType;
  msg: string;
  messageTime: number;
  shouldDisplayTime: boolean;
  sessionId: string;
  creationTime: string;
  msgId?: string;
  msgSequenceNumber?: number;
  data?: IChatMessageData | null;
  debugInfo?: string;
  tool?: IToolMessage | undefined;
  isHidden?: boolean;
}

export type TChatMessageType = 'User' | 'Bot';

export enum EChatMessageDataType {
  TASKS_LIST = 'ListUpcomingTasksData',
  SINGLE_TASK = 'SingleTaskData',
  DONT_DISPLAY = 'DontDisplay',
}

export interface IMessageData {
  type: EChatMessageDataType;
}
export interface ISingleMessageDataRes extends IMessageData {
  content?: IMessageDataTask;
}
export interface IListMessageDataRes extends IMessageData {
  content?: IMessageDataTask[];
}

export interface ISingleTaskData {
  name: string;
  reminderTime: string | undefined;
  dueDate: string | undefined;
  workTime: string | undefined;
}

export interface IChatMessageData extends IMessageData {
  content?: { [key: string]: IMessageDataTask[] };
}

export interface IChatForm {
  userMessage: string;
  messagesArr: IChatMessage[];
  isRecording: boolean;
  shouldDisplayRecordingErrorMsg: boolean;
}

export interface IHistoryMessage extends IChatMessage {
  creationTime: string;
}

export interface IHumanStudentTurnSendInputPayload {
  sessionId: string;
  studentInput?: string;
  option?: IOptionMessage;
}

export interface IToolMessage {
  name: string;
  arg?: IToolMessageArg;
}

export interface IToolMessageArg {
  form?: string;
  view?: string;
  field?: string;
  value?: unknown;
  task?: IMessageDataTask;
}

export interface ICreateSessionResponse {
  sessionId: string;
  messages: IMessageResponse[];
  history: IMessageResponse[];
  creationTime: string;
}

export interface ICreateSessionRequest {
  userIanaTimeZone: string | null;
  userTimeZoneOffsetInMinutes: number;
  sessionSourceTypeId?: number | null;
}

export interface IMessageResponse {
  id: string;
  sessionId: string;
  creationTime: string;
  messageSequenceNumber: number;
  party: TChatMessageType;
  text: string;
  data?: IListMessageDataRes | ISingleMessageDataRes | null;
  options?: IOptionMessage[];
  debugInfo?: string;
  tool?: IToolMessage | undefined;
}

export interface IBotResponse {
  user: IUser;
  messages: IMessageResponse[];
}

export class DislikeFeedbackForm {
  feedbackText = '';
  feedbackTags: string[] = [];
}

export enum EFeedbackType {
  LIKE = 'like',
  DISLIKE = 'dislike',
  NONE = 'none',
}

export interface IFeedbackPayload {
  sessionId: string;
  messageId: string;
  messageSequenceNumber: number;
  feedbackType: EFeedbackType;
  feedbackText?: string;
  feedbackTags?: string[];
}

export interface IOptionMessage {
  text: string;
  tool?: IToolMessage | undefined;
}

export interface IHistoryParams {
  sessionId: string;
  currentMessageNumber: number;
}

export interface IHistoryQuery {
  before: number;
  after: number;
  stopDate?: string;
}

export interface IMessageFeedback {
  feedbackType: EFeedbackType;
  feedbackText?: string;
  updateTime: string;
  tags?: string[];
}
export interface IHistoryResponse extends IMessageResponse {
  userId: string;
  feedback: IMessageFeedback | null;
}

export interface ISessionSummariesResponse {
  sessionId: string;
  creationTime: string;
  summary: string | null;
}

export type appendNewMessageResetOrFocusArg = 'reset' | 'focus';
