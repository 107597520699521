import type { FunctionComponent } from 'react';
import React from 'react';
import TasksListSingleItem from '../TasksListSingleItem';
import TaskDueActionButton from '../task-action-buttons/TaskDueActionButton';
import TaskScheduleActionButton from '../task-action-buttons/TaskScheduleActionButton';
import {
  ETaskViewType,
  type ISingleTaskItemContainerChildrenProps,
} from '../SingleTaskItemContainer';
import sassVariables from '../singleTaskItemContainer.module.scss';
import './SwipeableSingleTaskItemMobile.scss';
import TaskDeleteActionButton from '../task-action-buttons/TaskDeleteActionButton';
import { isMobileDevice } from '../../../../../../../shared/utils/isMobileDevice';
import SwipeableItemWrapper from '../../../../../../../shared/components/swipeable-item-wrapper/SwipeableItemWrapper';
import { getFeatureFlagByKey } from '../../../../../../../shared/utils/utils';
import TaskTimerActionButton from '../task-action-buttons/TaskTimerActionButton';
import { FEATURE_FLAGS } from '../../../../../../../app/constants';
import { taskActionOverlaysPositionStyle } from '../../TasksList.utils';

interface ISwipeableElement {
  itemWidth: number;
  element: JSX.Element;
}

const SwipeableSingleTaskItemMobile: FunctionComponent<ISingleTaskItemContainerChildrenProps> = ({
  ...props
}) => {
  const isMobile = isMobileDevice();
  const isFocusedTimerEnabled = getFeatureFlagByKey(FEATURE_FLAGS.FOCUS_TIMER);

  let leftSideSwipeableElements: ISwipeableElement[] = [];

  if (props.viewType === ETaskViewType.TASK_LIST) {
    leftSideSwipeableElements = [
      ...leftSideSwipeableElements,
      {
        itemWidth: Number(sassVariables.singleTaskActionButtonWidth),
        element: (
          <TaskDeleteActionButton task={props.singleTask} onUpdateTask={props.onUpdateTask} />
        ),
      },
    ];
  }

  const rightSideSwipeableElements = [
    {
      itemWidth: Number(sassVariables.singleTaskActionButtonWidth),
      element: (
        <TaskDueActionButton
          task={props.singleTask}
          onUpdateTask={props.onUpdateTask}
          actionOverlaysPositionStyle={taskActionOverlaysPositionStyle}
        />
      ),
    },
    {
      itemWidth: Number(sassVariables.singleTaskActionButtonWidth),
      element: (
        <TaskScheduleActionButton
          task={props.singleTask}
          onUpdateTask={props.onUpdateTask}
          actionOverlaysPositionStyle={taskActionOverlaysPositionStyle}
        />
      ),
    },
  ];

  if (isFocusedTimerEnabled) {
    rightSideSwipeableElements.push({
      itemWidth: Number(sassVariables.singleTaskActionButtonWidth),
      element: (
        <TaskTimerActionButton
          task={props.singleTask}
          onUpdateTask={props.onUpdateTask}
          actionOverlaysPositionStyle={taskActionOverlaysPositionStyle}
        />
      ),
    });
  }
  return (
    <div className={`swipeable-task-item ${isMobile ? 'swipeable-task-item--mobile' : ''}`}>
      <SwipeableItemWrapper
        rangeLimitedSwipeRight={
          (Number(sassVariables.singleTaskActionButtonWidth) +
            parseInt(sassVariables.distanceBetweenBetweenEachButton)) *
          leftSideSwipeableElements.length
        }
        rangeLimitedSwipeLeft={
          (Number(sassVariables.singleTaskActionButtonWidth) +
            parseInt(sassVariables.distanceBetweenBetweenEachButton)) *
          rightSideSwipeableElements.length
        }
        translateXCalcType="px"
        shouldReturnToStartWhenEndSwipe={false}
        itemHeightInPx={Number(sassVariables.singleTaskHight.replace('px', ''))}
        swipeableItemElement={<TasksListSingleItem {...props} testid="task-mobile" />}
        leftSideElements={leftSideSwipeableElements}
        rightSideElements={rightSideSwipeableElements}
        extraDistanceBetweenSwipeableItemToTheButtonRight={
          sassVariables.extraDistanceBetweenSwipeableItemToTheButtonRight
        }
        extraDistanceBetweenSwipeableItemToTheButtonLeft={
          sassVariables.extraDistanceBetweenSwipeableItemToTheButtonLeft
        }
        distanceBetweenBetweenEachButton={sassVariables.distanceBetweenBetweenEachButton}
      />
    </div>
  );
};

export default SwipeableSingleTaskItemMobile;
