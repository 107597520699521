import { EnhancedStore } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ApplicationInsightsApi } from '../../application-insights';
import { FlagUtils } from './flagUtils';
import { EClientFlags } from '../components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';

/**
 * Injecting the store to be able to use it inside axios interceptors
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */

// eslint-disable-next-line
let store: EnhancedStore<RootState> | undefined;
export const injectStoreSoundUtils = (_store: EnhancedStore) => {
  store = _store;
};

export enum ESoundType {
  TASK_COMPLETED = 1,
  TIMER_COMPLETED = 2,
}

interface ISoundPermissions {
  soundType: ESoundType;
  isAllowedForUnauthenticatedUsers: boolean;
  soundUrl: string;
}

const soundConfigs: Record<ESoundType, ISoundPermissions> = {
  [ESoundType.TASK_COMPLETED]: {
    soundType: ESoundType.TASK_COMPLETED,
    isAllowedForUnauthenticatedUsers: false,
    soundUrl: '/sounds/max_done.mp3',
  },
  [ESoundType.TIMER_COMPLETED]: {
    soundType: ESoundType.TIMER_COMPLETED,
    isAllowedForUnauthenticatedUsers: true,
    soundUrl: '/sounds/max_timer_done.mp3',
  },
};

const getSoundConfigs = (soundType: ESoundType) => {
  try {
    return soundConfigs[soundType];
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return null;
  }
};

const isUserAuthenticated = () => {
  try {
    const user = store?.getState()?.userReducer?.user;
    return !!user;
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return false;
  }
};

const isAuthenticatedUserSoundEnabled = () => {
  try {
    const clientFlags = store?.getState()?.userReducer?.user?.clientFlags || 0;
    const isEnabled = !FlagUtils.getFlag(clientFlags, EClientFlags.AUDIO_OFF);
    return isEnabled;
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return false;
  }
};

const playSound = (soundType: ESoundType): boolean => {
  try {
    const soundPermission = getSoundConfigs(soundType);
    const isAuthenticated = isUserAuthenticated();
    const isSoundEnabled = isAuthenticatedUserSoundEnabled();
    if (!soundPermission) {
      return false;
    }
    if (!isAuthenticated && !soundPermission.isAllowedForUnauthenticatedUsers) {
      return false;
    }
    if (isAuthenticated && !isSoundEnabled) {
      return false;
    }
    const sound = new Audio(soundPermission.soundUrl);
    sound.play();
    return true;
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return false;
  }
};

export const handleAppPlaySound = (soundType: ESoundType): boolean => {
  return playSound(soundType);
};
