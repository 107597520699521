import type { FunctionComponent } from 'react';
import { useState } from 'react';
import './EditPhoneModal.scss';
import EnterPhoneAndGetOtpForm from '../../../../../phone-verification-flow/enter-phone-and-get-otp-form/EnterPhoneAndGetOtpForm';
import { useTranslation } from 'react-i18next';
import PhoneOrEmailVerificationByOtp from '../../../../../phone-verification-flow/phone-or-email-verification-by-otp/PhoneOrEmailVerificationByOtp';
import removeAppOverlayPopover from '../../../../../app-overlay-popover/removeAppOverlayPopover';
import AppButton from '../../../../../app-button/AppButton';
import XCloseIcon from '../../../../../icons/XCloseIcon';

enum EEditPhoneModalStep {
  ENTER_PHONE_NUMBER,
  ENTER_OTP_CODE,
}

interface IEditPhoneModalProps {
  phoneNumber?: string;
  setPhoneNumber: (phoneNumber: string) => void;
}

const EditPhoneModal: FunctionComponent<IEditPhoneModalProps> = ({
  phoneNumber,
  setPhoneNumber,
}) => {
  const [componentStep, setComponentStep] = useState<EEditPhoneModalStep>(
    EEditPhoneModalStep.ENTER_PHONE_NUMBER,
  );
  const { t } = useTranslation();

  const renderStepComponent = () => {
    switch (componentStep) {
      case EEditPhoneModalStep.ENTER_PHONE_NUMBER:
        return (
          <EnterPhoneAndGetOtpForm
            className="settings-enter-phone-number"
            onFulfilledSendOtp={() => setComponentStep(EEditPhoneModalStep.ENTER_OTP_CODE)}
            title={t('settingsAddOrChangePhoneNumberTitle')}
            defaultPhoneNumber={phoneNumber}
            trackEventName="update-user-phone-in-settings-enter-phone"
          />
        );
      case EEditPhoneModalStep.ENTER_OTP_CODE:
        return (
          <PhoneOrEmailVerificationByOtp
            onFulfilledVerifyOtp={(data) => {
              setPhoneNumber(data?.user?.phoneNumber || '');
              onCloseModal();
            }}
            trackEventName="update-user-phone-in-settings-verify-phone"
            context="UpdateSettings"
            title={t('phoneVerificationTitle')}
            subTitle={t('phoneVerificationSubTitle')}
          />
        );
      default:
        return <></>;
    }
  };

  const onCloseModal = () => {
    removeAppOverlayPopover({
      shouldSlideOutMobileAnimation: true,
      shouldFadeOutDesktopAnimation: true,
    });
  };

  return (
    <div className="edit-phone-modal" data-testid="settings-edit-phone-modal">
      <AppButton
        id="settings-close-edit-phone-modal"
        className="close-edit-phone-modal"
        onClick={onCloseModal}
      >
        <XCloseIcon />
      </AppButton>
      {renderStepComponent()}
    </div>
  );
};

export default EditPhoneModal;
