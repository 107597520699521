import React, { Fragment } from 'react';
import SingleTag from './SingleTag';
import { ReactComponent as TagIcon } from '../../../../../../../assets/images/tag.svg';
import type { IAddEditTaskForm, ITaskFields, ITaskTag } from '../../stageTasks.interface';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../../task-edit-overlays/TaskEditOverlay';
import './TaskTags.scss';
import {
  editOverlaysPositionStyle,
  getElementPosition,
  getElementWidthAndHeight,
} from '../CreateOrEditTask.utils';
import { subTaskOverlayPopoverClassName } from '../../../../../../../app/constants';
import { ECloseSwipeDirection } from '../../../../../../../shared/hooks/swipe-hooks/swipe.utils';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';

interface ITaskTagsProps {
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isSubTask?: boolean;
  isReadOnly: boolean;
}

const TaskTags = ({ onUpdateTask, isSubTask, isReadOnly }: ITaskTagsProps) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const tagsWatch = taskForm.watch('tags');
  const { t } = useTranslation();

  const onDeleteTag = (index: number) => {
    // remove the tag from the list
    const tempTags = [...(tagsWatch || [])];
    tempTags.splice(index, 1);
    taskForm.setValue('tags', tempTags);
    onUpdateTask({ tags: tempTags }, false);
  };

  const onAddTag = (tag: ITaskTag) => {
    const updatedTags = [...(tagsWatch || []), tag];
    taskForm.setValue('tags', updatedTags);
    onUpdateTask({ tags: updatedTags });
  };

  return (
    <div
      className={`task-tags-container task-detail-section ${
        isReadOnly ? 'task-tags-container--read-only' : ''
      } ${isReadOnly && !tagsWatch?.length ? 'd-none' : ''}`}
    >
      {(!isReadOnly || !!tagsWatch?.length) && (
        <h3 className="create-or-edit-sub-title">{t('createOrEditTaskTagsTitle')}</h3>
      )}
      <div className="tags-list">
        {!isReadOnly && (
          <AppButton
            className="add-tag"
            id="add-new-tag"
            type="button"
            onClick={(e) =>
              createAppOverlayPopover(
                <TaskEditOverlay
                  overlayType="tags"
                  onChange={(tag) => !!tag && onAddTag(tag as ITaskTag)}
                  isSubTask={isSubTask}
                />,
                isSubTask ? subTaskOverlayPopoverClassName : null,
                e,
                isSubTask
                  ? {
                      ...editOverlaysPositionStyle,
                      ...getElementPosition(
                        document.getElementById('add-edit-task-form-container')!,
                      ),
                      ...getElementWidthAndHeight(
                        document.getElementById('add-edit-task-form-container')!,
                      ),
                    }
                  : undefined,
                {
                  // overlay config
                  isCustomStyle: isSubTask,
                  slideInMobileAnimation: isSubTask
                    ? EAppOverlaySlideInMobileAnimation.FULL_SCREEN
                    : EAppOverlaySlideInMobileAnimation.HALF_SCREEN,
                  shouldCloseBySwipeOnMobile: true,
                  closeSwipeDirection: ECloseSwipeDirection.DOWN,
                },
              )
            }
          >
            <TagIcon
              className={`task-detail-icon ${
                (tagsWatch || []).length ? 'task-detail-icon--active' : ''
              }`}
            />{' '}
            {!(tagsWatch || []).length && t('createOrEditTaskAddTagButtonText')}
          </AppButton>
        )}
        {(tagsWatch || []).map((tag, i) => (
          <Fragment key={i}>
            <SingleTag tag={tag} onDeleteTag={() => onDeleteTag(i)} />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default TaskTags;
