import { useMemo } from 'react';
import { useAppSelector } from '../../../app/store';
import { EAPIStatus } from '../../../shared/api/models';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import { useTranslation } from 'react-i18next';
import StyledAppButton from '../../../shared/components/app-button/styled-app-button/StyledAppButton';

interface IProps {
  onSet: () => void;
}

export const PlanTaskPlacementSetButton = ({ onSet }: IProps) => {
  const { updateTaskRes, tasksListResponse } = useAppSelector((store) => store.StageTasksReducer);
  const { currentTaskPlacement } = useAppSelector((store) => store.StagePlannerReducer);
  const { t } = useTranslation();

  const isLoading = useMemo(
    () =>
      updateTaskRes.status === EAPIStatus.PENDING ||
      tasksListResponse.status === EAPIStatus.PENDING,
    [updateTaskRes.status, tasksListResponse.status],
  );

  return (
    <StyledAppButton
      type="button"
      id="set-task-planner"
      disabled={
        (!currentTaskPlacement?.workTime && !currentTaskPlacement?.workBlockStartTime) || isLoading
      }
      className={`task-edit-overlay-header-action-button task-edit-overlay-header-action-button--set ${
        (!currentTaskPlacement?.workTime && !currentTaskPlacement?.workBlockStartTime) || isLoading
          ? 'task-edit-overlay-header-action-button--disabled'
          : ''
      }`}
      onClick={onSet}
      aria-label={t('setButtonText')}
      buttonStyleType="secondary"
    >
      {isLoading ? <LottieAppLoader /> : t('setButtonText')}
    </StyledAppButton>
  );
};
