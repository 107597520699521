import { useCallback, useState } from 'react';
import type { IFormData } from '../../../../../shared/components/app-phone-control/AppPhoneControl';
import { AppPhoneControl } from '../../../../../shared/components/app-phone-control/AppPhoneControl';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  API_ROUTES,
  APP_ROUTING_PATHS,
  isSignUpLocalStorageKey,
  loginTypeStorageKey,
  registrationParametersStorageKey,
  userPhoneCountryTwoLetterCodeLocalStorageKey,
  userPhoneNumberLocalStorageKey,
} from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { requestMagicLink } from '../../../auth.store';
import { baseUrl } from '../../Login';
import { EAPIStatus } from '../../../../../shared/api/models';
import { isEmbeddedBrowser } from '../../../auth.utils';
import { useLocalStorage } from '../../../../../shared/utils/useLocalStorage';
import phoneMascotImg from '../../../../../assets/images/mascot/phone-mascot.png';
import signInMascot from '../../../../../assets/images/mascot/onboarding/sign-in-mascot.png';
import PhoneNumberVerificationAuthPage from '../../../phone-verification-auth-flow/phone-or-email-verification-auth-page/PhoneOrEmailVerificationAuthPage';
import { FormProvider, useForm } from 'react-hook-form';
import './LoginWithPhone.scss';
import { AuthHeroCard } from '../../../../../shared/components/auth-hero-card/AuthHeroCard';
import { getItemFromLocalStorage } from '../../../../../shared/utils/localStorage.utils';
import StyledAppButton from '../../../../../shared/components/app-button/styled-app-button/StyledAppButton';
import AppButton from '../../../../../shared/components/app-button/AppButton';

enum EViewType {
  REQUEST_MAGIC_LINK = 1,
  MAGIC_LINK_SENT,
  MAGIC_LINK_SENT_DESKTOP,
}

const LoginWithPhone = () => {
  const { magicLinkRes } = useAppSelector((store) => store.authReducer);
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<EViewType>(EViewType.REQUEST_MAGIC_LINK);
  const [errorText, setErrorText] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [, setPhoneNumber] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const isSignUpFlow = getItemFromLocalStorage<boolean>(isSignUpLocalStorageKey);
  const [, setPhoneCountryTwoLetterCode] = useLocalStorage<string | null>(
    userPhoneCountryTwoLetterCodeLocalStorageKey,
    null,
  );
  const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');

  const loginWithPhoneForm = useForm<IFormData>({
    defaultValues: {
      phone: '',
      country: 'US',
    },
  });

  const onSubmit = () => {
    const formData = loginWithPhoneForm.getValues();
    const isMobileOrEmbeddedBrowser = isEmbeddedBrowser();
    if (magicLinkRes.status === EAPIStatus.PENDING) return;
    setErrorText('');
    dispatch(
      requestMagicLink({
        payload: {
          phoneNumber: formData.phone,
          phoneCountryTwoLetterCode: formData.country,
          redirectUri: `${baseUrl}${APP_ROUTING_PATHS.SSO_MAGIC_LINK}`,
          registrationParameters:
            localStorage.getItem(registrationParametersStorageKey) || undefined,
          isShortCodeRequested: !isMobileOrEmbeddedBrowser,
        },
        api: API_ROUTES.AUTH.REQUEST_MAGIC_LINK_PHONE,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res) {
          setPhoneCountryTwoLetterCode(formData.country);
          setPhoneNumber(formData.phone);
          setLoginType('phone');
          setViewType(
            isMobileOrEmbeddedBrowser
              ? EViewType.MAGIC_LINK_SENT
              : EViewType.MAGIC_LINK_SENT_DESKTOP,
          );
        }
      })
      .catch(() => {
        setErrorText(t('enterValidPhoneNumberError').toString());
      });
  };

  const getMascot = useCallback(() => {
    if (viewType === EViewType.REQUEST_MAGIC_LINK && !isSignUpFlow) {
      return signInMascot;
    }
    return phoneMascotImg;
  }, [viewType]);

  const getTitle = useCallback(() => {
    if (viewType === EViewType.REQUEST_MAGIC_LINK) {
      return isSignUpFlow ? t('phoneRegistrationScreenTitleNewFlow') : t('loginReturningUser');
    }
    return t('phoneVerificationTitle');
  }, [viewType]);

  const getSubTitle = useCallback(() => {
    if (viewType === EViewType.REQUEST_MAGIC_LINK && isSignUpFlow)
      return t('loginWithPhoneTextNewFlow');
    return '';
  }, [viewType]);

  const handleNavigateBack = () => {
    switch (viewType) {
      case EViewType.REQUEST_MAGIC_LINK:
        navigate(-1);
        break;
      case EViewType.MAGIC_LINK_SENT:
      case EViewType.MAGIC_LINK_SENT_DESKTOP:
        setViewType(EViewType.REQUEST_MAGIC_LINK);
        break;
      default:
        navigate(-1);
        break;
    }
  };

  return (
    <>
      {viewType === EViewType.MAGIC_LINK_SENT_DESKTOP ? (
        <PhoneNumberVerificationAuthPage isLoginFlow={true} onClickBack={handleNavigateBack} />
      ) : (
        <div
          className={`login-with-phone-page login-with-phone-page--view-type-${viewType} shorten-onboarding-flow auth-screen`}
          id="login-with-phone-page"
        >
          <div className="login-with-phone-content-container scroll-y-container-hidden-scroll-bar">
            <AuthHeroCard mascot={getMascot()} title={getTitle()} subTitle={getSubTitle()} />
            {viewType === EViewType.REQUEST_MAGIC_LINK && (
              <FormProvider {...loginWithPhoneForm}>
                <form
                  onSubmit={loginWithPhoneForm.handleSubmit(onSubmit)}
                  className="login-with-phone-text-and-phone-content-container enter-phone-number-get-otp"
                >
                  <section className="phone-form-content">
                    <label htmlFor="phone" className="phone-input-label">
                      {t('enterPhoneNumberToGetCodeLabel')}
                    </label>
                    <AppPhoneControl className="login-with-phone-control" errorText={errorText} />
                    <StyledAppButton
                      onClick={onSubmit}
                      className="auth-next-btn auth-submit-btn"
                      form="update-phone-form"
                      id="login-with-phone-submit"
                      data-testid="update-phone-form-submit"
                      type="button"
                      disabled={
                        !loginWithPhoneForm.formState.isValid ||
                        magicLinkRes.status === EAPIStatus.PENDING
                      }
                    >
                      {t('sendButtonText')}
                    </StyledAppButton>
                  </section>
                  <p className="agreement-paragraph">{t('enterPhoneLegalText')}</p>
                </form>
              </FormProvider>
            )}
            {viewType === EViewType.MAGIC_LINK_SENT && (
              <>
                <h1 className="login-with-phone-title">{t('loginWithPhoneSecondaryTitle')}</h1>
                <p className="login-with-phone-instructions white-space-pre-wrap">
                  {t('loginWithPhoneSecondaryText')}
                </p>
                <p className="login-with-phone-instructions login-with-phone-resend-instructions">
                  {t('loginWithPhoneResentCodeText')}{' '}
                  <span className="clickable-element" onClick={onSubmit}>
                    {t('loginWithPhoneResentLinkText')}
                  </span>
                  .
                </p>
              </>
            )}
          </div>
          <AppButton
            id="login-with-phone-back-button"
            onClick={() => {
              setErrorText('');
              handleNavigateBack();
            }}
            className="back-link"
          >
            {t('registerScreenBackButtonText')}
          </AppButton>
        </div>
      )}
    </>
  );
};

export default LoginWithPhone;
