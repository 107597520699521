import { createAction, createReducer } from '@reduxjs/toolkit';
import type { ApiDataStateType } from '../../../../../shared/store/utils';
import { createAPIReducerCases } from '../../../../../shared/store/utils';
import { apiServiceCSharp, createApiThunk } from '../../../../../shared/api/axios';
import {
  ETaskStatus,
  type ETaskFormType,
  type ICreateTaskReqPayload,
  type IMessageDataTask,
  type ISelectedTasksFilter,
  type IStageTasksListResponse,
  type ITaskUpdateReqPayload,
} from './stageTasks.interface';
import { API_ROUTES, fullDayDurationInSeconds } from '../../../../../app/constants';
import type { IAPIRequestState } from '../../../../../shared/api/models';
import { APIRequestState } from '../../../../../shared/api/models';
import { store } from '../../../../../app/store';
import { getTagHashString } from './TasksList.utils';

export interface IStageTasksStore extends ApiDataStateType {
  tasksListResponse: IAPIRequestState<IStageTasksListResponse>;
  tasksListPollingResponse: IAPIRequestState<IStageTasksListResponse>;
  tasksFilter: ISelectedTasksFilter[];
  updateTaskRes: IAPIRequestState<IMessageDataTask>;
  shouldStageExpand: boolean;
  selectedMainTaskForEditing: IMessageDataTask | null;
  shouldOpenAddEditTaskFrom: ETaskFormType | null;
  createTaskRes: IAPIRequestState<IMessageDataTask[]>;
  shouldStartDestroyAnimationTaskForm: boolean;
  allTasks: IMessageDataTask[];
  allWorkBlocks: IMessageDataTask[];
  activeMyPlanDay: string | null;
  calendarSelectedWorkTime: string | null;
  updatedTasksViaTheChat: IMessageDataTask[] | null;
  totalTasksForUser: number;
  subtasksMap: { [key: string]: IMessageDataTask[] };
  tagsToDisplayOnTasksFilter: string[];
  tasksWithoutRecurrences: IMessageDataTask[];
}

const initialStateStageTasksStore: IStageTasksStore = {
  tasksListResponse: APIRequestState.create(),
  tasksListPollingResponse: APIRequestState.create(),
  tasksFilter: [],
  updateTaskRes: APIRequestState.create(),
  shouldStageExpand: false,
  selectedMainTaskForEditing: null,
  shouldOpenAddEditTaskFrom: null,
  createTaskRes: APIRequestState.create(),
  shouldStartDestroyAnimationTaskForm: false,
  allTasks: [],
  allWorkBlocks: [],
  activeMyPlanDay: null,
  calendarSelectedWorkTime: null,
  updatedTasksViaTheChat: null,
  totalTasksForUser: 0,
  subtasksMap: {},
  tagsToDisplayOnTasksFilter: [],
  tasksWithoutRecurrences: [],
};

function processTaskData(state: any, tasksData: { tasks?: IMessageDataTask[] }) {
  // Merge tasks and remove duplicates
  const mergedWithoutDuplicatesTasksArr = Array.from(
    new Map(
      ([...state.tasksWithoutRecurrences] as IMessageDataTask[])
        .concat(tasksData?.tasks || [])
        .map((item: IMessageDataTask) => [item.id, item]),
    ).values(),
  );

  const localSubtasksMap: { [key: string]: IMessageDataTask[] } = {};
  const localTagsArray: string[] = [];
  // Update task names and sibling data
  const updatedTaskNamesWithoutDuplicates = mergedWithoutDuplicatesTasksArr.map((task) => {
    const updatedTask = updateSubTaskData(task, [...mergedWithoutDuplicatesTasksArr]);
    updateSubtasksMapState(localSubtasksMap, updatedTask);
    addTaskTagsToFilterTagsArray(updatedTask, localTagsArray);
    return updatedTask;
  });

  // Update state properties
  state.tasksWithoutRecurrences = [...updatedTaskNamesWithoutDuplicates];
  state.tagsToDisplayOnTasksFilter = [...localTagsArray];
  state.subtasksMap = { ...localSubtasksMap };
  state.totalTasksForUser = updatedTaskNamesWithoutDuplicates?.length || 0;
  state.allTasks = [
    ...updatedTaskNamesWithoutDuplicates
      .filter((t) => !(t.isEvent && t.isWorkBlock))
      .map((task) => expandRecurringTask(task))
      .flat(),
  ];
  state.allWorkBlocks = [
    ...updatedTaskNamesWithoutDuplicates
      .filter((t) => t.isEvent && t.isWorkBlock)
      .map((task) => expandRecurringTask(task))
      .flat(),
  ];
}

const addTaskTagsToFilterTagsArray = (task: IMessageDataTask, localTagsArray: string[]) => {
  let tagHash = '';
  task.tags?.forEach((tag) => {
    tagHash = getTagHashString(tag);
    if (!localTagsArray.includes(tagHash)) {
      localTagsArray.push(tagHash);
    }
  });
};

const updateSubtasksMapState = (localSubtasksMap: any, task: IMessageDataTask) => {
  // if is subTask
  if (
    task.parentId &&
    !task?.isMultiWorkTimeTask &&
    !task.isEvent &&
    !task.isWorkBlock &&
    task.status !== ETaskStatus.DELETED
  ) {
    localSubtasksMap[task.parentId]
      ? localSubtasksMap[task.parentId].push(task)
      : (localSubtasksMap[task.parentId] = [task]);
  }
};

const createReducerKey = (subKey: string): string => {
  return 'stageTasks/' + subKey;
};
// If a task has no name and has a parentId, then it should inherit the name of the parent task
export const updateSubTaskData = (task: IMessageDataTask, currentTaskList: IMessageDataTask[]) => {
  if (((!task.name || task.name.length === 0) && !!task.parentId) || task.isMultiWorkTimeTask) {
    // task is a multi work time task
    // mark it as such
    // find the parent and extract its name and due date
    task.isMultiWorkTimeTask = true;
    const parentTask = currentTaskList.find((t) => t.id === task.parentId);
    // for multiple work time tasks we need to inherit the name, due date, tags, priority and quickWin from the parent task
    if (parentTask) {
      task.name = parentTask.name;
      task.dueDate = parentTask.dueDate;
      task.tags = parentTask.tags?.length ? [...parentTask.tags] : [];
      task.priority = parentTask.priority;
      task.quickWin = parentTask.quickWin;
    }
  }
  task.isFullDayEvent = isFullDayEvent(task.duration);
  return task;
};

export const isFullDayEvent = (itemDuration?: number | null) => {
  if (itemDuration && itemDuration >= fullDayDurationInSeconds) return true;
  return false;
};

export const expandRecurringTask = (item: IMessageDataTask) => {
  const itemRecurrenceDates = item.workTimeRecurrenceDates;
  if (itemRecurrenceDates && itemRecurrenceDates.length > 0) {
    return itemRecurrenceDates.map((date, index) => {
      const starTimeProperty = { workTime: date };
      return {
        ...item,
        ...starTimeProperty,
        isRecurrenceInstance: index > 0, // the first recurrence belongs to the work time of the main task
        originalWorkTime: item.workTime,
      };
    });
  }
  return [item];
};

export const getTasksListReqAction = createApiThunk(createReducerKey('getTasksListReqAction'), () =>
  apiServiceCSharp.get<IStageTasksListResponse>(
    `${API_ROUTES.TASKS.TASKS_LIST}?timestamp=${
      store.getState().StageTasksReducer.tasksListPollingResponse?.data
        ?.userTaskTimestampForDelta || 0
    }`,
  ),
);

export const getTasksListPollingReqAction = createApiThunk(
  createReducerKey('getTasksListPollingReqAction'),
  () =>
    apiServiceCSharp.get<IStageTasksListResponse>(
      `${API_ROUTES.TASKS.TASKS_LIST}?timestamp=${
        store.getState().StageTasksReducer.tasksListPollingResponse?.data
          ?.userTaskTimestampForDelta || 0
      }`,
    ),
);

export const updateTaskReqAction = createApiThunk(
  createReducerKey('updateTaskReqAction'),
  (reqPayload?: ITaskUpdateReqPayload) => {
    return apiServiceCSharp.patch<IMessageDataTask>(`${API_ROUTES.TASKS.UPDATE_TASK}`, reqPayload);
  },
);

export const createTaskReqAction = createApiThunk(
  createReducerKey('createTaskReqAction'),
  (reqPayload?: ICreateTaskReqPayload) => {
    return apiServiceCSharp.post<IMessageDataTask[]>(`${API_ROUTES.TASKS.CREATE_TASK}`, reqPayload);
  },
);

export const setTasksFilter = createAction<ISelectedTasksFilter[]>(
  createReducerKey('setTasksFilter'),
);

export const setShouldStageExpand = createAction<boolean>(createReducerKey('setShouldStageExpand'));

export const setSelectedMainTaskForEditing = createAction<IMessageDataTask | null>(
  createReducerKey('setSelectedMainTaskForEditing'),
);

export const setShouldOpenAddEditTaskFrom = createAction<ETaskFormType | null>(
  createReducerKey('setShouldOpenAddEditTaskFrom'),
);

export const setShouldStartDestroyAnimationTaskForm = createAction<boolean>(
  createReducerKey('setShouldStartDestroyAnimationTaskForm'),
);

export const setActiveMyPlanDay = createAction<string | null>(
  createReducerKey('setActiveMyPlanDay'),
);

export const setCalendarSelectedWorkTime = createAction<string | null>(
  createReducerKey('setCalendarSelectedWorkTime'),
);

export const setUpdatedTasksViaTheChat = createAction<IMessageDataTask[] | null>(
  createReducerKey('setUpdatedTasksViaTheChat'),
);

export const StageTasksReducer = createReducer(initialStateStageTasksStore, (builder) => {
  createAPIReducerCases(getTasksListReqAction, 'tasksListResponse', builder, {
    onFulfilled(state, tasksData) {
      processTaskData(state, tasksData);
    },
  });

  createAPIReducerCases(getTasksListPollingReqAction, 'tasksListPollingResponse', builder, {
    onFulfilled(state, tasksData) {
      processTaskData(state, tasksData);
    },
  });

  createAPIReducerCases(updateTaskReqAction, 'updateTaskRes', builder, {
    onFulfilled(state, updatedTask) {
      const task = updateSubTaskData(updatedTask, [...state.allTasks]);
      state.updateTaskRes.data = { ...task };
    },
  });

  createAPIReducerCases(createTaskReqAction, 'createTaskRes', builder, {
    onFulfilled(state, createdTasks) {
      const tasks = createdTasks.map((task) => updateSubTaskData(task, [...state.allTasks]));
      state.createTaskRes.data = [...tasks];
    },
  });

  builder.addCase(setTasksFilter, (state, action) => {
    state.tasksFilter = [...action.payload];
  });

  builder.addCase(setShouldStageExpand, (state, action) => {
    state.shouldStageExpand = action.payload;
  });

  builder.addCase(setSelectedMainTaskForEditing, (state, action) => {
    state.selectedMainTaskForEditing = action.payload;
  });

  builder.addCase(setShouldOpenAddEditTaskFrom, (state, action) => {
    state.shouldOpenAddEditTaskFrom = action.payload;
  });

  builder.addCase(setShouldStartDestroyAnimationTaskForm, (state, action) => {
    state.shouldStartDestroyAnimationTaskForm = action.payload;
  });

  builder.addCase(setActiveMyPlanDay, (state, action) => {
    state.activeMyPlanDay = action.payload;
  });

  builder.addCase(setCalendarSelectedWorkTime, (state, action) => {
    state.calendarSelectedWorkTime = action.payload;
  });

  builder.addCase(setUpdatedTasksViaTheChat, (state, action) => {
    state.updatedTasksViaTheChat = action.payload;
  });
});
