import type { FunctionComponent } from 'react';
import React from 'react';
import TaskActionButton from './TaskActionButton';
import type { ITaskSingleActionButtonsProps } from '../TasksListSingleItem.interfaces';
import { useTranslation } from 'react-i18next';
import { IRecurrenceValues } from '../../stageTasks.interface';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../../task-edit-overlays/TaskEditOverlay';
import { ECloseSwipeDirection } from '../../../../../../../shared/hooks/swipe-hooks/swipe.utils';

const TaskScheduleActionButton: FunctionComponent<ITaskSingleActionButtonsProps> = ({
  task,
  onUpdateTask,
  actionOverlaysPositionStyle,
  className = '',
}) => {
  const { t } = useTranslation();

  const onChangeWorkTime = (
    workTime: Date,
    reminder?: number | null,
    duration?: number | null | undefined,
    recurrenceValues?: IRecurrenceValues,
  ) => {
    const recurrenceProperties = recurrenceValues ? { ...recurrenceValues } : {};

    onUpdateTask({
      workTime: workTime ? workTime.toISOString() : null,
      workTimeReminder: reminder || null,
      duration: duration !== null && duration !== undefined ? duration : null,
      ...recurrenceProperties,
    });
  };

  const onClickScheduleAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    createAppOverlayPopover(
      <TaskEditOverlay
        overlayType="workTime"
        duration={task.duration}
        reminder={task.workTimeReminder}
        date={task.workTime ? new Date(task.workTime) : undefined}
        recurrenceValues={{
          workTimeRecurrenceType: task?.workTimeRecurrenceType || null,
          workTimeRecurrenceInterval: task?.workTimeRecurrenceInterval || null,
          workTimeRecurrenceWeekDays: task?.workTimeRecurrenceWeekDays || null,
        }}
        onChange={(date, reminder, duration, recurrenceValues) =>
          onChangeWorkTime(date as Date, reminder, duration, recurrenceValues)
        }
      />,
      null,
      e,
      actionOverlaysPositionStyle,
      {
        // config
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.HALF_SCREEN,
        shouldCloseBySwipeOnMobile: true,
        closeSwipeDirection: ECloseSwipeDirection.DOWN,
      },
    );
  };

  return (
    <TaskActionButton
      actionName={t('taskDueActionButtonSchedule')}
      actionType="schedule"
      onClickAction={(e) => onClickScheduleAction(e)}
      className={className}
    />
  );
};

export default TaskScheduleActionButton;
