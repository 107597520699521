import { FunctionComponent } from 'react';
import { ReactComponent as CloseSvgComponentBigIcon } from '../../../assets/images/x-close-big-icon.svg';
import { ReactComponent as CloseSvgComponentSmallIcon } from '../../../assets/images/x-close-small-icon.svg';

interface ICloseIconProps {
  className?: string;
  size?: 'small' | 'large';
}

const XCloseIcon: FunctionComponent<ICloseIconProps> = ({ className = '', size = 'large' }) => {
  return (
    <>
      {size === 'large' ? (
        <CloseSvgComponentBigIcon className={`primary-color-stroke-icon ${className}`} />
      ) : (
        <CloseSvgComponentSmallIcon className={`primary-color-stroke-icon ${className}`} />
      )}
    </>
  );
};

export default XCloseIcon;
