import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProfileForm } from './edit-profile-form/EditProfileForm';
import { ResetProfile } from './reset-profile/ResetProfile';
import { useAppDispatch } from '../../../../../app/store';
import { getUserProfileReq } from '../../../../../app/useAppData/user.store';
import './PersonalInfo.scss';
import { CalendarSync } from './calendar-sync/CalendarSync';
import { Notifications } from '../notifications/Notifications';
import AudioControl from '../audio-control/AudioControl';
import ThemeControl from '../theme-control/ThemeControl';

export const PersonalInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserProfileReq());
  }, [dispatch]);

  return (
    <section className="personal-info settings-section-container">
      <h2 className="sub-title">{t('settingsPersonalInfoTitle')}</h2>
      <div className="separation"></div>
      <EditProfileForm />
      <CalendarSync />
      <ResetProfile />
      <ThemeControl />
      <AudioControl />
      <h2 className="sub-title">{t('settingsPersonalInfoNotificationsTitle')}</h2>
      <div className="separation"></div>
      <Notifications />
    </section>
  );
};
