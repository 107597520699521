import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MascotComponentLight } from '../../../assets/images/mascot/main-1.svg';
import { ReactComponent as MascotComponentDark } from '../../../assets/images/mascot/main-1-dark.svg';
import { Link } from 'react-router-dom';
import './TimerPageDialog.scss';
import { EAppThemes, getThemeByDocumentDataThemeAttr } from '../../../shared/utils/themes.utils';

const TimerPageDialog = () => {
  const { t } = useTranslation();
  const learnMoreURL = 'https://join.maximallearning.com/?utm_medium=inapp&utm_source=sharedtimer';
  const isDarkMode = getThemeByDocumentDataThemeAttr() === EAppThemes.DARK;

  return (
    <div className="timer-page-info-dialog">
      <h3 className="timer-page-info-dialog-header">{t('timerInfoDialogHeader')}</h3>
      <p className="timer-page-info-dialog-description">{t('timerInfoDialogText')}</p>
      <Link className="timer-page-info-dialog-link" to={learnMoreURL}>
        {t('timerInfoDialogLink')}
      </Link>
      {isDarkMode ? (
        <MascotComponentDark className="timer-page-info-dialog-mascot" />
      ) : (
        <MascotComponentLight className="timer-page-info-dialog-mascot" />
      )}
    </div>
  );
};

export default TimerPageDialog;
