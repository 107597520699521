import type { FunctionComponent } from 'react';
import React from 'react';
import OverlayTypeDetails from '../../task-edit-overlays/overlay-type-details/OverlayTypeDetails';
import {
  getRecurringFrequencyText,
  getReminderCalendarText,
} from '../../task-edit-overlays/TaskEditOverlay.utils';
import type { IRecurrenceValues } from '../../stageTasks.interface';
import { ReactComponent as RecurrenceIcon } from '../../../../../../../assets/images/recurrence.svg';
import { ReactComponent as BellIcon } from '../../../../../../../assets/images/task-overlay-bell-icon.svg';
import { useTranslation } from 'react-i18next';
import './ExternalEventsRecurrenceAndReminder.scss';

interface IExternalEventsRecurrenceAndReminder {
  reminderValue: number | null | undefined;
  recurrenceValues: IRecurrenceValues;
  workTimeDate?: Date;
}

const ExternalEventsRecurrenceAndReminder: FunctionComponent<
  IExternalEventsRecurrenceAndReminder
> = ({ reminderValue, recurrenceValues, workTimeDate }) => {
  const { t } = useTranslation();

  return (
    <>
      {(!!reminderValue || reminderValue === 0) && (
        <OverlayTypeDetails
          SvgComponent={BellIcon}
          title={t('ReminderCalendarTitle')}
          data={getReminderCalendarText(reminderValue, 'workTime', workTimeDate)}
          className="cursor-default task-detail-section external-event-detail"
        />
      )}
      {recurrenceValues?.workTimeRecurrenceType && (
        <OverlayTypeDetails
          SvgComponent={RecurrenceIcon}
          title={t('recurringFrequencyTitle')}
          data={getRecurringFrequencyText(recurrenceValues)}
          className="cursor-default task-detail-section external-event-detail"
        />
      )}
    </>
  );
};

export default ExternalEventsRecurrenceAndReminder;
