import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validICalUrlRegex } from './ICalProvider.utils';
import './ICalProviderSync.scss';
import { iCalUpdateRequestAction } from '../../../../../../../app/useAppData/user.store';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import { EAPIStatus } from '../../../../../../api/models';
import { useApiData } from '../../../../../../hooks/useApiData';
import StyledAppButton from '../../../../../app-button/styled-app-button/StyledAppButton';

interface ICalProviderFormValues {
  iCalUrl?: string;
}

export const ICalProviderSync = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { iCalUpdateRequest } = useAppSelector((store) => store.userReducer);
  const { register, formState, watch, reset } = useForm<ICalProviderFormValues>({
    defaultValues: {
      iCalUrl: undefined,
    },
  });

  const onSubmit = () => {
    dispatch(iCalUpdateRequestAction({ iCalUrl: watch('iCalUrl')! }));
  };

  useApiData(iCalUpdateRequest, {
    onRejected: () => {
      reset();
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      className="ical-provider-sync-form"
    >
      <div className="ical-provider-sync-form-input-container">
        <input
          disabled={iCalUpdateRequest.status === EAPIStatus.PENDING}
          {...register('iCalUrl', { required: 'Required', pattern: validICalUrlRegex })}
          type="text"
          className="ical-provider-sync-form-input"
          required
          autoFocus
        />
        {!!watch('iCalUrl')?.length && formState.isDirty && !formState.isValid && (
          <small className="error ical-sync-error">{t('iCalSyncInvalidUrl')}</small>
        )}
      </div>
      <StyledAppButton
        id="ical-provider-sync-form-submit"
        type="submit"
        className="ical-provider-sync-form-submit-button static-string"
        onClick={onSubmit}
        disabled={!formState.isValid || iCalUpdateRequest.status === EAPIStatus.PENDING}
      >
        {t('iCalSyncAction')}
      </StyledAppButton>
    </form>
  );
};
