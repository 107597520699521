import type { FunctionComponent } from 'react';
import React, { Fragment } from 'react';
import type { IMessageDataTask } from '../../stage-tasks/stageTasks.interface';
import { useTranslation } from 'react-i18next';
import SingleTaskItemContainer from '../../stage-tasks/tasks-list-single-item/SingleTaskItemContainer';

interface IWorkBlockDetailsTasksList {
  tasksToDisplay: IMessageDataTask[];
}

const WorkBlockDetailsTasksList: FunctionComponent<IWorkBlockDetailsTasksList> = ({
  tasksToDisplay,
}) => {
  const { t } = useTranslation();

  return (
    <div className="work-block-details-tasks-container" data-testid="work-block-tasks-container">
      <h3 className="tasks-title">{t('workBlockDetailsRelatedTasksTitle')}</h3>
      {tasksToDisplay.map((t) => {
        return (
          <Fragment key={t.id}>
            <SingleTaskItemContainer
              shouldHideOnComplete={false}
              singleTask={t}
              shouldDisplayActionButtons={true}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default WorkBlockDetailsTasksList;
