import type { FunctionComponent, SyntheticEvent } from 'react';
import React, { Fragment } from 'react';
import type { IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { ETaskFormType } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import {
  setShouldOpenWorkBlockDetails,
  setWorkBlockForEdit,
} from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import { getTimeRange } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/TasksList.utils';
import {
  getTaskFromAllTaskList,
  getWorkBlockAssignedTasksSorted,
  getWorkBlockOrder,
} from '../../../../shared/utils/utils';
import {
  setSelectedMainTaskForEditing,
  setShouldOpenAddEditTaskFrom,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { ReactComponent as WorkBlockIconComponent } from '../../../../assets/images/work-block-icon.svg';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { onPlaceUnscheduledTask } from '../../plan.utils';
import './PlanDayScheduledWorkBlock.scss';
import SingleTaskItemContainer from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/tasks-list-single-item/SingleTaskItemContainer';

interface IPlanDayScheduledWorkBlockProps {
  workBlock: IMessageDataTask;
}

const PlanDayScheduledWorkBlock: FunctionComponent<IPlanDayScheduledWorkBlockProps> = ({
  workBlock,
}) => {
  const { plannerMode, currentTaskPlacement } = useAppSelector(
    (store) => store.StagePlannerReducer,
  );
  const { allTasks } = useAppSelector((store) => store.StageTasksReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClickWorkBlock = (event: SyntheticEvent) => {
    if (plannerMode && currentTaskPlacement) {
      event.stopPropagation();
      handleTaskScheduling();
    } else {
      dispatch(setWorkBlockForEdit(workBlock));
      dispatch(setShouldOpenWorkBlockDetails(true));
    }
  };

  const onClickTask = (event: SyntheticEvent, taskToOpen: IMessageDataTask) => {
    if (plannerMode && currentTaskPlacement) {
      event.stopPropagation();
      handleTaskScheduling();
    } else {
      event.stopPropagation();
      if (taskToOpen.parentId) {
        const parentTask = getTaskFromAllTaskList(taskToOpen.parentId);
        if (parentTask) taskToOpen = parentTask;
      }
      dispatch(setSelectedMainTaskForEditing(taskToOpen));
      dispatch(
        setShouldOpenAddEditTaskFrom(taskToOpen.isEvent ? ETaskFormType.Event : ETaskFormType.Task),
      );
    }
  };

  const assignmentsTasks = getWorkBlockAssignedTasksSorted(
    [...allTasks, currentTaskPlacement || ({} as IMessageDataTask)],
    workBlock.id!,
    workBlock.workTime,
  );

  const handleTaskScheduling = () => {
    switch (plannerMode) {
      case EPlannerMode.TIMEPICKER:
      case EPlannerMode.UNSCHEDULEDTASKSPLACER:
        if (currentTaskPlacement?.isEvent) return;
        onPlaceUnscheduledTask(
          new Date(workBlock.workTime!),
          workBlock.id,
          workBlock.name || t('workBlockDefaultName'),
          !!workBlock.workTimeRecurrenceType,
          assignmentsTasks.length
            ? getWorkBlockOrder(assignmentsTasks[assignmentsTasks.length - 1]?.workBlockOrder)
            : 0,
        );
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="plan-day-scheduled-work-block"
      onClick={(e) => {
        if (!assignmentsTasks || assignmentsTasks.length === 0) onClickWorkBlock(e);
      }}
    >
      <section
        className="plan-day-scheduled-work-block--header"
        onClick={(e) => {
          if (!!assignmentsTasks && assignmentsTasks.length > 0) onClickWorkBlock(e);
        }}
      >
        <h1 className="workBlock-title">
          <WorkBlockIconComponent className="work-block-icon primary-color-stroke-icon" />
          {workBlock?.name || t('workBlockDefaultName')}
        </h1>
        <div className="pen-and-time-range">
          {workBlock?.workTime && (
            <div className="work-block-time-range">
              {getTimeRange(new Date(workBlock.workTime), workBlock.duration, true)}
            </div>
          )}
        </div>
      </section>
      <section className="plan-day-scheduled-work-block--tasks">
        {assignmentsTasks.map((t, i) => (
          <Fragment key={t.id}>
            <SingleTaskItemContainer
              shouldHideOnComplete={false}
              singleTask={t}
              shouldDisplayActionButtons={false}
              onClick={(e) => onClickTask(e, t)}
            />
          </Fragment>
        ))}
      </section>
    </div>
  );
};

export default PlanDayScheduledWorkBlock;
