import React, { FunctionComponent, useRef } from 'react';
import AppButton from '../../../../app-button/AppButton';
import { useAppDispatch } from '../../../../../../app/store';
import { useFormContext } from 'react-hook-form';
import { IProfileForm } from '../../SettingsMenu';
import './ThemeButton.scss';
import { updateUserProfileReq } from '../../../../../../app/useAppData/user.store';
import { EClientFlags } from '../../SettingsMenu.interfaces';
import { FlagUtils } from '../../../../../utils/flagUtils';
import {
  EAppThemes,
  getActiveThemeClientFlag,
  themeButtonType,
} from '../../../../../utils/themes.utils';

interface IThemeButtonProps {
  type: themeButtonType;
  SvgComponent: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  themeFlag?: EClientFlags;
}

const ThemeButton: FunctionComponent<IThemeButtonProps> = ({ type, SvgComponent, themeFlag }) => {
  const form = useFormContext<IProfileForm>();
  const watchClientFlags = form.watch('clientFlags');
  const isActive = getActiveThemeClientFlag(watchClientFlags) === type;
  const dispatch = useAppDispatch();
  const prevTheme = useRef<themeButtonType>(getActiveThemeClientFlag(watchClientFlags));
  const watchShouldDisplayThemeError = form.watch('shouldDisplayThemeError');

  const unsetAllThemeFlags = () => {
    prevTheme.current = getActiveThemeClientFlag(watchClientFlags);
    let updatedClientFlags = FlagUtils.unsetFlag(watchClientFlags, EClientFlags.LIGHT_MODE_ON);
    updatedClientFlags = FlagUtils.unsetFlag(updatedClientFlags, EClientFlags.DARK_MODE_ON);
    return updatedClientFlags;
  };

  const onUpdateThemeReq = (updatedClientFlags: number) => {
    const reqPayload = { clientFlags: updatedClientFlags };
    dispatch(updateUserProfileReq(reqPayload))
      .unwrap()
      .then()
      .catch(() => {
        // display error and revert value
        form.setValue('shouldDisplayThemeError', true);
        if (type !== 'systemSettings' && themeFlag) {
          updatedClientFlags = FlagUtils.unsetFlag(updatedClientFlags, themeFlag);
        }
        if (prevTheme.current && prevTheme.current !== 'systemSettings') {
          updatedClientFlags = FlagUtils.setFlag(
            updatedClientFlags,
            prevTheme.current === EAppThemes.LIGHT
              ? EClientFlags.LIGHT_MODE_ON
              : EClientFlags.DARK_MODE_ON,
          );
        }
        form.setValue('clientFlags', updatedClientFlags);
      });
  };

  const onClickThemeButton = () => {
    if (watchShouldDisplayThemeError) form.setValue('shouldDisplayThemeError', false);
    if (isActive) return;
    let updatedClientFlags = unsetAllThemeFlags();
    // set this button theme flag
    if (type !== 'systemSettings' && themeFlag) {
      updatedClientFlags = FlagUtils.setFlag(updatedClientFlags, themeFlag);
    }
    form.setValue('clientFlags', updatedClientFlags);
    onUpdateThemeReq(updatedClientFlags);
  };

  return (
    <AppButton
      data-active-theme={isActive}
      data-testid={`theme-control-button-${type}`}
      id={`theme-control-button-${type}`}
      className={`theme-control-button ${isActive ? 'theme-control-button--active' : ''}`}
      onClick={onClickThemeButton}
    >
      <SvgComponent
        className={`theme-control-button--icon ${
          isActive ? 'brand-primary-color-stroke-icon' : 'text-secondary-color-stroke-icon'
        }`}
      />
    </AppButton>
  );
};

export default ThemeButton;
