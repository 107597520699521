import React, { FunctionComponent } from 'react';
import AppButton, { IAppButtonProps } from '../AppButton';
import './StyledAppButton.scss';

interface IStyledAppButtonProps extends IAppButtonProps {
  buttonStyleType?: 'primary' | 'secondary' | 'tertiary';
}

const StyledAppButton: FunctionComponent<IStyledAppButtonProps> = ({
  buttonStyleType = 'primary',
  className = '',
  ...props
}) => {
  return (
    <AppButton
      {...props}
      className={`app-button--default-style app-button-${buttonStyleType} ${className}`}
    />
  );
};

export default StyledAppButton;
