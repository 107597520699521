import type { FunctionComponent } from 'react';
import React from 'react';
import './OverlayTypeDetails.scss';

interface IOverlayTypeDetailsProps {
  SvgComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  data: string | null;
  disabled?: boolean;
  addOpacity?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

const OverlayTypeDetails: FunctionComponent<IOverlayTypeDetailsProps> = ({
  SvgComponent,
  title,
  data,
  disabled,
  addOpacity,
  onClick,
  className = '',
}) => {
  return (
    <div
      className={`task-edit-overlay-type-details-container 
        ${disabled ? 'task-edit-overlay-type-details-container--disabled' : ''} 
        ${addOpacity ? 'task-edit-overlay-type-details-container--opacity' : ''}
        ${className}
      `}
      onClick={onClick}
    >
      <div className="task-edit-overlay-type-title-and-icon-container static-string">
        <SvgComponent className="primary-color-stroke-icon" />
        {title}
      </div>
      {!!data && <div className="task-edit-overlay-type-data">{data}</div>}
    </div>
  );
};

export default OverlayTypeDetails;
