import { aiToolNames } from '../../../../../app/constants';
import { getTimeByDate } from '../../../../../shared/utils/dateFormat';
import type { IHumanStudentTurnSendInputPayload } from '../../../chat.interfaces';
import type { IMessageDataTask, ISelectedTasksFilter, ITaskTag } from './stageTasks.interface';
import { ETaskStatus, ETasksFilterType } from './stageTasks.interface';
import sassVariables from '../../../../../styles/style.module.scss';
import getThemeColorValue from '../../../../../shared/utils/themes.utils';
import { ApplicationInsightsApi } from '../../../../../application-insights';
import tasksSassVariables from './StageTasksVariables.module.scss';

export const unscheduledSorting = (a: IMessageDataTask, b: IMessageDataTask) => {
  // Sorted by due date ascending (for tasks that have a due date) then by creation date, descending (for tasks w/o due date)
  // Tasks with a due date are sorted ascendingly by dueDate
  if (a.dueDate && b.dueDate) {
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
  }
  // Tasks without a due date are sorted descendingly by creationDate
  if (!a.dueDate && !b.dueDate) {
    return new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();
  }
  // A task with a dueDate comes before a task without a dueDate
  if (a.dueDate) return -1;
  if (b.dueDate) return 1;
  return 0;
};

// sort first the tasks that dont include workBlockOrder and then the rest by workBlockOrder ascending
export const sortingTasksByWorkBlockOrderAscending = (
  a: IMessageDataTask,
  b: IMessageDataTask,
): number => {
  const isOrderExist_A = !!a.workBlockOrder || a.workBlockOrder === 0;
  const isOrderExist_B = !!b.workBlockOrder || b.workBlockOrder === 0;
  if (!isOrderExist_A && !isOrderExist_B) return 0;
  if (!isOrderExist_A) return 1;
  if (!isOrderExist_B) return -1;
  return a.workBlockOrder! - b.workBlockOrder!;
};

export const sortingTasksByCreationTime = (a: IMessageDataTask, b: IMessageDataTask): number => {
  // task with creationTime come before item without creationTime
  if (!a.creationTime && !b.creationTime) return 0;
  if (!a.creationTime) return 1;
  if (!b.creationTime) return -1;

  return new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();
};

export const isOverdueDate = (dueDate: string | Date, completionTime?: string): boolean => {
  const due = new Date(dueDate);
  if (completionTime) {
    return new Date(completionTime) > due;
  }
  const now = new Date();
  return due < now;
};

// only task that is not event, scrathpad task or subtask
export const shouldDisplayOnTaskList = (task: IMessageDataTask): boolean =>
  !task.isEvent && task.status !== ETaskStatus.DELETED && !task?.parentId;

// TODO move to a class handling all automated bot events
export const createAutomatedTaskEvent = (
  sessionId: string,
  field: string,
  value: any,
  task: IMessageDataTask,
): IHumanStudentTurnSendInputPayload => {
  return {
    sessionId,
    option: {
      text: '',
      tool: {
        name: aiToolNames.UX_SET_TASK_FIELD,
        arg: {
          field,
          value,
          task,
        },
      },
    },
  };
};
// generates time range from date and duration (in seconds) in the following format: "HH:mm - HH:mm AM/PM"
// when hour12 is true the following format will be with a 12-hour module: "HH:mmAM/PM - HH:mmAM/PM"
export const getTimeRange = (
  date: Date,
  duration: number | null | undefined,
  hour12?: boolean,
  shouldAmPmOnStartTime?: boolean,
): string => {
  // if there is no duration or duration is 0 return time of date in the following format: "HH:mm AM/PM"
  if (!duration || duration === 0)
    return hour12
      ? getTimeByDate(date)
      : date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  const startDate = new Date(date);
  const endDate = new Date(startDate.getTime() + duration * 1000);
  // when hour12 is true the following format will be with a 12-hour module: "HH:mmAM/PM - HH:mmAM/PM"
  if (hour12) {
    const startTime = shouldAmPmOnStartTime
      ? getTimeByDate(startDate)
      : getTimeByDate(startDate).replace('AM', '').replace('PM', '');
    return `${startTime} - ${getTimeByDate(endDate)}`;
  }
  return `${startDate
    .toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
    .replace('AM', '')
    .replace('PM', '')} - ${endDate.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  })}`;
};

// returns the date formatted as Ddd Mmm dd
export const getDateFormatted = (date: Date): string => {
  return date.toDateString().slice(0, 10);
};

export const getFirstLabelColor = (task: IMessageDataTask) => {
  const defaultColor = sassVariables.tagUntagged;
  if (task.tags && task.tags.length > 0) {
    if (!task.tags[0]?.color || task.tags[0].color === getThemeColorValue('backgroundTransparent'))
      return defaultColor;
    else return task.tags[0]?.color;
  }
  return defaultColor;
};

export const deleteTaskModalAppOverlayPosition = () => {
  const stagingElement = document.getElementById('stage-container-main');
  const appOverlayWidth = parseInt(sassVariables.deleteTaskToastConfirmationWidth) || 0;
  let stagingCenter = 50;

  // position the delete confirm modal at the center of the stage element
  if (stagingElement) {
    const rect = stagingElement.getBoundingClientRect();
    stagingCenter = rect.left + rect.width / 2 - appOverlayWidth / 2 + 4;
  } else {
    // if stagingElement not exist position the modal at the center od the window
    stagingCenter = window.innerWidth / 2 - appOverlayWidth / 3;
  }

  return {
    left: `${stagingCenter + 8}px`,
    right: `unset`,
    top: sassVariables.deleteTaskConfirmModalTopPosition,
  };
};

// multi select filter tasks implementation
export const doesTaskMatchFilter = (
  task: IMessageDataTask,
  selectedFilters: ISelectedTasksFilter[],
): boolean => {
  try {
    // Check if the task matches the filters
    if (selectedFilters.length === 0 && task.status !== ETaskStatus.DONE) {
      return true;
    }

    if (
      !selectedFilters.find((filter) => filter.type === ETasksFilterType.DONE) &&
      task.status === ETaskStatus.DONE
    ) {
      return false;
    }

    // Only return true if the task matches ALL filters in selectedFilters.
    return selectedFilters.every((filter) => {
      // Check if the filter is a tag
      if (filter.tag) {
        // Task must have at least one matching tag
        if (!task.tags || !task.tags.some((taskTag) => getTagHashString(taskTag) === filter.tag)) {
          return false; // Task doesn't include this tag
        }
      }

      // Check if the filter is a type
      else if (filter.type) {
        switch (filter.type) {
          case ETasksFilterType.DONE:
            if (task.status !== ETaskStatus.DONE) return false;
            break;

          case ETasksFilterType.OVERDUE:
            if (!task.dueDate || !isOverdueDate(task.dueDate, task.completionTime)) return false;
            break;

          case ETasksFilterType.UNSCHEDULED:
            if (task.workTime || task.workBlockId) return false;
            break;

          case ETasksFilterType.PRIORITY:
            if (!task.priority) return false;
            break;

          case ETasksFilterType.QUICK_WINS:
            if (!task.quickWin) return false;
            break;

          default:
            return false;
        }
      }

      return true; // If both tag and type filters are satisfied
    });
  } catch (e) {
    console.error('Error while filtering tasks', e);
    ApplicationInsightsApi.trackException(e);
    return false;
  }
};

export const doesTaskOrSubtasksMatch = (
  task: IMessageDataTask,
  selectedFilters: ISelectedTasksFilter[],
  subTasksArr: IMessageDataTask[] = [],
): boolean => {
  try {
    // Check if the main task itself or any of its subtasks match the filter
    return (
      doesTaskMatchFilter(task, selectedFilters) ||
      subTasksArr?.some((subtask) => doesTaskMatchFilter(subtask, selectedFilters))
    );
  } catch (e) {
    console.error('Error while filtering tasks', e);
    ApplicationInsightsApi.trackException(e);
    return false;
  }
};

export const getTagHashString = (tag: ITaskTag): string => {
  return `${tag.name}|${tag.color}`;
};

export const parseTagHash = (tagHash: string): ITaskTag => {
  const [name, color] = tagHash.split('|');
  const tagName: string | null = name === 'null' ? null : name;
  const tagColor: string | null = color === 'null' ? null : color;
  return { name: tagName, color: tagColor };
};

export const taskActionOverlaysPositionStyle = {
  left: 'unset',
  right: tasksSassVariables.taskOverlaysRightPosition,
};
