import type { CSSProperties, Dispatch, FunctionComponent, MouseEvent, SetStateAction } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { ICalendarEvent, IDropResult } from './CalendarDay';
import type {
  IMessageDataTask,
  ITaskUpdateReqPayload,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import {
  ETaskFormType,
  ETaskSource,
  ETaskStatus,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { store, useAppDispatch, useAppSelector } from '../../../../app/store';
import { onPlaceUnscheduledTask } from '../../plan.utils';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import {
  setShouldOpenWorkBlockDetails,
  setWorkBlockForEdit,
} from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import {
  areDatesEqual,
  EDragAndDropType,
  getExternalEventSourceDetails,
  getTaskFromAllTaskList,
  getWorkBlockOrder,
  isTaskAssignToThisWorkBlock,
} from '../../../../shared/utils/utils';
import {
  getTasksListReqAction,
  setSelectedMainTaskForEditing,
  setShouldOpenAddEditTaskFrom,
  updateTaskReqAction,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import type { DragSourceMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import WorkBlockTasksList from './work-block-tasks-list/WorkBlockTasksList';
import { EPlanDayCardDisplayType } from '../../plan-day-card/PlanDayCard';
import { ReactComponent as RecurrenceIcon } from '../../../../assets/images/single-task/task_recurrence_icon.svg';
import DraggableWrapper from '../../../../shared/components/dragged-destination-preview/DraggableWrapper';
import { getStyleForDraggingPreview } from '../../../../shared/components/dragged-destination-preview/DragAndDrop.utils';
import type { IDragItem } from './CalendarDragAndDrop.util';
import type { IUpdateDragEventApiRequestFuncArgs } from './CalendarDay.interfaces';
import { addMinutes } from 'date-fns';
import { fullDayDurationInSeconds } from '../../../../app/constants';
import type { TResizingEventEdge } from './CalendarDay.util';
import {
  calcEventTitleVisibleLines,
  calcNewDuration,
  calcNewStartTime,
  defaultDuration,
  getCalendarEventIconColor,
  getCalendarEventInnerContainerClassName,
  getDurationTypeClassName,
  getEventStatus,
  getResizingEventEdge,
} from './CalendarDay.util';
import { isMobileDevice } from '../../../../shared/utils/isMobileDevice';
import { ReactComponent as WorkBlockIcon } from '../../../../assets/images/work-block-icon.svg';
import { ReactComponent as EventIcon } from '../../../../assets/images/single-task/event-icon.svg';
import AppCheckbox from '../../../../shared/components/app-checkbox/AppCheckbox';
import { ReactComponent as ExternalEventGoogle } from '../../../../assets/images/single-task/external-event-google.svg';
import { ReactComponent as ExternalEventApple } from '../../../../assets/images/single-task/external-event-apple.svg';
import { ReactComponent as ExternalEventMicrosoft } from '../../../../assets/images/single-task/external-event-microsoft.svg';
import './CalendarEvent.scss';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import FocusModeButton from './work-block-tasks-list/focus-mode-button/FocusModeButton';

interface ICalendarEventProps {
  event: ICalendarEvent;
  isCalenderDayClickable: boolean;
  shouldShowHourText: boolean;
  calendarDayContainerWidth: number;
  playViewType: EPlanDayCardDisplayType;
  divideEventByGroups: (events: ICalendarEvent[]) => void;
  eventsFlatArray: ICalendarEvent[];
  dayIndex: number;
  updateDragEventApiRequest: (updateDraggedEventArgs: IUpdateDragEventApiRequestFuncArgs) => void;
  setDroppingProcessCounter: Dispatch<SetStateAction<number>>;
  cellHeight: number;
}

export interface IUseDragCollectedProps {
  opacity?: number;
  cursor?: string;
  transition?: string;
}

export interface IWorkBlockDropResult extends IDropResult {
  droppedWorkBlockId: string;
}

export const calendarTaskCheckboxSize = '12px';

const CalendarEvent: FunctionComponent<ICalendarEventProps> = ({
  event,
  dayIndex,
  divideEventByGroups,
  eventsFlatArray,
  isCalenderDayClickable,
  shouldShowHourText,
  calendarDayContainerWidth,
  playViewType,
  updateDragEventApiRequest,
  setDroppingProcessCounter,
  cellHeight,
}) => {
  const { allTasks } = useAppSelector((store) => store.StageTasksReducer);
  const { plannerMode, currentTaskPlacement, planView } = useAppSelector(
    (store) => store.StagePlannerReducer,
  );
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const dispatch = useAppDispatch();
  const defaultLeftPosition = 40;
  const reductionFromContainerWidth = shouldShowHourText ? 40 : 0;
  const marginBetweenEvents = 2;
  const eventRef = useRef<HTMLDivElement | null>(null);
  const leftPosition = calcEventLeft();
  const [draggingPreviewStyle, setDraggingPreviewStyle] = useState(
    getStyleForDraggingPreview(eventRef.current, null),
  );
  // resizing variables
  const [resizingStartY, setResizingStartY] = useState<number | null>(null);
  const [cursor, setCursor] = useState('pointer');
  const [resizingEdge, setResizingEdge] = useState<TResizingEventEdge>(null);
  const [shouldDisableOnClick, setShouldDisableOnClick] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const initialResizingHeight = useRef<number | null>(null);
  const initialResizingTopRef = useRef<number | null>(null);
  const endResizingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const isMobile = isMobileDevice();
  const eventIconColor = getCalendarEventIconColor(event.backgroundColor);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    setDraggingPreviewStyle({ ...getStyleForDraggingPreview(eventRef.current, null) });
  }, [eventRef, leftPosition]);

  // Dragging is permitted only if:
  // 1. The event is not recurring, and it is not the current task placement.
  // 2. The event is not external.
  const canDrag = () => {
    const isNotRecurring = !event.isRecurring;
    const isNotCurrentTaskPlacement = currentTaskPlacement?.id !== event.id;
    const isNotExternalEvent = !event.isEvent || event.source === ETaskSource.Internal;

    return (
      isNotRecurring &&
      isNotCurrentTaskPlacement &&
      isNotExternalEvent &&
      cursor !== 'ns-resize' &&
      !resizingEdge
    );
  };

  const canResizing = () => {
    const isNotRecurring = !event.isRecurring;
    const isNotCurrentTaskPlacement = currentTaskPlacement?.id !== event.id;
    const isNotExternalEvent = !event.isEvent || event.source === ETaskSource.Internal;
    return isNotCurrentTaskPlacement && isNotExternalEvent && isNotRecurring;
  };

  const onEndDrag = (
    draggedItem: IDragItem,
    monitor: DragSourceMonitor<IDragItem, IDropResult>,
  ) => {
    const dropResult = monitor.getDropResult();
    // if dropped in different day, remove the event from the current day (else, the event is already updated in the current day inside the drop function)
    if (monitor.didDrop() && dropResult?.dayIndex !== dayIndex) {
      divideEventByGroups(eventsFlatArray.filter((e) => e.id !== draggedItem.event.id));
    }
  };

  const updateLocalDroppedWorkBlockEvent = (
    task: IMessageDataTask,
    taskWorkBlockOrder: number,
    taskWorkBlockInstance: Date | null,
  ) => {
    const updatedTask = {
      ...task,
      workBlockId: event?.id,
      workBlockInstance: taskWorkBlockInstance,
      workBlockOrder: taskWorkBlockOrder,
      workTime: null,
      workTimeRecurrenceType: null,
      workTimeReminder: null,
    };
    const tempEventsArr = [...eventsFlatArray];
    const workBlockEvent = tempEventsArr.find((e) => e.id === event.id);
    if (workBlockEvent) {
      workBlockEvent.relatedTasks.push(updatedTask);
      divideEventByGroups([...tempEventsArr].filter((e) => e.id !== task.id));
    }
  };

  const dropInToWorkBlock = (
    dragItem: IDragItem,
    dropType: EDragAndDropType,
  ): IWorkBlockDropResult => {
    setDroppingProcessCounter((prev) => prev + 1);
    const task = allTasks.find((t) => t.id === dragItem.event.id);
    const taskWorkBlockInstance = event.isRecurring ? event.start : null;
    // if the task is not already assigned to this workBlock, update the task and the workBlock
    if (
      !!task &&
      !isTaskAssignToThisWorkBlock(
        event.id,
        taskWorkBlockInstance || '',
        task?.workBlockId,
        task?.workBlockInstance,
      )
    ) {
      const taskWorkBlockOrder = getWorkBlockOrder(
        event.relatedTasks[event.relatedTasks.length - 1]?.workBlockOrder,
      );
      updateLocalDroppedWorkBlockEvent(task, taskWorkBlockOrder, taskWorkBlockInstance);
      updateDragEventApiRequest({
        dragItem,
        dropType,
        updatedWorkTime: null,
        relatedWorkBlockId: event.id,
        relatedWorkBlockOrder: taskWorkBlockOrder,
        relatedWorkBlockInstance: taskWorkBlockInstance,
      });
    }
    return { dayIndex, droppedWorkBlockId: event.id };
  };

  // drop into work block
  // dropRef is used only for work blocks to make them droppable
  const [{ isDraggableItemOverClassName }, dropRef] = useDrop({
    accept: [EDragAndDropType.CALENDAR_EVENT, EDragAndDropType.PLANNER_TO_CALENDAR_EVENT],
    drop: (dragItem: IDragItem, monitor) =>
      dropInToWorkBlock(dragItem, monitor.getItemType() as EDragAndDropType),
    canDrop: (dragItem: IDragItem) => !dragItem.event.isEvent,
    collect: (monitor) => ({
      isDraggableItemOverClassName:
        monitor.isOver() && !monitor.getItem().event.isEvent && !monitor.getItem().event.isWorkBlock
          ? 'draggable-item-is-over'
          : '',
    }),
  });

  const onPlaceTaskInsideWorkBlock = (workBlockEvent: ICalendarEvent) => {
    onPlaceUnscheduledTask(
      workBlockEvent.start,
      workBlockEvent.id,
      workBlockEvent.title,
      workBlockEvent.isRecurring,
      workBlockEvent.relatedTasks.length
        ? getWorkBlockOrder(
            workBlockEvent.relatedTasks[workBlockEvent.relatedTasks.length - 1]?.workBlockOrder,
          )
        : 0,
    );
  };

  const onClickWorkBlockEvent = (workBlockEvent: ICalendarEvent) => {
    if (plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceTaskInsideWorkBlock(workBlockEvent);
      return;
    }
    const workBlock = store
      .getState()
      .StageTasksReducer.allWorkBlocks.find(
        (w) => w.id === workBlockEvent.id && areDatesEqual(w.workTime, workBlockEvent.start),
      );
    if (workBlock) {
      dispatch(setWorkBlockForEdit(workBlock));
      dispatch(setShouldOpenWorkBlockDetails(true));
    }
  };

  const onOpenTaskDetails = (task: IMessageDataTask, isEvent?: boolean) => {
    dispatch(setSelectedMainTaskForEditing(task));
    dispatch(setShouldOpenAddEditTaskFrom(isEvent ? ETaskFormType.Event : ETaskFormType.Task));
  };

  const handleEventClick = (
    clickEvent: MouseEvent<HTMLElement, any>,
    event: ICalendarEvent,
    workBlockTask?: IMessageDataTask,
  ) => {
    clickEvent.stopPropagation();
    if (!isCalenderDayClickable || cursor === 'ns-resize' || shouldDisableOnClick) return;
    if (plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER && currentTaskPlacement) {
      if (event.isWorkBlock) {
        onPlaceTaskInsideWorkBlock(event);
        return;
      }
      onPlaceUnscheduledTask(event.start);
      return;
    }
    if (workBlockTask && workBlockTask.id && plannerMode !== EPlannerMode.TIMEPICKER) {
      // if is subtask open the parent task
      const taskToOpen = workBlockTask.parentId
        ? getTaskFromAllTaskList(workBlockTask.parentId || workBlockTask.id)
        : workBlockTask;
      if (taskToOpen) {
        onOpenTaskDetails(taskToOpen, false);
      }
      return;
    }
    if (!event.isWorkBlock && plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceUnscheduledTask(event.start);
      return;
    }
    if (event.isWorkBlock) onClickWorkBlockEvent(event);
    else {
      // try get parent task first if exists
      const taskToOpen = getTaskFromAllTaskList(event.parentId || event.id);
      if (taskToOpen) {
        onOpenTaskDetails(taskToOpen, event.isEvent);
      }
    }
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  };

  function calcEventLeft() {
    const leftPosition = shouldShowHourText ? defaultLeftPosition : 0;
    if (event.columnOffset === 0)
      return (
        (event.columnOffset * (calendarDayContainerWidth - reductionFromContainerWidth)) /
          event.totalColumns! +
        leftPosition
      );
    return (
      (event.columnOffset! * (calendarDayContainerWidth - reductionFromContainerWidth)) /
        event.totalColumns! +
      leftPosition
    );
  }

  const getEventStyle = (): CSSProperties => {
    const baseStyle: CSSProperties = {
      position: 'absolute' as const,
      top: event.top,
      left: leftPosition,
      height: `${event.height}px`,
      width: `${
        (calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns! -
        marginBetweenEvents
      }px`,
      transition: 'none',
    };
    return baseStyle;
  };

  const handleMouseOverForCursor = (e: React.MouseEvent<HTMLElement>) => {
    const edgeForResizing = getResizingEventEdge(e, cellHeight);

    if (edgeForResizing) {
      setCursor('ns-resize');
    } else {
      setCursor('pointer');
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    const edgeForResizing = getResizingEventEdge(e, cellHeight);

    if (elementRef.current && !!edgeForResizing) {
      setShouldDisableOnClick(true);
      setResizingStartY(e.clientY);
      initialResizingHeight.current = elementRef.current.offsetHeight;
      setResizingEdge(edgeForResizing === 'top' ? 'top' : 'bottom');
      if (edgeForResizing === 'top') initialResizingTopRef.current = elementRef.current.offsetTop;
    }
  };

  const handleMouseMove = (e: Event) => {
    const initialHeight = initialResizingHeight.current;
    if (!resizingEdge || !resizingStartY || !initialHeight || !elementRef.current) return;
    const deltaY = (e as unknown as MouseEvent).clientY - resizingStartY;
    if (resizingEdge === 'bottom') {
      const newHeight = Math.max(
        cellHeight,
        Math.round((initialHeight + deltaY) / cellHeight) * cellHeight,
      ); // Snap to increments of cellHeight
      elementRef.current.style.height = `${newHeight}px`;
    } else if (resizingEdge === 'top' && initialResizingTopRef.current) {
      const newHeight = Math.max(
        cellHeight,
        Math.round((initialHeight - deltaY) / cellHeight) * cellHeight,
      );
      const newTop = initialResizingTopRef.current + (initialHeight - newHeight); // Adjust top to maintain bottom position

      elementRef.current.style.height = `${newHeight}px`;
      elementRef.current.style.top = `${newTop}px`;
    }
  };

  const onUpdateLocalEventsList = (
    newHeight: number,
    updatedDuration: number,
    newStartTime: Date | null,
  ) => {
    const start = newStartTime !== null ? newStartTime : event.start;
    const minutesToAddFromStartToCalcEndDate =
      updatedDuration / 60 > 15 ? updatedDuration / 60 : defaultDuration;
    const end = addMinutes(start, minutesToAddFromStartToCalcEndDate);
    const durationType = getDurationTypeClassName(updatedDuration);
    const top = elementRef.current?.offsetTop || event.top;
    const updatedEvent = {
      ...event,
      duration: updatedDuration,
      height: newHeight,
      end,
      durationType,
      start,
      top,
    };
    divideEventByGroups([...eventsFlatArray.filter((e) => e.id !== event.id), updatedEvent]);
  };

  const onUpdateEventDurationAndStart = () => {
    const newHeight = elementRef.current?.offsetHeight;
    const initialHeight = initialResizingHeight.current;
    const isInvalidResize =
      !sessionResponse?.data?.sessionId ||
      !newHeight ||
      !initialHeight ||
      newHeight === initialHeight ||
      !elementRef.current;
    if (isInvalidResize) {
      initialResizingHeight.current = null;
      initialResizingTopRef.current = null;
      return;
    }

    const newDuration = calcNewDuration(newHeight, cellHeight);
    // extending to multi-day durations is not supported
    if (fullDayDurationInSeconds <= newDuration) {
      elementRef.current!.style.height = `${initialHeight}px`; // use ! -> we know it's not null as isInvalidResize=false
    } else {
      // calc new start time only for top resizing
      let newStartTime = null;
      if (resizingEdge === 'top') {
        newStartTime = calcNewStartTime(event.start, initialHeight, newHeight, cellHeight);
      }
      setDroppingProcessCounter((prev) => prev + 1);
      onUpdateLocalEventsList(newHeight, newDuration, newStartTime); // update the local events list before the server update

      const reqPayload: ITaskUpdateReqPayload = {
        sessionId: sessionResponse?.data?.sessionId!, // use ! -> we know it's not null as isInvalidResize=false
        id: event.id,
        duration: newDuration,
      };
      // add the updated work time if exists to the request payload
      if (newStartTime) reqPayload.workTime = newStartTime;

      dispatch(updateTaskReqAction(reqPayload)).finally(() => {
        // Prevent the calendar from rendering until the GET request with the updated task occurs.
        // This avoids events reverting to their previous placement before the server update tasks is complete.
        dispatch(getTasksListReqAction())
          .unwrap()
          .finally(() => setDroppingProcessCounter((prev) => (prev > 0 ? prev - 1 : 0)));
      });
    }
    initialResizingHeight.current = null;
    initialResizingTopRef.current = null;
  };

  const cleanEndResizingTimerRef = () => {
    if (endResizingTimerRef.current) clearTimeout(endResizingTimerRef.current);
  };

  const handleMouseUp = () => {
    setResizingEdge(null);
    setResizingStartY(null);
    onUpdateEventDurationAndStart();
    cleanEndResizingTimerRef();
    // prevent from the event form modal to open while release the mouse
    endResizingTimerRef.current = setTimeout(() => {
      setShouldDisableOnClick(false);
    }, 0);
  };

  useEffect(() => {
    if (resizingEdge && !isMobile && canResizing()) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line
  }, [resizingEdge]);

  useEffect(() => {
    return () => cleanEndResizingTimerRef();
  }, []);

  const onMouseLeaveUpdateCursor = () => {
    if (!isMobile && cursor !== 'pointer') setCursor('pointer');
  };

  const getEventIcon = () => {
    switch (event?.source) {
      case ETaskSource.Google:
        return <ExternalEventGoogle className="external-event-icon primary-color-stroke-icon" />;
      case ETaskSource.Outlook:
        return <ExternalEventMicrosoft className="external-event-icon primary-color-stroke-icon" />;
      case ETaskSource.Apple:
        return <ExternalEventApple className="external-event-icon primary-color-stroke-icon" />;
      default:
        return (
          <EventIcon
            className={`calendar-event-title-icon event-icon primary-color-stroke-icon ${
              eventIconColor ? 'event-icon--tagged' : ''
            }`}
          />
        );
    }
  };

  const getCalendarEventIcon = () => {
    if (event.isWorkBlock) {
      return (
        <WorkBlockIcon className="calendar-event-title-icon work-block-icon primary-color-stroke-icon" />
      );
    } else if (event.isEvent) {
      return <div className="event-icon-circle-background">{getEventIcon()}</div>;
    }
    return (
      <AppCheckbox
        className="task-checkbox"
        isChecked={event.status === ETaskStatus.DONE}
        shouldDisabled={true}
        chackboxColor={eventIconColor}
        checkboxSize={calendarTaskCheckboxSize}
        checkMarkSize={'8px'}
      />
    );
  };

  return (
    <DraggableWrapper<IDragItem, IDropResult>
      className={`calendar-event calendar-event--${event.durationType} ${
        cursor === 'ns-resize' ? 'cursor-resize' : ''
      } ${resizingEdge ? 'resizing' : ''} ${
        event?.isWorkBlock ? 'calendar-event--work-block' : 'event-or-task'
      }`}
      id={`calendar-event-draggable-wrapper-${event.id}`}
      type={EDragAndDropType.CALENDAR_EVENT}
      item={{ event, previewStyle: draggingPreviewStyle }}
      canDrag={() => canDrag()}
      onEndDrag={onEndDrag}
      dropRef={event.isWorkBlock ? dropRef : undefined}
      keyAttr={event.id}
      style={getEventStyle()}
      onClick={(e) => {
        e.stopPropagation();
        handleEventClick(e, event);
      }}
      // Resizing events
      wrapperRef={elementRef}
      onMouseDown={!isMobile && canResizing() ? handleMouseDown : undefined}
      onMouseOver={!isMobile && canResizing() ? handleMouseOverForCursor : undefined}
      onMouseLeave={onMouseLeaveUpdateCursor}
    >
      <div
        ref={eventRef}
        className={`
          ${getCalendarEventInnerContainerClassName(planView, event.durationType)}
          ${isDraggableItemOverClassName}
          ${getEventStatus(event.start, event.end) === 'focused' ? 'focused' : ''}
        `}
        style={
          {
            // --calendar-event-circle-color -> CSS variable that set the circle icon color.
            ...(eventIconColor && {
              '--calendar-event-first-label-color': eventIconColor,
            }),
            '--calendar-task-checkbox-size': calendarTaskCheckboxSize,
          } as React.CSSProperties
        }
      >
        <div className="event-text-container">
          <h3
            className={`calendar-event-title calendar-event-title--${
              event.isEvent && !event.isWorkBlock ? 'event' : ''
            } ${event.status === ETaskStatus.DONE ? 'completed-task' : ''}`}
          >
            {getCalendarEventIcon()}{' '}
            <span
              className="title-text"
              style={{
                // Calculate visible lines
                WebkitLineClamp: calcEventTitleVisibleLines(event),
              }}
            >
              {event.title}
            </span>
          </h3>
          {!event?.isWorkBlock && event.source !== ETaskSource.Internal && (
            <span className="calendar-event-addition-text">
              {getExternalEventSourceDetails(event.source)}
            </span>
          )}
          {event?.isWorkBlock && (
            <WorkBlockTasksList
              workBlock={event}
              onClickWorkBlockTask={handleEventClick}
              planViewType={playViewType}
              relatedTasks={event.relatedTasks}
              eventsFlatArray={eventsFlatArray}
              dayIndex={dayIndex}
              divideEventByGroups={divideEventByGroups}
            />
          )}
        </div>
        {!event?.isWorkBlock && event.isRecurring && (
          <div className="calendar-event-addition-info-container">
            <RecurrenceIcon
              className={`primary-color-stroke-icon${
                !event.isRecurring ? ' visibility-hidden' : ''
              }`}
            />
          </div>
        )}
        <FocusModeButton event={event} />
      </div>
    </DraggableWrapper>
  );
};

export default CalendarEvent;
